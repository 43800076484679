import { IProps as DataTableProps } from '../../components/dataTable/DataTable'
export const getTagCfg = function getTagCfg(id: string): DataTableProps[]  {

    return [
        {
            title: 'Blogs',
            apiUrl: `/blog/tags/${id}`,
            url: '/blogs',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Created', attr: 'created_at'},
            ]
        },
        
    ]
} 