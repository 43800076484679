import React, { FC, ReactElement, useState } from "react";
import { Button, IconButton, Theme, Typography } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Logo } from "../assets/hotovo_logo.svg";
import { logout } from "../utils/Auth";
import { useNavigate } from "react-router-dom";
import FormProvider from "../store/FormProvider";
import { ROUTES } from "../App";
import { Roles } from "../types/Roles";
import { useUserContext } from "../store/UserProvider";

export const LAYOUT_ID = "layout-content";
const drawerWidth = 280;

type StyleProps = {
  open: Boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    display: "block",
  },
  sidenav: {
    backgroundColor: "#212121",
    height: "100vh",
    width: `${drawerWidth}px`,
    color: "white",
    transition: "ease-out",
	overflowY: "scroll",
    transitionDuration: "200ms",
    position: "absolute",
    top: 0,
    left: 0,
  },
  header: {
    padding: "4px 30px",
    height: 50,
    display: "flex",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "space-between",
  },
  contentBox: {
    margin: `0 0 0 ${drawerWidth}px`,
    transition: "ease-out",
    transitionDuration: "200ms",
    flexGrow: 100,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    backgroundColor: "#EEE",
    width: "100%",
    flexGrow: 100,
    padding: "30px 0",
    maxHeight: "calc(100vh - 118px)",
    overflowY: "scroll",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "block",
    },
    contentBox: {
      margin: `0 0 0 0`,
      transition: "none",
      transitionDuration: "0ms",
      flexGrow: 0,
      display: "flex",
      flexDirection: "column",
    },
  },
}));

interface IProps {
  children: ReactElement;
}

const DefaultLayout: FC<IProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(true);

  const classes = useStyles({ open: open });
  const navigate = useNavigate();
  const userContext = useUserContext();

  // const matches = useMediaQuery('(min-width:600px)');
  //
  // useEffect(() => {
  // 	setOpen(matches)
  // }, [matches])

  return (
    <div>
      <div className={classes.root}>
        <div style={{ width: 0 }}>
          <div
            className={classes.sidenav}
            style={{ transform: open ? "translateX(0)" : "translateX(-100%)" }}
          >
            <div style={{ padding: "16px" }}>
              <Logo
                style={{
                  fill: "white",
                  marginBottom: "30px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              />
              {ROUTES.map((route) => {
                return (
                  <React.Fragment key={route.title}>
                    <Typography variant={"caption"}>{route.title}</Typography>
                    <div style={{ marginLeft: "-5px" }}>
                      {route.items.map((item) => {
                        if (
                          item.auth.find((role) => userContext.role === role) ||
                          userContext.role === Roles.ADMIN
                        ) {
                          return (
                            <Button
                              key={item.title}
                              style={{ display: "flex", color: "white" }}
                              startIcon={item.icon}
                              onClick={() => navigate(item.to)}
                            >
                              {item.title}
                            </Button>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={classes.contentBox}
          style={{ marginLeft: open ? `${drawerWidth}px` : 0 }}
        >
          <div className={classes.header}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen((prev) => !prev)}
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Button
              variant="text"
              style={{ color: "gray", fontWeight: 700 }}
              onClick={logout}
            >
              LOGOUT
            </Button>
          </div>
          <div className={classes.content} id={LAYOUT_ID}>
            <FormProvider>{children}</FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
