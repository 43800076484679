import React, { FC } from "react";
import { Button, Typography } from "@mui/material";
import MediaPicker from "../MediaPicker";

import { API_URL } from "../../utils/constants";
import { useCommonClasses } from "./common-classes";
import { useNavigate } from "react-router-dom";
import MuxPlayer from "@mux/mux-player-react";

export type SelectedMedia = {
  id: number;
  url: string;
  type: MediaFormType;
};

export type IdMediaObject = {
  id: number;
};

export type MediaFormType = "video" | "image";

interface IProps {
  selectedMediaElements: SelectedMedia[];
  handleRemoveMedia: (id: number) => any;
  setSelectedMedia: (value: IdMediaObject) => any;
  mediaType: MediaFormType;
}

const MediaForm: FC<IProps> = ({
  selectedMediaElements,
  handleRemoveMedia,
  setSelectedMedia,
  mediaType,
}) => {
  const classes = useCommonClasses();
  const navigate = useNavigate();

  const keywordsMap = {
    video: "video",
    image: "image",
  };

  return (
    <div className={classes.modalContentImage}>
      <div className={classes.imagesContent}>
        {selectedMediaElements?.length === 0 ? (
          <div style={{ flexGrow: 100 }}>
            <Typography variant={"h6"}>
              There are no {keywordsMap[mediaType]}s in this blog
            </Typography>
          </div>
        ) : null}
        {selectedMediaElements.map((media) => {
          return (
            <div key={media.id} className={classes.mediaSelect} style={{
                maxWidth: media.type === "video" ? "calc(50% - 25px)" : undefined
            }}>
              <div className={classes.mediaContent}>
                {media.type === "image" && (
                  <img
                    src={`${API_URL}/${media.url}`}
                    style={{
                      maxHeight: 250,
                      maxWidth: 250,
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                )}
                {media.type === "video" &&
                  (media.url.startsWith("upload") ? (
                    <video
                      controls
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                    >
                      <source src={`${API_URL}/${media.url}`} />
                    </video>
                  ) : (
                    <MuxPlayer streamType="on-demand" playbackId={media.url} />
                  ))}
              </div>
              <div>
                <Button
                  variant={"contained"}
                  onClick={() => handleRemoveMedia(media.id)}
                  style={{ marginTop: 10, marginRight: 10 }}
                >
                  REMOVE
                </Button>
                <Button
                  variant={"contained"}
                  onClick={() => navigate("/media-library/" + media.id)}
                  style={{ marginTop: 10 }}
                >
                  EDIT
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Typography variant={"h5"} style={{ marginBottom: 20 }}>
        Select {keywordsMap[mediaType]}
      </Typography>
      <MediaPicker
        id={""}
        label={""}
        mediaType={mediaType}
        value={[]}
        onChange={(id, item) => setSelectedMedia(item[0])}
      />
    </div>
  );
};

export default MediaForm;
