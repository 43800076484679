import { useState } from "react";
import MediaPicker, { getMediaFileUrl } from "./MediaPicker";
import { GRAVATAR  } from "../utils/constants";


export interface Props  {
    onChange: (id: string, value: any) => void
    photo?: string;
    avatar: string;
}

export default function ProfilePicker({
    onChange,
    photo,
    avatar
}: Props) {

    const [openMediaLibrary, setOpenMediaLibrary] = useState(false);

    const [profilePhoto, setProfilePhoto] = useState( photo ? getMediaFileUrl( photo) : (avatar || `${GRAVATAR}foo?s=500`));

    function toggleMediaLibrary() {
		setOpenMediaLibrary(prev => !prev);
	}

    return (
        <div>
            <MediaPicker
                showPreview={false}
                multiple={false}
                id={"profile_picture"}
                label={"Images"}
                value={{
                    id: 1
                }}
                open={openMediaLibrary}
                validators={[{required: true}]}
                onChange={(id, value, selectedMedia) => {
                    setProfilePhoto(getMediaFileUrl(selectedMedia[0].file))
                    onChange(id, value[0]);
                }}
                onClose={() => setOpenMediaLibrary(false)}
            />
            <img onClick={toggleMediaLibrary} src={profilePhoto} style={
                { cursor: 'pointer',
                    maxWidth: 188,
                    width: 188,
                    maxHeight: 188,
                    height: 188,
                    objectFit: "contain",
                    marginRight: 10,
                    borderRadius: 4,
                    marginTop: 5,
                    marginBottom: 5,
                    background: '#e6e6e6'
                }} alt={"user avatar"}/>
        </div>
    )
}