import AuthLayout from "../layouts/Auth";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState, FormEvent } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import * as qs from "qs";
import { API_URL } from "../utils/constants";
import { getAuth } from "../utils/Auth";
import { handleResponseError } from "../utils/methods";

export default function ForgottenPassword() {
  const location = useLocation();
  const navigate = useNavigate();

  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token;

  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");

  function goToLogin() {
    navigate("/login");
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    axios
      .post(
        API_URL + "/auth/forgotten-password/new",
        {
          token,
          password,
          password_conf: passwordConf,
        },
        getAuth()
      )
      .then(() => {
        toast.success("Password recovered successfully");
        goToLogin();
      })
      .catch((err) => {
        console.error(err, err.response);
        handleResponseError(err.response, "while recovering password");
      });
  }

  useEffect(() => {
    if (!token) {
      goToLogin();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout title="RECOVER PASSWORD">
      <form onSubmit={handleSubmit}>
        <div style={{ width: "100%", marginBottom: 30 }}>
          <TextField
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            variant="standard"
            fullWidth
            type="password"
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Password confirmation"
            value={passwordConf}
            onChange={(event) => setPasswordConf(event.target.value)}
            variant="standard"
            fullWidth
            type="password"
            style={{ marginBottom: 20 }}
          />
          <input type="hidden" />
        </div>
        <Button variant={"contained"} type={"submit"} fullWidth>
          CHANGE PASSWORD
        </Button>
      </form>
    </AuthLayout>
  );
}
