import React, { FC, ReactElement } from 'react';
import { isLogged } from './Auth';

interface IProps {
	component: ReactElement
}

const PrivateRoute: FC<IProps> = ({component}) => {

	if(isLogged()) return component
	window.location.replace("/login")
	return <div>Redirecting...</div>
}

export default PrivateRoute