interface RatingProps {
  id: string;
  rating: number;
  score: number;
  text: string;
}


const Rating = ({ id, rating, score, text }: RatingProps) => {

  return (
    <div key={id} className={`seo-result seo-result--${rating}`}>
      <span className="seo-result__score">
        {rating} ({score}/10)
      </span>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default Rating;
