import React, {useState} from 'react';
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import {useParams} from "react-router-dom";
import EntityDetailLayout from "../layouts/EntityDetail";
import {CategoryItem, defaultData} from '../types/CategoryItem';
import TextInput from '../components/TextInput';

const tableHeader = [
  {name: "ID", attr: "id"},
  {name: "Name", attr: "name"},
  {name: "Slug", attr: "slug"},
]

export default function Categories() {

    const {entityId} = useParams()
    const [detailData, setDetailData] = useState<CategoryItem>(defaultData)

    const getFormData = () => {
      return {
          name: detailData.name,
          slug: detailData.slug,
          description: detailData.description
      }
    }

    const changeData = (attr: string, value: any) => {
      setDetailData(prev => {
        return {...prev, [attr]: value}
      })
    }

    if(entityId) {
        return (
            <DefaultLayout>
                <EntityDetailLayout id={detailData.id} title={"Categories"} apiUrl={"/category"} defaultValue={defaultData} setData={setDetailData} getFormData={getFormData}>
                  <TextInput value={detailData.name} onChange={changeData} id={"name"} label={"Name"} validators={[{required: true}, {maxLimit: 255}]}/>
                  <TextInput value={detailData.slug} onChange={changeData} id={"slug"} label={"Slug"} validators={[{required: true}, {regex: /^[-a-zA-Z0-9_]+$/}, {maxLimit: 255}]}/>
                  <TextInput value={detailData.description} onChange={changeData} id={"description"} label={"Description"} validators={[{maxLimit: 255}]}/>
                </EntityDetailLayout>
            </DefaultLayout>
        )
    }

    return (
        <DefaultLayout>
            <DataTable title={"Categories"} apiUrl={"/category"} url={"/categories"} tableHeader={tableHeader} />
        </DefaultLayout>
    )
}
