import React, {useState} from 'react';
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import {useParams} from "react-router-dom";
import EntityDetailLayout from "../layouts/EntityDetail";
import TextInput from '../components/TextInput';
import {defaultTagItem, TagItem} from "../types/TagItem";

const tableHeader = [
    {name: "ID", attr: "id"},
    {name: "Title", attr: "title"},
    {name: "Slug", attr: "slug"},
]

export default function Tags() {

    const {entityId} = useParams()
    const [detailData, setDetailData] = useState<TagItem>(defaultTagItem)

    const getFormData = () => {
        return {
            title: detailData.title,
            slug: detailData.slug,
        }
    }

    const changeData = (attr: string, value: any) => {
        setDetailData(prev => {
            return {...prev, [attr]: value}
        })
    }

    if(entityId) {
        return (
            <DefaultLayout>
                <EntityDetailLayout id={detailData.id} title={"Tags"} apiUrl={"/tag"} defaultValue={defaultTagItem} setData={setDetailData} getFormData={getFormData} showUsedInForEntity='tag'>
                    <TextInput value={detailData.title} onChange={changeData} id={"title"} label={"Title"} validators={[{maxLimit: 255}]}/>
                    <TextInput value={detailData.slug} onChange={changeData} id={"slug"} label={"Slug"} validators={[{required: true}, {maxLimit: 255}]}/>
                </EntityDetailLayout>
            </DefaultLayout>
        )
    }

    return (
        <DefaultLayout>
            <DataTable title={"Tags"} apiUrl={"/tag"} url={"/tags"} tableHeader={tableHeader} />
        </DefaultLayout>
    )
}
