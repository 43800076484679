import React, { useState } from 'react';
import DefaultLayout from '../layouts/Default';
import DataTable from '../components/dataTable/DataTable';
import { useParams } from 'react-router-dom';
import EntityDetailLayout from '../layouts/EntityDetail';
import { defaultData } from '../types/CategoryItem';
import TextInput from '../components/TextInput';
import { defaultJobContactItem, JobContactItem } from '../types/JobContactItem';

const tableHeader = [
  { name: 'ID', attr: 'id' },
  { name: 'Location', attr: 'location' },
  { name: 'Name', attr: 'name' },
  { name: 'Phone', attr: 'phone' },
  { name: 'Email', attr: 'email' },
];

export default function JobContacts() {

  const { entityId } = useParams();
  const [detailData, setDetailData] = useState<JobContactItem>(defaultJobContactItem);

  const getFormData = () => {
    return {
      location: detailData.location,
      name: detailData.name,
      phone: detailData.phone,
      email: detailData.email
    };
  };

  const changeData = (attr: string, value: any) => {
    setDetailData(prev => {
      return { ...prev, [attr]: value };
    });
  };


  if (entityId) {
    return (
      <DefaultLayout>
        <EntityDetailLayout id={detailData.id} title={'Job Contacts'} apiUrl={'/job-contact'} defaultValue={defaultData}
                            setData={setDetailData} getFormData={getFormData}>
          <TextInput value={detailData.location} onChange={changeData} id={'location'} label={'Location'}
                     validators={[{ maxLimit: 255, required: true }]} />
          <TextInput value={detailData.name} onChange={changeData} id={'name'} label={'Name'}
                     validators={[{ maxLimit: 255 }]} />
          <TextInput value={detailData.phone} onChange={changeData} id={'phone'} label={'Phone'}
                     validators={[{ maxLimit: 255 }]} />
          <TextInput value={detailData.email} onChange={changeData} id={'email'} label={'Email'}
                     validators={[{ maxLimit: 255 }]} />
        </EntityDetailLayout>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <DataTable title={'Job Contacts'} apiUrl={'/job-contact'} url={'/job-contacts'} tableHeader={tableHeader} />
    </DefaultLayout>
  );
}
