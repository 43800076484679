import {toast} from "react-toastify";

export const getFileType = (path: string | File) => {
	if(path instanceof File) {
		return path?.name?.split('.').pop();
	}
	else return path?.split('.').pop();
}

export const isImage = (path: string | File) => {
	const type = getFileType(path)
	return type === "png" || type === "jpg" || type === "svg" || type === "jpeg" || type === "gif";
}

export const isVideo = (filename: string | File) => {
	const ext = getFileType(filename);
	if(!ext) return false;
	switch (ext.toLowerCase()) {
		case 'm4v':
		case 'avi':
		case 'mpg':
		case 'mp4':
			return true;
	}
	return false;
}

export function getImageForm(value: any, single: true): {id: number}  | undefined
export function getImageForm(value: any, single?: false): {id: number}[]  | undefined
export function getImageForm (value: any, single: boolean = true) : {id: number}[] | {id: number}  | undefined {

	if(!value) return undefined;
	if(!Array.isArray(value)) return {id: value.id}

	if(Array.isArray(value) && !value.length) {
		return undefined;
	}

	if(value.length === 1 && single) return {id: value[0].id}
	return value.map(item => {return {id: item.id}})
}

export const handleResponseError = (error: any, type?: string) => {

	let message = '!'
	if(error?.statusText) message = ": " + error.data.message + "!"
	if(error?.data?.message) message = ": " + error.data.message + "!"

	switch(error.status) {
		case 400:
			toast.error(`An error occurred ${type ? type : ""} ${message}`)
			break
		case 401:
			toast.error(`Unauthorized ${message}`)
			break
		case 403:
			toast.error(`Forbidden ${message}`)
			break
		case 404:
			toast.error(`Not found ${message}`)
			break
		case 415:
			toast.error(`Unsupported Media Type ${message}`)
			break
		case 500:
			toast.error(`Server error occurred ${type ? type : ""} ${message}`)
			break
		case 429:
			toast.error(`${message}`)
			break
		default:
			if(!error) toast.error("Network error !")
			else toast.error(`An unknown error occurred ${type ? type : ""} ${message}`)
			break
	}
}