import React, { createContext, FC, ReactElement, useContext, useState } from 'react';
import { validateValue, ValidatorTypes } from '../types/Validation';

interface IProps {
	children: ReactElement
}

export interface IFormContext {
	errors: Map<string, string>
	setErrors: (value: (prev: Map<string, string>) => Map<string, string>) => void
	hasChanged: boolean,
	setHasChanged: (value: boolean) => void,
	validateForm: () => boolean,
	addValue: (id: string, value: any) => void
	addValidationType: (id: string, validation: ValidatorTypes[] | undefined) => void
}

export function useFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useFormContext must be used within a FormProvider');
	}
	return context;
}

const FormContext = createContext<IFormContext>(null!)

const FormProvider: FC<IProps> = ({children}: IProps) => {

	const [values, setValues] = useState<Map<string, any>>(new Map())
	const [validators, setValidators] = useState<Map<string, ValidatorTypes[]>>(new Map())
	const [errors, setErrors] = useState<Map<string,string>>(new Map())
	const [hasChanged, setHasChanged] = useState<boolean>(false)

	const validateForm = () : boolean => {
		let response = true
		values.forEach((value, key) => {
			const vals = validators.get(key)
			if(vals) {
				const {isValid, error} = validateValue(value, vals)
				if(!isValid) {
					response = false
					setErrors(prev => {
						return new Map(prev.set(key, error))
					})
				}
			}
		})
		return response
	}

	const addValue = (id: string, value: string) => {
		setValues(prev => prev.set(id, value))
		if(errors.has(id)) {
			const vals = validators.get(id)
			if(vals) {
				const {isValid, error} = validateValue(value, vals)
				if(!isValid) {
					setErrors(prev => {
						return new Map(prev.set(id, error))
					})
				} else {
					setErrors(new Map())
				}
			}
		}
	}

	const addValidationType = (id: string, validation: ValidatorTypes[] | undefined) => {
		if(!validation) return
		setValidators(prev => {
			return prev.set(id, validation)
		})
	}

	return (
		<FormContext.Provider value={{
			errors,
			setErrors,
			hasChanged,
			setHasChanged,
			validateForm,
			addValue,
			addValidationType
		}}>
			{children}
		</FormContext.Provider>
	)
}

export default FormProvider
