import DefaultLayout from "../../layouts/Default";
import DataTable from "../../components/dataTable/DataTable";

const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Title", attr: "title" },
  { name: "Link", attr: "our_work.slug" },
  { name: "Slide Image", attr: "slide_image", type: "image" },
];

export default function OurWorkSlider() {

  return (
    <DefaultLayout>
      <DataTable
        title={"Our Work Slider"}
        apiUrl={"/our-work-slider"}
        url={"/our-work-slider"}
        tableHeader={tableHeader}
        allowDnDSorting
      />
    </DefaultLayout>
  );
}
