export interface DocumentItem {
  id: number,
  type: string,
  documentContents: DocumentContentItem[]
}

export interface DocumentContentItem {
  id: number,
  content: string,
  languageId: number
}

export const defaultData: DocumentItem = {
  id: -1,
  type: '',
  documentContents: [],
};