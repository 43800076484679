export interface MediaLink {
	id: number;
	text: string;
	href: string;
}

export interface SettingsItem {
	id: number,
	title: string,
	company: string,
	address: string,
	VAT: string,
	VAT_note: string,
	sales_email: string,
	marketing_email: string,
	facebook: string,
	instagram: string,
	linkedin: string,
	youtube: string,
	twitter: string,
	clutch: string,
	links: MediaLink[]
}

export const defaultSettingsItem: SettingsItem = {
	id: 0,
	address: '',
	sales_email: '',
	marketing_email: '',
	VAT: '',
	VAT_note: '',
	company: '',
	facebook: '',
	instagram: '',
	linkedin: '',
	title: '',
	twitter: '',
	youtube: '',
	clutch: '',
	links: []
}
