import React from 'react'
import DefaultLayout from '../layouts/Default';
import { useUserContext } from '../store/UserProvider';
import { Typography } from '@mui/material';

export default function Homepage() {

	const userContext = useUserContext();

	return (
		<DefaultLayout>
			<div style={{padding: 20}}>
				<Typography variant={"h6"}>Dobrý deň, {userContext.first_name || userContext.last_name ? `${userContext.first_name} ${userContext.last_name}` : userContext.email} !</Typography>
			</div>
		</DefaultLayout>
	)
}