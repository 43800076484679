import React, {FC, useState} from 'react';
import DefaultLayout from "../layouts/Default";
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import TextInput from '../components/TextInput';
import { useUserContext } from '../store/UserProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import FormProvider, {useFormContext} from '../store/FormProvider';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { getAuth } from '../utils/Auth';
import {UserItem} from "../types/UserItem";
import {handleResponseError} from "../utils/methods";
import ProfilePicker from '../components/ProfilePicker';

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: "0 30px"
	},
	heading: {
		alignItems: "center",
		marginBottom: 20,
	},
	controls: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 20
	},
	content: {
		marginTop: 20,
	},
	form: {
		backgroundColor: "white",
		padding: 20,
		borderRadius: 10,
		gap: 10
	}
});

interface Passwords {
	old_password: string,
	password: string,
	password_conf: string
}

const defaultPasswords: Passwords = {
	password: '',
	password_conf: '',
	old_password: ''
}

const Content:FC = () => {

	const classes = useStyles();
	const navigate = useNavigate();
	const formContext = useFormContext()
	const userContext = useUserContext()

	const getFormData = () => {
		return {
			email: userContext.email,
			first_name: userContext.first_name,
			last_name: userContext.last_name,
			biographical_info: userContext.biographical_info,
			profile_picture: userContext.profile_picture
		}
	}

	const editUser = () => {

		if(!formContext.validateForm()) return
		axios.put(`${API_URL}/user/${userContext.id}`, getFormData(), getAuth()).then(() => {
			toast.success("Profile edited successfully");
		}).catch(err => {
			console.error(err)
			handleResponseError(err.response, "while editing user")
		})
		axios.get(`${API_URL}/media/${getFormData().profile_picture?.id}`, getAuth()).then(res => {
			userContext.setUser((prev: UserItem) => {
				return {...prev, profile_picture: res.data, profile_picture_id: res.data.profile_picture_id}
			})
		}).catch(err => {
			console.error(err)
			handleResponseError(err.response, "while getting media for profile picture")
		})
	}

	const [dataChanged, setDataChanged] = useState<boolean>(false)


	const changeUserData = (attr: string, value: any) => {
		setDataChanged(true)
		userContext.setUser((prev: UserItem) => {
			return {...prev, [attr]: value}
		})
	}

	const handleGoBack = () => {
		navigate(-1)
	}

	return (
		<>
			<div className={classes.heading}>
				<Typography variant={"h4"} style={{fontWeight: 600, marginRight: "20px"}}>Profile</Typography>
			</div>
			<div className={classes.controls}>
				<ArrowBackIcon onClick={handleGoBack} />
			</div>
			<div className={classes.form}>
				<div style={{display: "flex", width: "100%"}}>
					<ProfilePicker photo={userContext.profile_picture?.file} avatar={userContext.avatar} onChange={changeUserData} />
					<div style={{flexGrow: 100}}>
						<TextInput id={"first_name"} label={"First Name"} value={userContext.first_name} onChange={changeUserData} validators={[{required: true}]} />
						<TextInput id={"last_name"} label={"Last Name"} value={userContext.last_name} onChange={changeUserData} validators={[{required: true}]} />
						<TextInput id={"email"} label={"Email"} value={userContext.email} onChange={changeUserData} validators={[{required: true}]} />
					</div>
				</div>
				<TextInput id={"biographical_info"} label={"Biographical Info"} value={userContext.biographical_info} onChange={changeUserData}/>
				<TextInput value={userContext.role} id={"role"} label={"Role"} validators={[]} onChange={() => {}} disabled={true}/>
				<Button variant={"contained"} style={{marginTop: 20}} disabled={!dataChanged} onClick={editUser}>SAVE CHANGES</Button>
			</div>
		</>
	)
}

const PasswordReset = () => {

	const classes = useStyles();
	const formContext = useFormContext()

	const [passwords, setPasswords] = useState<Passwords>(defaultPasswords)

	const changeData = (attr: string, value: any) => {
		setPasswords(prev => {
			return {...prev, [attr]: value}
		})
	}

	const resetPassword = () => {
		if(!formContext.validateForm()) return
		if(passwords.old_password !== passwords.password) {
			formContext.setErrors(prev => {
				prev.delete("password")
				return new Map(prev)
			})
			if (passwords.password === passwords.password_conf) {
				formContext.setErrors(prev => {
					prev.delete("password_conf")
					return new Map(prev)
				})
				axios.post(API_URL + "/auth/change-password", passwords, getAuth()).then(() => {
					toast.success("Password changed successfully");
				}).catch(err => {
					console.error(err)
					handleResponseError(err.response, "while changing password")
				})
			} else {
				formContext.setErrors(prev => {
					return new Map(prev.set("password_conf", "Passwords doesn't match"))
				})
			}
		} else {
			formContext.setErrors(prev => {
				return new Map(prev.set("password", "New password can't be same as old password"))
			})
		}
	}

	return (
		<div className={classes.form} style={{marginTop: 30}}>
			<TextInput value={passwords.old_password} onChange={changeData} id={"old_password"} label={"Old Password"} type={"password"} validators={[{required: true}]}/>
			<TextInput value={passwords.password} onChange={changeData} id={"password"} label={"New Password"} type={"password"} validators={[{required: true}, {minLimit: 8, error: "Password should be at least 8 characters long"}]}/>
			<TextInput value={passwords.password_conf} onChange={changeData} id={"password_conf"} label={"New Password Again"} type={"password"} validators={[{required: true}, {minLimit: 8, error: "Password should be at least 8 characters long"}]}/>
			<Button variant={"contained"} style={{marginTop: 20}} onClick={resetPassword}>CHANGE PASSWORD</Button>
		</div>
	)
}

const Profile = () => {

	const classes = useStyles();

	return (
		<DefaultLayout>
		<div className={`${classes.root} max-width-container`}>
			<FormProvider>
				<Content  />
			</FormProvider>
			<FormProvider>
				<PasswordReset  />
			</FormProvider>
		</div>
		</DefaultLayout>
	)
}

export default Profile
