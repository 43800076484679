import React, { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import ForgottenPassword from "./pages/ForgottenPassword";
import Homepage from "./pages/Homepage";
import PrivateRoute from "./utils/PrivateRoute";
import Categories from "./pages/Categories";
import MediaLibrary from "./pages/mediaLibrary/MediaLibrary";
import Blog from "./pages/Blog";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import Contacts from "./pages/contact/Contacts";
import Positions from "./pages/position/Positions";
import Partners from "./pages/Partners";
import OurWork from "./pages/ourWork/OurWork";
import Testimonials from "./pages/Testimonials";
import Galleries from "./pages/Galleries";
import Tags from "./pages/Tags";
import OurWorkSlider from "./pages/ourWork/OurWorkSlider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Profile from "./pages/Profile";
import Error from "./layouts/Error";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import SettingsIcon from "@mui/icons-material/Settings";
import { Roles } from "./types/Roles";
import { useUserContext } from "./store/UserProvider";
import OurWorkDetail from "./pages/ourWork/OurWorkDetail";
import MediaLibraryDetail from "./pages/mediaLibrary/MediaLibraryDetail";
import Languages from "./pages/Languages";
import StringKeys from "./pages/StringKeys";
import Translations from "./pages/Translations";
import TranslationsDetail from "./pages/TranslationsDetail";
import DocumentsDetail from "./pages/documents/DocumentsDetail";
import Documents from "./pages/documents/Documents";
import JobContacts from "./pages/JobContacts";
import BranchManagers from "./pages/BranchManagers";
import PositionDetail from "./pages/position/PositionDetail";
import OurWorkSliderDetail from "./pages/ourWork/OurWorkSliderDetail";
import ContactDetail from "./pages/contact/ContactDetail";
import AboutUs from "./pages/aboutUs/AboutUs";
import AboutUsDetail from "./pages/aboutUs/AboutUsDetail";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const ROUTES: {
  title: string;
  items: {
    icon: ReactNode;
    title: string;
    to: string;
    hasDetail: boolean;
    isPrivate: boolean;
    auth: Roles[];
    component: JSX.Element;
    detailComponent?: JSX.Element;
  }[];
}[] = [
    {
      title: "Data",
      items: [
        {
          icon: <SettingsIcon />,
          title: "Contacts",
          to: "/contacts",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR],
          component: <Contacts />,
          detailComponent: <ContactDetail />,
        },
        {
          icon: <SettingsIcon />,
          title: "Job Contacts",
          to: "/job-contacts",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR],
          component: <JobContacts />,
        },
        {
          icon: <SettingsIcon />,
          title: "Our Work",
          to: "/our-work",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <OurWork />,
          detailComponent: <OurWorkDetail />,
        },
        {
          icon: <SettingsIcon />,
          title: "Our Work Slider",
          to: "/our-work-slider",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <OurWorkSlider />,
          detailComponent: <OurWorkSliderDetail />
        },
        {
          icon: <SettingsIcon />,
          title: "Galleries",
          to: "/galleries",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <Galleries />,
        },
        {
          icon: <SettingsIcon />,
          title: "Partners",
          to: "/partners",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR],
          component: <Partners />,
        },
        {
          icon: <SettingsIcon />,
          title: "Positions",
          to: "/positions",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR],
          component: <Positions />,
          detailComponent:<PositionDetail />,
        },
        {
          icon: <SettingsIcon />,
          title: "Testimonials",
          to: "/testimonials",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR],
          component: <Testimonials />,
        },
        {
          icon: <SettingsIcon />,
          title: "Blogs",
          to: "/blogs",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <Blog />,
        },
        {
          icon: <SettingsIcon />,
          title: "Categories",
          to: "/categories",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <Categories />,
        },
        {
          icon: <SettingsIcon />,
          title: "Tags",
          to: "/tags",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <Tags />,
        },
        {
          icon: <SettingsIcon />,
          title: "Media library",
          to: "/media-library",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <MediaLibrary />,
          detailComponent: <MediaLibraryDetail />,
        },
        {
          icon: <SettingsIcon />,
          title: "Documents",
          to: "/documents",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.ADMIN],
          component: <Documents />,
          detailComponent: <DocumentsDetail />,
        },
        {
          icon: <SettingsIcon />,
          title: "Branch Managers",
          to: "/branch-manager",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.ADMIN],
          component: <BranchManagers />,
        },
        {
          icon: <SettingsIcon />,
          title: "About Us",
          to: "/about-us",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.EDITOR],
          component: <AboutUs />,
          detailComponent: <AboutUsDetail />,
        },
      ],
    },
    {
      title: "Settings",
      items: [
        {
          icon: <SettingsIcon />,
          title: "Settings",
          to: "/settings",
          hasDetail: false,
          isPrivate: true,
          auth: [],
          component: <Settings />,
        },
        {
          icon: <SettingsIcon />,
          title: "Users",
          to: "/users",
          hasDetail: true,
          isPrivate: true,
          auth: [],
          component: <Users />,
        },
        {
          icon: <SettingsIcon />,
          title: "Profile",
          to: "/profile",
          hasDetail: false,
          isPrivate: true,
          auth: [Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <Profile />,
        },
      ],
    },
    {
      title: "Localisation",
      items: [
        {
          icon: <SettingsIcon />,
          title: "Languages",
          to: "/languages",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.ADMIN],
          component: <Languages />,
        },
        {
          icon: <SettingsIcon />,
          title: "String keys",
          to: "/string-keys",
          hasDetail: true,
          isPrivate: true,
          auth: [Roles.ADMIN, Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <StringKeys />,
        },
        {
          icon: <SettingsIcon />,
          title: "Translations",
          to: "/translations",
          hasDetail: false,
          isPrivate: true,
          auth: [Roles.ADMIN, Roles.EDITOR, Roles.CONTRIBUTOR],
          component: <Translations />,
        },
      ]
    }
  ];

function App() {
  const userContext = useUserContext();

  const getComponent = (component: JSX.Element, isPrivate: boolean) =>
    isPrivate ? <PrivateRoute component={component} /> : component;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Routes>
            <Route
              path={"/"}
              element={<PrivateRoute component={<Homepage />} />}
            />

            {ROUTES.map((section) =>
              section.items.map((item) => {
                const detailComponent =
                  item.hasDetail && item.detailComponent
                    ? item.detailComponent
                    : item.component;

                if (
                  item.auth.find((role) => userContext.role === role) ||
                  userContext.role === Roles.ADMIN
                ) {
                  return (
                    <>
                      <Route
                        path={item.to}
                        element={getComponent(item.component, item.isPrivate)}
                      />
                      {item.hasDetail && (
                        <Route
                          path={item.to + "/:entityId"}
                          element={getComponent(
                            detailComponent,
                            item.isPrivate
                          )}
                        />
                      )}
                    </>
                  );
                }
                return null;
              })
            )}
            <Route path={"/translations/:languageId"} element={<TranslationsDetail />} />
            <Route path={"/login"} element={<Login />} />
            <Route
              path={"/forgotten-password"}
              element={<ForgottenPassword />}
            />
            <Route path={"*"} element={<Error />} />
          </Routes>
          <ToastContainer theme={"colored"} />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
