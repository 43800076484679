import { scoreToRating } from "yoastseo";
import  Rating  from "./Rating";

interface PresenterProps {
  assessor: any;
}

const Presenter = ({ assessor }: PresenterProps) => {
  const isObject = (obj: any) => {
    var type = typeof obj;
    return type === "function" || (type === "object" && !!obj);
  };

  const getScores = () => {
    const scores = assessor.getValidResults().map((r: any) => {
      if (!isObject(r) || !r.getIdentifier()) {
        return ``;
      }
      r.rating = scoreToRating(r.score);
      return r;
    });

    return scores.filter((a: any) => a !== "");
  };

  const addRating = (item: any) => {
    return {
      rating: item.rating,
      text: item.text,
      identifier: item.getIdentifier(),
      score: item.score,
    };
  };

  let ratings: any[] = [];
  Object.entries(getScores()).forEach(([key, item]) => {
    ratings.push(addRating(item));
  });

  return (
    <div>
      {ratings
        .sort((a, b) => {
          // First compare the score.
          if (a.score < b.score) {
            return -1;
          }
          if (a.score > b.score) {
            return 1;
          }
          // If there is no difference then compare the id.
          return a.id - b.id;
        })
        .map((r) => (
          <Rating
            key={r.identifier}
            id={r.identifier}
            rating={r.rating}
            text={r.text}
            score={r.score}            
          />
        ))}
    </div>
  );
};

export default Presenter;
