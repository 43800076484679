import DefaultLayout from "../layouts/Default";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import EntityDetailLayout from "../layouts/EntityDetail";
import TextInput from "../components/TextInput";
import DataTable from "../components/dataTable/DataTable";
import { defaultData, StringKeyItem } from "../types/StringKeyItem";

const tableHeader = [
  {name: "ID", attr: "id"},
  {name: "Section", attr: "section"},
  {name: "Key", attr: "key"},
  {name: "Description", attr: "description"},
  {name: "Default Translation", attr: "default"},
]

export default function StringKeys() {
  const {entityId} = useParams()
  const [detailData, setDetailData] = useState<StringKeyItem>(defaultData)

  const getFormData = () => {
    return {
      id: detailData.id,
      section: detailData.section,
      key: detailData.key,
      description: detailData.description,
      default: detailData.default
    }
  }

  const changeData = (attr: string, value: any) => {
    setDetailData(prev => {
      return {...prev, [attr]: value}
    })
  }

  if (entityId) {
    return (
      <DefaultLayout>
        <EntityDetailLayout id={detailData.id} title={"Keys"} apiUrl={"/string-key"}
                            defaultValue={defaultData} setData={setDetailData} getFormData={getFormData}>
          <TextInput value={detailData.section} onChange={changeData} id={"section"} label={"Section"}
                     validators={[{required: true}]}/>
          <TextInput value={detailData.key} onChange={changeData} id={"key"} label={"Key"}
                     validators={[{required: true}]}/>
          <TextInput value={detailData.description} onChange={changeData} id={"description"} label={"Description"}
                     validators={[{required: true}]}/>
          <TextInput value={detailData.default} onChange={changeData} id={"default"} label={"Default Translation"}
                     validators={[{required: true}]}/>
        </EntityDetailLayout>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      <DataTable title={"Keys"} apiUrl={"/string-key"} url={"/string-keys"} tableHeader={tableHeader}/>
    </DefaultLayout>
  );
}