import { useEffect, useState } from "react";
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import { useParams } from "react-router-dom";
import EntityDetailLayout from "../layouts/EntityDetail";
import { makeStyles } from "@mui/styles";
import MediaPicker from "../components/MediaPicker";
import CategoryPicker from "../components/CategoryPicker";
import TextInput from "../components/TextInput";
import OptionPicker from "../components/OptionPicker";
import { UserItem } from "../types/UserItem";
import { BlogItem, defaultBlogData } from "../types/BlogItem";
import QuillEditor from "../components/QuillEditor/QuillEditor";
import { useUserContext } from "../store/UserProvider";
import DatePicker from "../components/DatePicker";
import TagPicker from "../components/TagPicker";
import { API_URL } from "../utils/constants";
import axios from "axios";
import { getAuth } from "../utils/Auth";
import { getImageForm, handleResponseError } from "../utils/methods";
import { GalleryItem } from "../types/GalleryItem";
import { usePreview } from "../hooks/usePreview";
import AutocompleteField from "../components/AutocompleteField";
import AnalysisResults from "../components/seo/AnalysisResults";
import { useSeoAnalysis } from "../components/seo/useSeoAnalysis";
import { Preview, Google } from "@mui/icons-material";
import { Status } from "../types/global";
import {
  clearStorage,
  getDraftDataFromStorage,
  persistOnChange,
} from "../utils/persistance";
import { Box } from "@mui/material";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    gap: 20,
  },
  mainEditor: {
    width: "80%",
  },
  seoSidebar: {
    width: "20%",
  },
  contentBox: {
    display: "flex",
    width: "100%",
    gap: 20,
  },
  leftSide: {
    width: "50%",
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
    "& img": {
      width: "100%",
    },
  },
  rightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  quill: {
    "& .ql-container": {
      height: "800px",
      maxHeight: "800px",
    },
  },
  quillError: {
    border: "1px solid red",
  },
  date: {
    width: "100%",
  },
});

const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Title", attr: "title" },
  { name: "slug", attr: "slug" },
];

const BLOG_LOCAL_STORAGE_KEY = "NEW_BLOG_DRAFT";

export default function Blog() {
  const classes = useStyles();
  const { entityId } = useParams();
  const userContext = useUserContext();

  // We need to create date here to renew it each time component is created.
  const date = new Date().toISOString();
  const componentDefaultBlogData = {
    ...defaultBlogData,
    ...getDraftDataFromStorage(entityId, BLOG_LOCAL_STORAGE_KEY),
    created_at: date,
  };

  const [detailData, setDetailData] = useState<BlogItem>(
    componentDefaultBlogData
  );

  const [authors, setAuthors] = useState<UserItem[]>([]);
  const [seoAnalysisRunning, setSeoAnalysisRunning] = useState(false);

  const [seoAssessor, initSeoAnalysis, analyzeContent, endSeoAnalysis] =
    useSeoAnalysis();

  const createPreviewData = async () => {
    const categories = await axios.get(
      `${API_URL}/blog/category/${detailData.slug}`,
      getAuth()
    );
    // @ts-ignore
    const image = await axios.get(
      `${API_URL}/media/${
        Array.isArray(detailData.image)
          ? detailData.image[0]?.id
          : detailData.image.id
      }`,
      getAuth()
    );
    const user = await axios.get(
      `${API_URL}/user/${detailData.author_id ?? detailData.author?.id}`,
      getAuth()
    );
    const galleries: GalleryItem[] = [];
    await Promise.all(
      detailData.galleries.map(async (gallery) => {
        const data = await axios.get(
          `${API_URL}/gallery/${gallery.id}`,
          getAuth()
        );
        galleries.push(data.data);
      })
    );

    return {
      title: detailData.title,
      sub_title: detailData.sub_title,
      short_description: detailData.short_description,
      slug: detailData.slug,
      content: detailData.content,
      status: detailData.status,
      redirect: detailData.redirect ? detailData.redirect : undefined,
      meta_title: detailData.meta_title,
      meta_description: detailData.meta_description,
      focus_keyphrase: detailData.focus_keyphrase,
      image: image?.data ? image.data : undefined,
      galleries: galleries,
      tags: getIdForm(detailData.tags),
      categories: categories.data.data,
      author: user.data,
      created_at: detailData.created_at,
    };
  };

  const getUsers = (searchTerm?: string | null) => {
    axios
      .get(`${API_URL}/user?search=${searchTerm ?? ""}`, getAuth())
      .then((res) => {
        setAuthors(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while loading users");
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    persistOnChange(entityId, BLOG_LOCAL_STORAGE_KEY, detailData);
  }, [detailData, entityId]);

  const { openPreview } = usePreview("blog", createPreviewData, detailData);

  const getFormData = () => {
    return {
      author: detailData.author,
      title: detailData.title,
      sub_title: detailData.sub_title,
      short_description: detailData.short_description,
      slug: detailData.slug,
      content: detailData.content,
      status: detailData.status,
      redirect: detailData.redirect ? detailData.redirect : undefined,
      meta_title: detailData.meta_title,
      meta_description: detailData.meta_description,
      focus_keyphrase: detailData.focus_keyphrase,
      image: getImageForm(detailData.image),
      galleries: detailData.galleries,
      tags: getIdForm(detailData.tags),
      categories: getIdForm(detailData.categories),
      created_at: detailData.created_at,
      social_facebook_description: detailData.social_facebook_description,
      social_facebook_image: getImageForm(detailData.social_facebook_image),
      social_facebook_title: detailData.social_facebook_title,
      social_twitter_description: detailData.social_twitter_description,
      social_twitter_image: getImageForm(detailData.social_twitter_image),
      social_twitter_title: detailData.social_twitter_title,
    };
  };

  const getIdForm = (value: any): { id: number }[] => {
    if (!value) return [];
    if (!Array.isArray(value)) return [{ id: value.id }];
    return value.map((item) => {
      return { id: item.id };
    });
  };

  const changeData = (attr: string, value: any) => {
    if (attr === "author") {
      setDetailData((prev: any) => {
        return { ...prev, author: authors.find((a) => a.email === value) };
      });
      return;
    }

    let updatedData: BlogItem = { ...detailData, [attr]: value };

    setDetailData((prev: BlogItem) => {
      updatedData = { ...prev, [attr]: value };

      return updatedData;
    });

    seoAnalysisRunning && analyzeContent(updatedData);
  };

  const toggleSeoAnalysis = () => {
    setSeoAnalysisRunning((opened) => {
      if (!opened) {
        initSeoAnalysis(detailData);
        analyzeContent(detailData);
      } else {
        endSeoAnalysis();
      }
      return !opened;
    });
  };

  const isEditing = () => entityId?.toLowerCase() !== "new";

  if (entityId) {
    return (
      <DefaultLayout>
        <EntityDetailLayout
          id={detailData.id}
          title={"Blogs"}
          apiUrl={"/blog"}
          defaultValue={componentDefaultBlogData}
          navigateBack={isEditing() ? false : true}
          setData={setDetailData}
          getFormData={getFormData}
          customButtons={[
            ...(detailData.content.length > 100
              ? [
                  {
                    label: `${
                      seoAnalysisRunning ? "STOP" : "START"
                    } SEO ANALYSIS`,
                    onClick: toggleSeoAnalysis,
                    floatButtonIcon: <Google />,
                  },
                ]
              : []),
            {
              label: "WEB PREVIEW",
              onClick: openPreview,
              floatButtonIcon: <Preview />,
            },
          ]}
          onSave={() => clearStorage(BLOG_LOCAL_STORAGE_KEY)}
          onOpen={() => {
            endSeoAnalysis();
            setSeoAnalysisRunning(false);
          }}
        >
          <div className={seoAnalysisRunning ? classes.wrapper : ""}>
            <div className={seoAnalysisRunning ? classes.mainEditor : ""}>
              <div className={classes.contentBox}>
                <div className={classes.leftSide}>
                  <MediaPicker
                    id={"image"}
                    label={"Image"}
                    mediaType={"image"}
                    value={detailData.image}
                    onChange={changeData}
                    validators={[{ required: true }]}
                  />
                  <MediaPicker
                    value={detailData.social_facebook_image}
                    id={"social_facebook_image"}
                    label={"Social Media Image"}
                    mediaType={"image"}
                    onChange={changeData}
                  />
                </div>
                <div className={classes.rightSide}>
                  <TextInput
                    value={detailData.slug}
                    onChange={changeData}
                    id={"slug"}
                    label={"Slug"}
                    validators={[
                      { maxLimit: 255 },
                      { regex: /^[-a-zA-Z0-9_]+$/ },
                      { required: true },
                    ]}
                  />
                  <TextInput
                    value={detailData?.title}
                    onChange={changeData}
                    id={"title"}
                    label={"Title"}
                    validators={[{ required: true }, { maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData?.sub_title}
                    onChange={changeData}
                    id={"sub_title"}
                    label={"Sub Title"}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData?.short_description}
                    onChange={changeData}
                    id={"short_description"}
                    label={"Short Description"}
                    multiline
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData.redirect}
                    multiline={true}
                    onChange={changeData}
                    id={"redirect"}
                    label={"Redirect"}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <CategoryPicker
                    value={detailData.categories}
                    onChange={changeData}
                    id={"categories"}
                    label={"Categories"}
                    validators={[{ required: true }, { minLimit: 1 }]}
                  />
                  <TagPicker
                    value={detailData.tags}
                    onChange={changeData}
                    id={"tags"}
                    label={"Tags"}
                    validators={[]}
                  />
                  <AutocompleteField
                    id="author"
                    value={detailData.author?.email ?? userContext.email}
                    options={authors.map((a) => a.email)}
                    onChange={(_: any, newValue: string | null) =>
                      changeData("author", newValue)
                    }
                    onInputChange={(_: any, newInputValue: string | null) =>
                      getUsers(newInputValue)
                    }
                    label="Author"
                  />
                  <OptionPicker
                    value={detailData.status}
                    id={"status"}
                    label={"Status"}
                    validators={[{ required: true }]}
                    onChange={changeData}
                    options={Object.values(Status)}
                  />
                  <DatePicker
                    value={detailData?.created_at}
                    onChange={changeData}
                    id={"created_at"}
                    label={"Created At"}
                    className={classes.date}
                  />
                  <TextInput
                    value={detailData.focus_keyphrase}
                    onChange={changeData}
                    id={"focus_keyphrase"}
                    label={"Key phrase"}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData.meta_title}
                    onChange={changeData}
                    id={"meta_title"}
                    label={"Meta Title"}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData.meta_description}
                    multiline={true}
                    onChange={changeData}
                    id={"meta_description"}
                    label={"Meta Description"}
                  />
                  <TextInput
                    value={detailData.social_facebook_title}
                    id={"social_facebook_title"}
                    label={"Social Facebook Title"}
                    onChange={changeData}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData.social_facebook_description}
                    id={"social_facebook_description"}
                    label={"Social Facebook Description"}
                    onChange={changeData}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData.social_twitter_title}
                    id={"social_twitter_title"}
                    label={"Social Twitter Title"}
                    onChange={changeData}
                    validators={[{ maxLimit: 255 }]}
                  />
                  <TextInput
                    value={detailData.social_twitter_description}
                    id={"social_twitter_description"}
                    label={"Social Twitter Description"}
                    onChange={changeData}
                    validators={[{ maxLimit: 255 }]}
                  />
                </div>
              </div>
              <Box sx={{ width: "740px", m: "auto", marginTop: 2 }}>
                <QuillEditor
                  value={detailData?.content}
                  onChange={changeData}
                  gallery_id={"galleries"}
                  galleries={detailData.galleries}
                  id={"content"}
                  label={"Content"}
                  validators={[{ required: true }]}
                />
              </Box>
            </div>
            <aside className={classes.seoSidebar}>
              {seoAssessor && <AnalysisResults seoAssessor={seoAssessor} />}
            </aside>
          </div>
        </EntityDetailLayout>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <DataTable
        title={"Blogs"}
        apiUrl={"/blog"}
        url={"/blogs"}
        tableHeader={tableHeader}
        onAddNew={() => clearStorage(BLOG_LOCAL_STORAGE_KEY)}
      />
    </DefaultLayout>
  );
}
