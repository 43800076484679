import { Status } from './global';
import { UserItem } from './UserItem';
import { CategoryItem } from './CategoryItem';

export interface BlogItem {
	id: number,
	title: string,
	sub_title: string,
	short_description: string,
	slug: string,
	content: string,
	author: UserItem | undefined,
	image: {id: number} | {id: number}[]
	redirect: string,
	status: Status,
	meta_title: string,
	meta_description: string,
	focus_keyphrase: string,
	categories: CategoryItem[]
	tags: {id: number}[],
	galleries: {id: number}[],
	created_at: string,
	updated_at: string,
	author_id: number,
	social_facebook_image: {id: number}[],
	social_facebook_title: string,
	social_facebook_description: string,
	social_twitter_image: {id: number}[],
	social_twitter_title: string,
	social_twitter_description: string,
}

export const defaultBlogData: BlogItem = {
	author: undefined,
	author_id: 0,
	categories: [],
	content: '',
	/*
		We don't create date value here because is cached during aplication lifetime
		and we need to renew it each time blog form is created.
	*/
	created_at: '',
	focus_keyphrase: '',
	galleries: [],
	id: 0,
	image: [],
	meta_description: '',
	meta_title: '',
	redirect: '',
	short_description: '',
	slug: '',
	status: Status.DRAFT,
	sub_title: '',
	tags: [],
	title: '',
	updated_at: '',
	social_facebook_description: "",
	social_facebook_image: [],
	social_facebook_title: "",
	social_twitter_description: "",
	social_twitter_image: [],
	social_twitter_title: "",
}
