import { Preview } from "@mui/icons-material";
import { useEffect, useState } from "react";
import MediaPicker from "../../components/MediaPicker";
import { usePreview } from "../../hooks/usePreview";
import DefaultLayout from "../../layouts/Default";
import EntityDetailLayout from "../../layouts/EntityDetail";
import QuillEditor from "../../components/QuillEditor/QuillEditor";
import TextInput from "../../components/TextInput";
import { AboutUsItem, defaultAboutUsItem } from "../../types/AboutUsItem";
import { getImageForm } from "../../utils/methods";
import axios from "axios";
import { getAuth } from "../../utils/Auth";
import { API_URL } from "../../utils/constants";
import { ABOUT_US_LOCAL_STORAGE_KEY } from "./AboutUsConst";
import {
  clearStorage,
  getDraftDataFromStorage,
  persistOnChange,
} from "../../utils/persistance";
import { useParams } from "react-router-dom";
import { LanguageItem } from "../../types/LanguageItem";
import OptionPicker from "../../components/OptionPicker";

export default function AboutUsDetail() {
  const { entityId } = useParams();

  const [languageOptions, setLanguageOptions] = useState<string[]>(['en']);

  useEffect(() => {
    axios
      .get(`${API_URL}/language`, getAuth())
      .then((res) => res.data.data.map((lang: LanguageItem) => lang.isoCode))
      .then(setLanguageOptions)
  }, []);

  const defaultData = {
    ...defaultAboutUsItem,
    ...getDraftDataFromStorage(entityId, ABOUT_US_LOCAL_STORAGE_KEY),
  };

  const [detailData, setDetailData] = useState<AboutUsItem>(defaultData);

  useEffect(() => {
    persistOnChange(entityId, ABOUT_US_LOCAL_STORAGE_KEY, detailData);
  }, [detailData, entityId]);

  const getFormData = () => {
    return {
      ...detailData,
      featured_image: getImageForm(detailData.featured_image, true),
      birthday_video: getImageForm(detailData.birthday_video, true),
      birthday_video_cover: getImageForm(detailData.birthday_video_cover, true),
      recommendation_video: getImageForm(detailData.recommendation_video, true),
      recommendation_video_cover: getImageForm(detailData.recommendation_video_cover, true),
    };
  };

  const createPreviewData = async () => {
    const featured_image = await axios.get(
      `${API_URL}/media/${getImageForm(detailData.featured_image, true)?.id}`,
      getAuth()
    );

    const data = {
      ...detailData,
      featured_image: featured_image.data.data,
      birthday_video: getImageForm(detailData.birthday_video, true),
      birthday_video_cover: getImageForm(detailData.birthday_video_cover, true),
      recommendation_video: getImageForm(detailData.recommendation_video, true),
      recommendation_video_cover: getImageForm(detailData.recommendation_video_cover, true),
    };

    return data;
  };

  const { openPreview } = usePreview("about-us", createPreviewData, detailData);

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  const isEditing = () => entityId?.toLowerCase() !== "new";

  return (
    <DefaultLayout>
      <EntityDetailLayout
        id={detailData.id}
        title="About us"
        apiUrl="/about-us"
        defaultValue={defaultData}
        setData={setDetailData}
        getFormData={getFormData}
        navigateBack={isEditing() ? false : true}
        onSave={() => clearStorage(ABOUT_US_LOCAL_STORAGE_KEY)}
        customButtons={[
          {
            label: "WEB PREVIEW",
            onClick: openPreview,
            floatButtonIcon: <Preview />,
          },
        ]}
      >
        <OptionPicker
          value={detailData.language}
          id={"language"}
          label={"Language"}
          validators={[{ required: true }]}
          onChange={changeData}
          options={languageOptions}
        />

        <MediaPicker
          mediaType={"image"}
          value={detailData.featured_image}
          onChange={changeData}
          id={"featured_image"}
          label={"Featured Image"}
          validators={[{ required: true }]}
        />
        <QuillEditor
          value={detailData.description}
          onChange={changeData}
          id={"description"}
          label={"Description"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          canAddImage={false}
          validators={[]}
        />
        <TextInput
          value={detailData.moto}
          multiline
          onChange={changeData}
          id={"moto"}
          label={"Moto"}
          validators={[{ required: true }]}
        />
        <QuillEditor
          value={detailData.who_are_we}
          onChange={changeData}
          id={"who_are_we"}
          label={"Who are we?"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          canAddImage={false}
          validators={[]}
        />
        <MediaPicker
          mediaType="video"
          value={detailData.birthday_video}
          onChange={changeData}
          id={"birthday_video"}
          label={"Birthday Video"}
          validators={[{ required: true }]}
        />
         <MediaPicker
          mediaType={"image"}
          value={detailData.birthday_video_cover}
          onChange={changeData}
          id={"birthday_video_cover"}
          label={"Birthday Video Cover"}
          validators={[]}
        />
        <TextInput
          value={detailData.moto2}
          multiline
          onChange={changeData}
          id={"moto2"}
          label={"Moto 2"}
          validators={[{ required: true }]}
        />
        <QuillEditor
          value={detailData.how_do_we_succeed}
          onChange={changeData}
          id={"how_do_we_succeed"}
          label={"How do we succeed?"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          canAddImage={false}
          validators={[]}
        />
        <QuillEditor
          value={detailData.why_to_choose_us}
          onChange={changeData}
          id={"why_to_choose_us"}
          label={"Why to choose us?"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          canAddImage={false}
          validators={[]}
        />
        <MediaPicker
          mediaType="video"
          value={detailData.recommendation_video}
          onChange={changeData}
          id={"recommendation_video"}
          label={"Recommendation Video"}
          validators={[{ required: true }]}
        />
        <MediaPicker
          mediaType={"image"}
          value={detailData.recommendation_video_cover}
          onChange={changeData}
          id={"recommendation_video_cover"}
          label={"Recommendation Video Cover"}
          validators={[]}
        />
      </EntityDetailLayout>
    </DefaultLayout>
  );
}
