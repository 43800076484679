import { getMediaCfg } from './media';
import { getTagCfg } from './tag';
import { getGalleryCfg } from './gallery';
import { IProps as DataTableProps } from '../../components/dataTable/DataTable'
import { getTestimonialCfg } from './testimonial';
import { getOurWorkSliderCfg } from './our_work_slider';

export type ShowUsedInForEntity = 'media' | 'tag' | 'gallery' | 'testimonial' | 'ourWorkSlider';

const cfg: { [key in ShowUsedInForEntity]: (id: string) => DataTableProps[]} = {
    media: getMediaCfg,
    tag: getTagCfg,
    gallery: getGalleryCfg,
    testimonial: getTestimonialCfg,
    ourWorkSlider: getOurWorkSliderCfg,
}

export const getUsedInCfg = function getUsedInCfg(entity: ShowUsedInForEntity, id: string) {
    return cfg[entity](id);
};

