import { IProps as DataTableProps } from '../../components/dataTable/DataTable'
export const getOurWorkSliderCfg = function getOurWorkSliderCfg(id: string): DataTableProps[]  {

    return [
        {
            title: 'Our Work',
            apiUrl: `/our-work/our-work-slider/${id}`,
            url: '/our-work',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Created', attr: 'created_at'},
            ]
        }
        
    ]
} 