import DataTable from "../components/dataTable/DataTable";
import DefaultLayout from "../layouts/Default";
import React from "react";

const tableHeader = [
  {name: "ID", attr: "id"},
  {name: "Name", attr: "name"},
]

const Translations = () => {
  return (
    <DefaultLayout>
      <DataTable title={"Translations"} apiUrl={"/language"} url={"/translations"} tableHeader={tableHeader} hasAddButton={false}/>
    </DefaultLayout>
  );
}

export default Translations;