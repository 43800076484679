import DefaultLayout from '../../layouts/Default';
import DataTable from '../../components/dataTable/DataTable';

const locations: Record<any, string> = {
  '': 'All',
  slovakia: 'Slovakia (HQ)',
  serbia: 'Serbia',
  argentina: 'Argentina',
  tunisia: 'Tunisia',
  uk: 'United Kingdom',
};

const tableHeader = [
  { name: 'ID', attr: 'id' },
  { name: 'Title', attr: 'title' },
  { name: 'Location', attr: 'location' },
  { name: 'Slug', attr: 'slug' },
  { name: 'Is Open', attr: 'is_open', type: 'boolean' },
  { name: 'Status', attr: 'status' },
  { name: 'Position image', attr: 'position_image', type: 'image' },
];

export default function Positions() {
  
  return (
    <DefaultLayout>
      <DataTable
        title={'Positions'}
        apiUrl={'/position'}
        url={'/positions'}
        tableHeader={tableHeader}
        valueFormatter={(attr: string, value: any) => {
          if (attr === 'location') {
            return (value || '')
              .split(',')
              .map((location: string) => locations[location])
              .join(', ');
          } else {
            return value;
          }
        }}
      />
    </DefaultLayout>
  );
}
