/* eslint-disable no-useless-escape */
import React, { FC, useRef, useState } from "react";
import { v4 } from "uuid";

import DefaultLayout from "../layouts/Default";
import EntityDetailLayout from "../layouts/EntityDetail";
import { Typography, Button, Grid, Box } from "@mui/material";
import { Delete, Add, DragIndicator } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import TextInput from "../components/TextInput";
import {
  defaultSettingsItem,
  MediaLink,
  SettingsItem,
} from "../types/SettingsItem";
import MediaPicker from "../components/MediaPicker";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { HTML5Backend } from "react-dnd-html5-backend";

const useStyles = makeStyles({
  settings: {
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "0px 0px 15px -5px #ddd",
  },
  heading: {
    padding: "25px 0",
  },
});

const urlValidation = {
  regex:
    /^$|([(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/, // empty string or URL
  error: "Value should be an URL address",
  maxLimit: 100,
};

const SettingsLinkItem: FC<{
  link: MediaLink & { uuid: string };
  idx: number;
  onChangeText: (idx: number, newValue: string, key: string) => void;
  onChangeFile: (idx: number, file: { id: number }) => void;
  deleteItem: (idx: number) => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}> = ({ link, onChangeText, onChangeFile, idx, deleteItem, moveCard }) => {
  const ref = useRef<HTMLLIElement>(null);
  const [, drop] = useDrop<any, void, { handlerId: Identifier | null }>({
    accept: "linkItem",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = idx;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "linkItem",
    item: () => {
      return { id: link.uuid, index: idx };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <Grid
      container
      spacing={2}
      mb={4}
      component="li"
      key={`${link.uuid}`}
      style={{ opacity: isDragging ? 0 : 1, cursor: "move" }}
      ref={ref}
    >
      <Grid item sm={6}>
        <Grid container alignItems={"center"}>
          <Grid item xs={1}>
            <Box
              component={"span"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {idx + 1})<DragIndicator />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <div>
              <TextInput
                label={"Text"}
                id={`${idx}-${link.uuid}-text`}
                onChange={(id, value) => onChangeText(idx, value, "text")}
                value={link.text}
                validators={[{ required: true }, { maxLimit: 75 }]}
              />
            </div>
            <div>
              <TextInput
                label={"Url"}
                id={`${idx}-${link.uuid}-href`}
                onChange={(id, value) => onChangeText(idx, value, "href")}
                value={link.href}
                validators={[{ maxLimit: 100 }]}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <MediaPicker
          id="links"
          label="Files"
          value={link.id ? { id: link.id } : undefined}
          onChange={(id, item) => {
            onChangeFile(idx, item[0] || { id: 0 });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={"contained"}
          color={"error"}
          style={{ width: "100%" }}
          endIcon={<Delete />}
          onClick={() => deleteItem(idx)}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

const SettingLinks: FC<{
  links: SettingsItem["links"];
  onChange: (links: SettingsItem["links"]) => void;
}> = (props) => {
  const [stateLinks, setStateLinks] = useState<
    (MediaLink & { uuid: string })[]
  >(
    props.links.map((current) => ({
      ...current,
      uuid: v4(),
    }))
  );

  function execOnChange(data: typeof stateLinks) {
    props.onChange(data.map(({ uuid, ...rest }) => rest));
  }

  function onChangeText(idx: number, newValue: string, key: string) {
    setStateLinks((prev) => {
      const newState = prev.map((value, currentIdx) => {
        if (currentIdx === idx) {
          return {
            ...value,
            [key]: newValue,
          };
        } else {
          return value;
        }
      });

      execOnChange(newState);

      return newState;
    });
  }

  function onChangeFile(
    idx: number,
    item: {
      id: number;
    }
  ) {
    setStateLinks((prev) => {
      const newState = prev.map((value, currentIdx) => {
        if (currentIdx === idx) {
          return {
            ...value,
            id: item.id,
          };
        } else {
          return value;
        }
      });

      execOnChange(newState);

      return newState;
    });
  }

  function deleteItem(idx: number) {
    setStateLinks((prev) => {
      const newState = prev.filter((value, currentIdx) => currentIdx !== idx);
      execOnChange(newState);
      return newState;
    });
  }

  function addItem() {
    setStateLinks((prev) => {
      const newState = [...prev, { href: "", id: 0, text: "", uuid: v4() }];
      execOnChange(newState);
      return newState;
    });
  }

  function moveCard(dragIndex: number, hoverIndex: number) {
    const updatedStateLinks = Array.from(stateLinks);
    const [removed] = updatedStateLinks.splice(dragIndex, 1);
    updatedStateLinks.splice(hoverIndex, 0, removed);
    setStateLinks(updatedStateLinks);
    execOnChange(updatedStateLinks);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box component={"ul"} p={4}>
        {stateLinks.map((link, idx) => (
          <SettingsLinkItem
            key={link.uuid}
            link={link}
            idx={idx}
            onChangeFile={onChangeFile}
            onChangeText={onChangeText}
            deleteItem={deleteItem}
            moveCard={moveCard}
          />
        ))}
        <Button variant="contained" endIcon={<Add />} onClick={addItem}>
          Add item
        </Button>
      </Box>
    </DndProvider>
  );
};

const Settings = () => {
  const classes = useStyles();
  const [data, setData] = useState<SettingsItem>(defaultSettingsItem);

  const getFormData = (): Omit<SettingsItem, "id"> => {
    return {
      title: data.title,
      company: data.company,
      address: data.address,
      VAT: data.VAT,
      VAT_note: data.VAT_note,
      sales_email: data.sales_email,
      marketing_email: data.marketing_email,
      facebook: data.facebook,
      instagram: data.instagram,
      linkedin: data.linkedin,
      youtube: data.youtube,
      twitter: data.twitter,
      clutch: data.clutch,
      links: data.links,
    };
  };

  const changeData = (attr: string, value: any) => {
    setData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  return (
    <DefaultLayout>
      <EntityDetailLayout
        id={0}
        apiUrl={"/setting"}
        title={"Settings"}
        defaultValue={defaultSettingsItem}
        setData={setData}
        noHeader={true}
        getFormData={getFormData}
        navigateBack={false}
      >
        <Typography variant={"h5"} className={classes.heading}>
          General
        </Typography>
        <TextInput
          label={"Title"}
          id={"title"}
          onChange={changeData}
          value={data.title}
          validators={[{ required: true }, { maxLimit: 75 }]}
        />
        <TextInput
          label={"Company"}
          id={"company"}
          onChange={changeData}
          value={data.company}
          validators={[{ required: true }, { maxLimit: 75 }]}
        />
        <TextInput
          label={"Address"}
          id={"address"}
          onChange={changeData}
          value={data.address}
          validators={[{ required: true }, { maxLimit: 75 }]}
        />
        <TextInput
          label={"VAT"}
          id={"VAT"}
          onChange={changeData}
          value={data.VAT}
          validators={[{ required: true }, { maxLimit: 75 }]}
        />
        <TextInput
          label={"VAT Note"}
          id={"VAT_note"}
          onChange={changeData}
          value={data.VAT_note}
          validators={[{ required: true }, { maxLimit: 255 }]}
        />
        <TextInput
          label={"Sales Email"}
          id={"sales_email"}
          value={data.sales_email}
          onChange={changeData}
          validators={[
            { regex: /^$|[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ },
            { maxLimit: 254 },
          ]}
        />
        <TextInput
          label={"Marketing Email"}
          id={"marketing_email"}
          value={data.marketing_email}
          onChange={changeData}
          validators={[
            { regex: /^$|[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ },
            { maxLimit: 254 },
          ]}
        />
        <Typography variant={"h5"} className={classes.heading}>
          Social
        </Typography>
        <TextInput
          label={"Facebook"}
          id={"facebook"}
          value={data.facebook}
          onChange={changeData}
          validators={[{ required: true }, urlValidation]}
        />
        <TextInput
          label={"Instagram"}
          id={"instagram"}
          value={data.instagram}
          onChange={changeData}
          validators={[{ required: true }, urlValidation]}
        />
        <TextInput
          label={"LinkedIn"}
          id={"linkedin"}
          value={data.linkedin}
          onChange={changeData}
          validators={[{ required: true }, urlValidation]}
        />
        <TextInput
          label={"Youtube"}
          id={"youtube"}
          value={data.youtube}
          onChange={changeData}
          validators={[{ required: true }, urlValidation]}
        />
        <TextInput
          label={"Twitter"}
          id={"twitter"}
          value={data.twitter}
          onChange={changeData}
          validators={[{ required: true }, urlValidation]}
        />
        <TextInput
          label={"Clutch"}
          id={"clutch"}
          value={data.clutch}
          onChange={changeData}
          validators={[urlValidation]}
        />
        <Typography variant={"h5"} className={classes.heading}>
          Footer links
        </Typography>
        <SettingLinks
          links={data.links.map((link) => ({
            ...link,
            uuid: new Date().getTime(),
          }))}
          onChange={(links) => changeData("links", links)}
        />
      </EntityDetailLayout>
    </DefaultLayout>
  );
};

export default Settings;
