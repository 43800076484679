import { FC, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useFormContext } from "../store/FormProvider";
import { ValidatorTypes } from "../types/Validation";

interface IProps {
  value: string;
  onChange: (id: string, value: string) => void;
  id: string;
  label: string;
  validators?: ValidatorTypes[];
  multiline?: boolean;
  type?: string;
  disabled?: boolean;
}

const TextInput: FC<IProps> = ({
  id,
  label,
  onChange,
  value,
  disabled,
  multiline,
  type,
  validators,
}: IProps) => {
  const { errors, addValue, addValidationType } = useFormContext();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    addValidationType(id, validators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addValue(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (errors.has(id)) setError(errors.get(id)!);
    else setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div>
      <TextField
        multiline={multiline}
        type={type}
        fullWidth
        error={Boolean(error)}
        helperText={error}
        disabled={disabled}
        placeholder={label}
        label={label}
        value={value}
        onChange={(e) => onChange(id, e.target.value)}
      />
    </div>
  );
};

export default TextInput;
