import { OurWorkItem } from "./OurWorkItem"
import { Status } from "./global"

export interface OurWorkSliderItem {
    id: number,
    title: string,
    sub_title: string,
    content: string,
    slide_image: {id: number}[],
    our_work: OurWorkItem | null
    status: Status,
}

export const defaultOurWorkSliderItem : OurWorkSliderItem = {
    content: "",
    id: 0,
    slide_image: [],
    sub_title: "",
    title: "",
    our_work: null,
    status: Status.DRAFT
}
