import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Chip, TextField } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../utils/constants';
import { getAuth } from '../utils/Auth';
import { CategoryItem } from '../types/CategoryItem';
import { useFormContext } from '../store/FormProvider';
import { ValidatorTypes } from '../types/Validation';
import {handleResponseError} from "../utils/methods";

const useStyles = makeStyles({
	mediaPicker: {
		display: "flex",
		width: "100%",
		gap: 20
	},
});

interface IProps {
	value: { id: number }[],
	id: string,
	label: string,
	validators: ValidatorTypes[],
	onChange: (id: string, value: {id: number}[]) => void,
}

const CategoryPicker: FC<IProps> = ({id, label, value, validators, onChange}) => {

	const classes = useStyles()
	const [selectedCategories, setSelectedCategories] = useState<CategoryItem[]>([])
	const [data, setData] = useState<CategoryItem[]>([])

	const {errors, addValue, addValidationType} = useFormContext()
	const [error, setError] = useState<string>("")

	useEffect(() => {
		addValidationType(id, validators)
	}, [addValidationType, id, validators])

	useEffect(() => {
		if(selectedCategories.length !== 0) addValue(id, value)
	}, [value, selectedCategories, addValue, id])

	useEffect(() => {
		if(errors.has(id)) setError(errors.get(id)!)
		else setError("")
	}, [setError, errors, id])

	const getData = () => {
		axios.get(API_URL + "/category", getAuth()).then(res => {
			setData(res.data.data)
			if(value) {
				const items: CategoryItem[] = []
				value.forEach((item) => {
					res.data.data.forEach((category: CategoryItem) => {
						if(category.id === item.id) items.push(category)
					})
				})
				setSelectedCategories(items)
			}
		}).catch(err => {
			handleResponseError(err.reponse, "while loading categories")
			console.error(err)
		})
	}

	useEffect(() => {
		getData()
	// eslint-disable-next-line
	}, [])

	useEffect(() => {
		onChange(id, selectedCategories.map((item) => {
			return {id: item.id}
		}))
	// eslint-disable-next-line
	}, [selectedCategories])

	return (
		<div className={classes.mediaPicker}>
			<Autocomplete
				multiple
				fullWidth
				value={selectedCategories.map((item) => item.name)}
				renderTags={(value: readonly string[], getTagProps) => {
						const cat: CategoryItem[] = []
						data.forEach((item) => {
							value.forEach((val) => {
								if(item.name === val) cat.push(item)
							})
						})
						return selectedCategories.map((item: CategoryItem, index: number) => {
							return <Chip variant="outlined" label={item.name} {...getTagProps({ index })} />
						})
					}
				}
				onChange={(event, newValue) => {
					const cat: CategoryItem[] = []
					data.forEach((item) => {
						newValue.forEach((val) => {
							if(item.name === val) cat.push(item)
						})
					})
					setSelectedCategories(cat)
				}}
				renderInput={(params) => <TextField {...params} error={Boolean(error)} helperText={error} fullWidth label={label} />}
				options={data.map((item) => { return item.name })}
			/>
		</div>
	)
}

export default CategoryPicker
