import React, { useEffect, useState } from "react";
import DataTable from "../../components/dataTable/DataTable";
import DefaultLayout from "../../layouts/Default";
import axios from "axios";
import { getAuth } from "../../utils/Auth";
import { API_URL } from "../../utils/constants";
import { handleResponseError } from "../../utils/methods";

type DocumentContent = {
  id: number;
  languageId: number;
  content: string;
  language: {
    id: number;
    isoCode: string;
    name: string;
  }  
};

type Document = {
  id: number;
  type: string;
  documentContents: DocumentContent[];
};


const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Name", attr: "type" },
  { name: "Languages", attr: "languages" },
  { name: "Missing Translations", attr: "missing" },
];
export default function Documents() {

  const [types, setTypes] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/documents/types`, getAuth())
      .then((res) => {
        setTypes([...res.data]);
      })
      .catch((err) => {
        handleResponseError(err.response, "while loading types");
      });
  }, []);

  return (
    <DefaultLayout>
      <DataTable
        title={"Documents"}
        apiUrl={"/documents"}
        url={"/documents"}
        tableHeader={tableHeader}
        hasAddButton={types.length > 0}
        dataMapper={(data: Document[]) => {
          return data.map((document) =>({
            id: document.id,
            type: document.type,
            languages: document.documentContents.map((dc) => dc.language.name).sort().join(", "),
            missing: document.documentContents.filter(dc => !dc.content).map((dc) => dc.language.name).sort().join(", "),
          }));
        }}
      />
    </DefaultLayout>
  );
}
