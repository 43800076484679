import AuthLayout from '../layouts/Auth';
import React, {FormEvent, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment,
  TextField,
  Theme, Typography
} from '@mui/material';

import {makeStyles} from '@mui/styles';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getAuth, login} from '../utils/Auth';
import axios from 'axios';
import {API_URL} from '../utils/constants';
import {handleResponseError} from '../utils/methods';


const useStyles = makeStyles<Theme, {}>({
	link: {
	  textDecoration: "underline",
	  color: 'blue',
	  cursor: 'pointer',
	  '&:hover': {
		color: 'lightblue'
	  }
	},
  });

export default function Login() {

  const [loginEmail, setLoginEmail] = useState<String>('');
  const [recoverPasswordEmail, setRecoverPasswordEmail] = useState('');
  const [password, setPassword] = useState<String>('');

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login(loginEmail.trim(), password.trim());
  };

  const sendForgotPassEmail = () => {
    setOpenDialog(false);
    axios.post(API_URL + '/auth/forgotten-password', {email: recoverPasswordEmail}, getAuth()).then(() => {
      toast.success('Email sent successfully');
    }).catch(err => {
      console.error(err);
      handleResponseError(err.response, 'while sending email');
    });
  };

	return (
		<AuthLayout title='ADMIN LOGIN'>
			<form onSubmit={handleSubmit}>
				<div style={{width: '100%', marginBottom: 30}}>
					<TextField label='Name' value={loginEmail} onChange={(event) => setLoginEmail(event.target.value)} variant='standard' fullWidth style={{marginBottom: 20}}/>
					<TextField
					fullWidth
					type={showPassword ? 'text' : 'password'}
					value={password}
					onChange={handleChange}
					label='Password'
					variant='standard'
					InputProps={{
						endAdornment: (
						<InputAdornment position='end'>
							<IconButton
							aria-label='toggle password visibility'
							onClick={() => setShowPassword(prev => !prev)}
							onMouseDown={(event) => event.preventDefault()}
							edge='end'
							>
							{showPassword ? <VisibilityOff/> : <Visibility/>}
							</IconButton>
						</InputAdornment>
						)
					}}
					/>
				</div>
				<Button variant={'contained'} type={'submit'} fullWidth>
					LOG IN
				</Button>
			</form>
			<Typography variant={"body1"} className={classes.link} onClick={() => setOpenDialog(true)}>Forgotten password</Typography>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
			<DialogTitle>Enter your email</DialogTitle>
			<DialogContent>
				<TextField
				autoFocus
				margin='dense'
				value={recoverPasswordEmail}
				onChange={(event) => setRecoverPasswordEmail(event.target.value)}
				id='name'
				label='Email Address'
				type='email'
				fullWidth
				variant='standard'
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpenDialog(false)}>Cancel</Button>
				<Button onClick={sendForgotPassEmail}>Send</Button>
			</DialogActions>
			</Dialog>
		</AuthLayout>
	)
}