import { OurWorkSliderItem } from "./OurWorkSliderItem"
import { TestimonialsItem } from "./TestimonialsItem"
import { Status } from "./global"

export interface OurWorkItem {
	id: number
	title: string,
	short_description: string,
	category: string,
	featured_image: {id: number}[],
	header_media: {id: number}[],
	header_media_image: {id: number}[],
	slug: string,
	testimonial_data: TestimonialsItem | null,
	company_logo: {id: number}[],
	company_description: string,
	the_need: string,
	our_solution: string,
	key_achievements: string,
	facts: string,
	gallery: {id: number}[],
	our_work_slider_data: OurWorkSliderItem | null
	social_facebook_image: {id: number}[],
	order: number | null,
	status: Status,
}

export const defaultOurWorkItem : OurWorkItem = {
	category: '',
	company_logo: [],
	company_description: '',
	facts: '',
	featured_image: [],
	gallery: [],
	header_media: [],
	header_media_image: [],
	id: 0,
	key_achievements: '',
	our_solution: '',
	short_description: '',
	slug: '',
	the_need: '',
	title: '',
	testimonial_data: null,
	our_work_slider_data: null,
	social_facebook_image: [],
	order: null,
	status: Status.DRAFT
}
