import { OurWorkItem } from "./OurWorkItem"
import { Status } from "./global";

export interface TestimonialsItem {
	id: number,
	title: string,
	sub_title: string,
	company_logo: {id: number}[],
	video: {id: number}[],
	video_placeholder_image: {id: number}[],
	person: string;
	our_work: OurWorkItem | null
	status: Status,
}

export const defaultTestimonialsItem : TestimonialsItem = {
	id: -1,
	title: '',
	sub_title: '',
	company_logo: [],
	video: [],
	video_placeholder_image: [],
	our_work: null,
	person: '',
	status: Status.DRAFT
}
