import DefaultLayout from "../../layouts/Default";
import DataTable from "../../components/dataTable/DataTable";

const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Title", attr: "title" },
  { name: "Email", attr: "email" },
  { name: "Address", attr: "address" },
  { name: "City", attr: "city" },
  { name: "State", attr: "state" },
];

export default function Contacts() {

  return (
    <DefaultLayout>
      <DataTable
        title={"Contacts"}
        apiUrl={"/contact"}
        url={"/contacts"}
        tableHeader={tableHeader}
        allowDnDSorting
      />
    </DefaultLayout>
  );
}
