/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Theme,
  Typography,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from "@mui/material";
import { Save, Delete, Edit, Close } from "@mui/icons-material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { makeStyles } from "@mui/styles";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getAuth } from "../utils/Auth";
import { API_URL } from "../utils/constants";
import { IFormContext, useFormContext } from "../store/FormProvider";
import ConfirmDialog from "../components/ConfirmDialog";
import { toast } from "react-toastify";
import { handleResponseError } from "../utils/methods";
import DataTable from "../components/dataTable/DataTable";
import {
  ShowUsedInForEntity,
  getUsedInCfg,
} from "../utils/entity-used-in/index";
import useLayoutElementScrollTrigger from "../hooks/use-listen-layout-scroll";
import { ReactElement } from "react";

type StyleProps = {};

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
  },
  heading: {
    alignItems: "center",
    marginBottom: 20,
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  content: {
    marginTop: 20,
  },
  form: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    gap: 10,
  },
  "used-in": {
    margin: "0px -30px",
  },
});

interface IProps {
  navigateBack?: boolean;
  apiUrl: string;
  title: string;
  id: number;
  defaultValue: any;
  setData: (value: any) => void;
  getFormData: () => any;
  children: ReactNode;
  noHeader?: boolean;
  noDelete?: boolean;
  customButtons?: {
    label: string;
    onClick: (
      ev: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>,
      formContext: IFormContext
    ) => void;
    floatButtonIcon: ReactElement;
  }[];
  showUsedInForEntity?: ShowUsedInForEntity;
  onSave?: (values?: any) => void;
  onOpen?: () => void;
  onSaveStart?: () => void;
  refreshUsedInOnSave?: boolean;
}

const EntityDetailLayout: FC<IProps> = ({
  title,
  id,
  children,
  apiUrl,
  noHeader,
  noDelete,
  defaultValue,
  setData,
  getFormData,
  customButtons,
  showUsedInForEntity,
  onSave,
  onOpen,
  onSaveStart,
  navigateBack = true,
  refreshUsedInOnSave = false,
}: IProps) => {
  let { entityId } = useParams();
  
  const navigate = useNavigate();
  const classes = useStyles();
  const formContext = useFormContext();

  const [floatBtnOpen, setFloatBtnOpen] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [filtersCount, setFilterCount] = useState(0);
  const [edit] = useState<boolean>(!!entityId && entityId !== "new");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);

  const getData = (entityId?: string) => {
    axios
      .get(`${API_URL}${apiUrl}${entityId ? "/" + entityId : ""}`, getAuth())
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while loading data");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (edit && entityId) {
      getData(entityId);
    } else if (noHeader) {
      getData();
    } else {
      setData(defaultValue);
      setLoading(false);
    }

    onOpen?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controlsRef = useRef<HTMLDivElement>(null);
  const containerScrollerRef = useRef<HTMLDivElement>(null);

  useLayoutElementScrollTrigger(
    controlsRef,
    {
      rootMargin: "40px",
      threshold: 1,
    },
    (status) => {
      if (status === "visible") {
        setFloatBtnOpen(true);
      } else {
        setFloatBtnOpen(false);
      }
    }
  );

  const handleSave = (entityId: number | undefined) => {
    if (!formContext.validateForm()) return;
    setSaving(true);

    onSaveStart?.();
    const progressToastId = toast.info("Saving...", {
      autoClose: false,
      isLoading: true,
      progress: 0,
    });

    if (edit && !noHeader) {
      axios
        .put(`${API_URL}${apiUrl}${id ? "/" + entityId : ""}`, getFormData(), {
          ...getAuth(),
        })
        .then(() => {
          navigateBack && navigate(-1);
          toast.success("Record edited successfully!");
          if (refreshUsedInOnSave && showUsedInForEntity) {
            setFilterCount((prev) => prev + 1);
          }
          onSave?.(getFormData());
        })
        .catch((err) => {
          console.error(err);
          handleResponseError(err.response, "while editing record");
        })
        .finally(() => {
          toast.dismiss(progressToastId);
          setSaving(false);
        });
    } else {
      const newUrl = title === "Users" ? "/user/create-user" : apiUrl;

      axios
        .post(API_URL + newUrl, getFormData(), {
          ...getAuth(),
        })
        .then(() => {
          navigateBack && navigate(-1);
          toast.success("Record created successfully!");
          onSave?.(getFormData());
        })
        .catch((err) => {
          console.error(err);
          handleResponseError(err.response, "while creating record");
        })
        .finally(() => {
          toast.dismiss(progressToastId);
          setSaving(false);
        });
    }
  };

  const handleDelete = (entityId: number | undefined) => {
    axios
      .delete(`${API_URL}${apiUrl}${entityId ? "/" + entityId : ""}`, getAuth())
      .then(() => {
        navigate(-1);
        toast.success("Record was deleted successfully!");
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while deleting record");
      });
  };

  const handleDialogClose = (confirmed: boolean) => {
    if (confirmed) {
      navigateBack && navigate(-1);
    }
    setOpenDialog(false);
  };

  const handleGoBack = () => {
    if (formContext.hasChanged) {
      setOpenDialog(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className={`${classes.root} max-width-container`}
      ref={containerScrollerRef}
    >
      <div className={classes.heading}>
        <Typography
          variant={"h4"}
          style={{ fontWeight: 600, marginRight: "20px" }}
        >
          {title}
        </Typography>
        {!noHeader && (
          <Typography
            variant={"h6"}
            style={{ fontWeight: 600, marginRight: "20px" }}
          >
            {edit ? "Edit record" : "Create new record"}
          </Typography>
        )}
      </div>
      <div className={classes.controls} ref={controlsRef}>
        <ArrowBackIcon onClick={handleGoBack} style={{
          pointerEvents: saving ? "none" : "auto",
          opacity: saving ? 0.5 : 1,
        }} />
        <div>
          {customButtons?.map((button) => (
            <Button
              key={button.label}
              variant={"contained"}
              style={{ marginRight: 20 }}
              onClick={(ev) => button.onClick(ev, formContext)}
            >
              {button.label}
            </Button>
          ))}
          {!noHeader && !noDelete && edit ? (
            <Button
              variant={"contained"}
              color={"error"}
              style={{ marginRight: 20 }}
              endIcon={<Delete />}
              onClick={() => setOpenDeleteDialog(true)}
            >
              Delete
            </Button>
          ) : null}
          <Button
            variant={"contained"}
            onClick={() => handleSave(id)}
            disabled={saving || formContext.errors.size > 0}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
      <div className={classes.form}>
        {loading ? <CircularProgress /> : children}
      </div>
      {showUsedInForEntity && !["new", "0"].includes(entityId || "new") && (
        <>
          <h1>Being used in: </h1>
          {getUsedInCfg(showUsedInForEntity, entityId as string).map(
            (datatableProps) => {
              return (
                <div
                  className={classes["used-in"]}
                  key={`used-in-table-${datatableProps.apiUrl}`}
                >
                  <DataTable
                    key={`data-table-filter-${datatableProps.apiUrl}-${filtersCount}`}
                    {...datatableProps}
                    hasAddButton={false}
                    hideWhenNoData={true}
                  />
                </div>
              );
            }
          )}
        </>
      )}
      <ConfirmDialog
        open={openDialog}
        title={"Discard changes ?"}
        content={
          "There are some changes which are not saved. Are you surFe you want to discard them?"
        }
        handleClose={handleDialogClose}
      />
      <ConfirmDialog
        open={openDeleteDialog}
        title={"Confirm delete"}
        content={"Are you sure you want to delete this record ?"}
        handleClose={(confirmed) => {
          if (confirmed) handleDelete(id);
          setOpenDeleteDialog(false);
        }}
      />
      <SpeedDial
        hidden={floatBtnOpen}
        ariaLabel="Entity actions"
        sx={{ position: "absolute", bottom: 48, right: 48 }}
        icon={<SpeedDialIcon icon={<Edit />} openIcon={<Close />} />}
      >
        <SpeedDialAction
          key="SAVE"
          icon={<Save />}
          tooltipTitle={"SAVE"}
          onClick={() => handleSave(id)}
        />
        {edit && (
          <SpeedDialAction
            key="DELETE"
            icon={<Delete color="error" />}
            tooltipTitle={"DELETE"}
            onClick={() => handleDelete(id)}
          />
        )}
        {customButtons?.map((button) => (
          <SpeedDialAction
            key={button.label}
            icon={button.floatButtonIcon}
            tooltipTitle={button.label}
            onClick={(ev) => button.onClick(ev, formContext)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default EntityDetailLayout;
