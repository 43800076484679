import DefaultLayout from "../../layouts/Default";
import DataTable from "../../components/dataTable/DataTable";
import { OUR_WORK_LOCAL_STORAGE_KEY } from "./OurWorkConst";
import { clearStorage } from "../../utils/persistance";

const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Title", attr: "title" },
  { name: "Slug", attr: "slug" },
  { name: "Category", attr: "category" },
  { name: "Featured Image", attr: "featured_image", type: "image" },
];

const MAIN_TITLE = "Our Work";
const URLS = "/our-work";

export enum OurWorkCategory {
  AGILE = "Agile Teams",
  DEV = "Product Development",
  AI = "AI Integrations"
}


export default function OurWork() {

  return (
    <DefaultLayout>
      <DataTable
        title={MAIN_TITLE}
        apiUrl={URLS}
        url={URLS}
        tableHeader={tableHeader}
        allowDnDSorting
        onAddNew={() => clearStorage(OUR_WORK_LOCAL_STORAGE_KEY)}
      />
    </DefaultLayout>
  );
}
