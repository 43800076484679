export type ValidatorTypes = {required?: boolean, minLimit?: number, maxLimit?: number, minLength?: number, maxLength?: number, regex?: RegExp, error?: string}
export type ValidationFunction = () =>{isValid: boolean, error: string}

export const validateValue = ( value: string, validators: ValidatorTypes[]) : {isValid: boolean, error: string} => {
	let response: {isValid: boolean, error: string} = {isValid: true, error: ""}
	validators.forEach((validator) => {
		if(validator.required) {
			if(value && Array.isArray(value) && value.length === 0) {
				response = {isValid: false, error: validator.error ? validator.error : "This field is required"}
				return
			}

			if(value && Array.isArray(value) && value.length === 0) {
				response = {isValid: false, error: validator.error ? validator.error : "This field is required"}
				return
			}
			// isBlank regex
			if(!value || /^\s*$/.test(value)) {
				response = {isValid: false, error: validator.error ? validator.error : "This field is required"}
			}
		}
		if(validator.minLimit) {
			if(value && value.length < validator.minLimit) {
				response = {isValid: false, error: validator.error ? validator.error : "Minimal length of value is " + validator.minLimit}
			}
		}
		if(validator.maxLimit) {
			if(value && value.length > validator.maxLimit) {
				response = {isValid: false, error: validator.error ? validator.error : "Maximal length of value is " + + validator.maxLimit}
			}
		}
		if(validator.regex && value && !Array.isArray(value)) {
			if(!validator.regex.test(value)) {
				response = {isValid: false, error: validator.error ? validator.error : "Value is not in required format"}
			}
		}
	})
	return response
}
