export interface PartnerItem {
	id: number,
	featured_image: {id: number}[],
	title: string,
	link: string,
}

export const defaultPartnerItem : PartnerItem = {
	id: 0,
	link: '',
	title: '',
	featured_image: [],
}