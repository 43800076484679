import { FC } from "react";
import {
    Theme, Typography
  } from '@mui/material';
  import {makeStyles} from '@mui/styles';
  import {ReactComponent as Logo} from '../assets/hotovo_logo.svg';

const useStyles = makeStyles<Theme, {}>({
    root: {
      backgroundColor: '#eceff1',
      width: '100vw',
      height: '100vh',
  
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
  
      backgroundColor: 'white',
      padding: 20,
      borderRadius: 10,
      minWidth: '500px',
      minHeight: '400px'
    },
  });
  
interface IProps {
    children: React.ReactNode,
    title: string
}

const AuthLayout: FC<IProps> = ({
    children,
    title
}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <Logo style={{maxWidth: '300px', marginTop: 30}}/>
                <Typography variant={'h4'} style={{fontWeight: 600}}>{title}</Typography>
                {
                    children
                }
            </div>
        </div>
    )
}

export default AuthLayout;