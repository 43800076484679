import { FC, useEffect, useState } from 'react';
import { useFormContext } from '../store/FormProvider';
import { ValidatorTypes } from '../types/Validation';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

export interface IProps {
	value: string,
	id: string,
	label: string,
	validators?: ValidatorTypes[],
	className?: string | undefined;
	onChange: (id: string, value: string) => void,
}

const DatePicker: FC<IProps> = ({id, label, value, validators, className, onChange}) => {

	const [selectedDate, setSelectedDate] = useState<string>((value))

	const {errors, addValue, addValidationType} = useFormContext()
	const [error, setError] = useState<string>("")

	useEffect(() => {
		addValidationType(id, validators)
	}, [addValidationType, id, validators])

	useEffect(() => {
		if(selectedDate.length !== 0) addValue(id, value)
	}, [value, selectedDate, id, addValue])

	useEffect(() => {
		if(errors.has(id)) setError(errors.get(id)!)
		else setError("")
	}, [errors, setError, id])


	useEffect(() => {
		onChange(id, selectedDate)
	// eslint-disable-next-line
	}, [selectedDate])

	return (
		<div>
			<DateTimePicker
				label={label}
				value={moment(value)}
				onChange={(value) => {
					if(value){
						setSelectedDate(value.toISOString());
					}
				}}
				slotProps={{
					textField: {
						fullWidth: true,
						 error: Boolean(error),
						helperText: error
					}
				}}
				className={className}
			/>
		</div>
	)
}

export default DatePicker
