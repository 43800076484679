export interface BranchManagerItem {
	id: number
	name: string,
	function: string,
	description: string,
	language: string;
	featured_image: { id: number }[],
	slug: string,
}

export const defaultBranchManagerItem: BranchManagerItem = {
	id: 0,
	name: "",
	function: "",
	description: "",
	language: "",
	featured_image: [],
	slug: "",
}
