export interface LanguageItem {
    id: number,
    name: string,
    isoCode: string,
    isRtl: boolean
}

export const defaultData: LanguageItem = {
    id: -1,
    name: '',
    isoCode: '',
    isRtl: false
}
