import React, {useState} from 'react'
import DefaultLayout from "../layouts/Default";
import EntityDetailLayout from "../layouts/EntityDetail";
import {defaultData} from "../types/CategoryItem";
import TextInput from "../components/TextInput";
import DataTable from "../components/dataTable/DataTable";
import {useParams} from "react-router-dom";
import {defaultGalleryItem, GalleryItem} from "../types/GalleryItem";
import MediaPicker from "../components/MediaPicker";

const tableHeader = [
    {name: "ID", attr: "id"},
    {name: "Title", attr: "title"},
]

const Galleries  = () => {

    const {entityId} = useParams()
    const [detailData, setDetailData] = useState<GalleryItem>(defaultGalleryItem)

    const getFormData = () => {
        return {
            title: detailData.title,
            images: detailData.images,
        }
    }

    const changeData = (attr: string, value: any) => {
        setDetailData(prev => {
            return {...prev, [attr]: value}
        })
    }

    if(entityId) {
        return (
            <DefaultLayout>
                <EntityDetailLayout id={detailData.id} title={"Galleries"} apiUrl={"/gallery"} defaultValue={defaultData} setData={setDetailData} getFormData={getFormData} showUsedInForEntity="gallery">
                    {/*<TextInput value={detailData.key} onChange={changeData} id={"key"} label={"Key"} validators={[{required: true}, {maxLimit: 255}]}/>*/}
                    <TextInput value={detailData.title} onChange={changeData} id={"title"} label={"Title"} validators={[{required: true}, {maxLimit: 255}]}/>
                    <MediaPicker multiple={true} id={"images"} label={"Images"} value={detailData.images} validators={[{required: true}]} onChange={changeData} />
                </EntityDetailLayout>
            </DefaultLayout>
        )
    }

    return (
        <DefaultLayout>
            <DataTable title={"Galleries"} apiUrl={"/gallery"} url={"/galleries"} tableHeader={tableHeader} />
        </DefaultLayout>
    )
}

export default Galleries
