import FormProvider, { useFormContext } from "../../store/FormProvider";
import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ValidatorTypes } from "../../types/Validation";
import "quill/dist/quill.snow.css";
import { GalleryItem } from "../../types/GalleryItem";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { getAuth } from "../../utils/Auth";
import TextInput from "../TextInput";
import ReactQuill, { Quill } from "react-quill";
import placeholder from "../../assets/placeholder-image.png";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import { handleResponseError } from "../../utils/methods";

import FormModal from "./FormModal";
import MediaForm, {
  SelectedMedia,
  IdMediaObject,
  MediaFormType,
} from "./MediaForm";
import { useCommonClasses } from "./common-classes";

// Apply extensions.
import "./extends";

import { MEDIA_ID_ATTRIBUTE, MEDIA_TYPE_ATTRIBUTE } from "./extends";

const Delta = Quill.import("delta");

const useStyles = makeStyles({
  quill: {
    position: "relative",
    "& .ql-toolbar": {
      top: 0,
      position: "absolute",
      background: "white",
      zIndex: 1,
      width: "100%",
    },
    "& .ql-container": {
      paddingTop: "42px",
      maxHeight: "600px",
      overflowY: "auto",
    },
    "& img": {
      backgroundColor: "#ebebeb",
    },
  },
  quillError: {
    border: "1px solid red",
  },
  header: {
    display: "flex",
  },
  heading: {
    margin: "10px 0 !important",
  },
  footer: {
    display: "flex",
    marginTop: 10,
    gap: 10,
  },
  galleryItem: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
  },

  galleryContent: {},
});

interface IProps {
  id: string;
  label: string;
  value: any;
  gallery_id?: string;
  galleries?: { id: number }[];
  validators: ValidatorTypes[];
  onChange: (id: string, value: string | { id: number }[]) => void;
  canAddCode?: boolean;
  canAddIframe?: boolean;
  canAddVideo?: boolean;
  canAddGallery?: boolean;
  canAddImage?: boolean;
}

const QuillEditor = ({
  id,
  label,
  value,
  gallery_id,
  galleries,
  validators,
  onChange,
  canAddCode = true,
  canAddIframe = true,
  canAddVideo = true,
  canAddGallery = true,
  canAddImage = true,
}: IProps) => {
  const classes = useStyles();

  const commonClasses = useCommonClasses();

  const navigate = useNavigate();
  const quillRef = useRef<ReactQuill | null>(null);
  const galleryModalRef = useRef(null);

  const { errors, addValue, addValidationType } = useFormContext();

  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const [openCode, setOpenCode] = useState(false);

  const [iframeData, setIframeData] = useState("");
  const [openIframe, setOpenIframe] = useState(false);

  const [openVideo, setOpenVideo] = useState(false);

  const [language, setLanguage] = useState("");
  const [openGalleries, setOpenGalleries] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [selectedGalleries, setSelectedGalleries] = useState<{ id: number }[]>(
    []
  );
  const [selectedImages, setSelectedImages] = useState<SelectedMedia[]>([]);

  const [selectedVideos, setSelectedVideos] = useState<SelectedMedia[]>([]);

  const [selectedVideo, setSelectedVideo] = useState<IdMediaObject | undefined>(
    undefined
  );

  const [selectedImage, setSelectedImage] = useState<IdMediaObject | undefined>(
    undefined
  );
  const [selectedGallery, setSelectedGallery] = useState<
    GalleryItem | undefined
  >(undefined);

  const [allGalleries, setAllGalleries] = useState<GalleryItem[]>([]);

  const [page, setPage] = useState(1);
  const [isSelected, setIsSelected] = useState(false);

  const [lastPosition, setLastPosition] = useState<number | undefined>(
    undefined
  );
  const [hover, setHover] = useState<boolean>(false);

  useOnClickOutside(galleryModalRef, () => {
    if (!hover) setSelectedGallery(undefined);
  });

  const modules = {
    imageResize: {
      handleStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [false, 2, 3] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["link", "video"],
      ["clean"],
    ],
  };

  const getGalleries = () => {
    axios
      .get(`${API_URL}/gallery?${page ? "" : "page=" + page}`, getAuth())
      .then((res) => {
        setAllGalleries((prev) => [...prev, ...res.data.data]);
        if (galleries) setSelectedGalleries(galleries);
        setPage((prev) => ++prev);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while loading galleries");
      });
  };

  useEffect(() => {
    addValidationType(id, validators);
    loadMediaOnEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addValue(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (errors.has(id)) setError(errors.get(id)!);
    else setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  /*
    Effect for adding `ShiftEnterBlot` properly on editor,
    see http://billauer.co.il/blog/2021/12/quill-br-shift-enter for getting details about implementation.
  */
  useEffect(() => {
    if (quillRef.current && quillRef.current.editor) {
      const { editor } = quillRef.current;

      const bindings = (quillRef.current.editor.keyboard as any)
        .bindings as any[][];

      bindings[13].unshift({
        key: 13,
        shiftKey: true,
        handler(range: any) {
          editor.updateContents(
            new Delta()
              .retain(range.index)
              .delete(range.length)
              .insert({ ShiftEnter: true }),
            "user"
          );

          if (!editor.getLeaf(range.index + 1)[0].next) {
            editor.updateContents(
              new Delta()
                .retain(range.index + 1)
                .delete(0)
                .insert({ ShiftEnter: true }),
              "user"
            );
          }

          editor.setSelection(range.index + 1, (Quill as any).sources.SILENT);

          return false;
        },
      });
    }
  }, []);

  const handleSelection = (range: any) => {
    if (range) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  const isInGalleries = (id: number) => {
    return selectedGalleries.find((gallery) => gallery.id === id);
  };

  const handleAddGallery = (id?: number) => {
    if (quillRef.current && onChange && gallery_id) {
      if (typeof lastPosition !== "undefined" && id) {
        let max = 0;
        selectedGalleries.forEach((gallery) => {
          if (gallery.id > max) max = gallery.id;
        });
        const gallery: GalleryItem = {
          id: id ? id : max + 1,
          images: [],
          key: id ? "Gallery-" + id : "Gallery-" + (max + 1),
          title: id ? "Gallery-" + id : "Gallery-" + (max + 1),
        };
        setSelectedGalleries((prev) => {
          const galls = [...prev, gallery];
          onChange(
            gallery_id,
            galls.map((gallery) => {
              return { id: gallery.id };
            })
          );
          return galls;
        });
        quillRef.current
          ?.getEditor()
          .insertText(lastPosition, `\n[gallery id=${gallery.id}]\n`, {
            color: "#f00",
          });
      }
    }
  };

  const loadMediaOnEditor = async () => {
    if (quillRef.current) {
      const contents = quillRef.current.getEditor().getContents();

      const deltaOperations = contents.ops
        ?.map((d: any) => {
          const attributes = d.attributes;

          const mediaId = attributes?.[MEDIA_ID_ATTRIBUTE];

          if ((d.insert?.image || d.insert?.video) && mediaId) {
            return d;
          } else if (
            typeof d.insert === "string" &&
            d.insert.startsWith("[video")
          ) {
            const id = d.insert.match(/id="(?<id>[^"]+)"/)?.groups?.id;
            const playbackId = d.insert.match(/playback-id="(?<id>[^"]+)"/)
              ?.groups?.id;

            return {
              attributes: {
                "data-media-id": id,
                "data-media-type": "video",
              },
              insert: {
                video: playbackId,
              },
            };
          }

          return null;
        })
        .filter((v) => v !== null);

      if (!deltaOperations) return;

      let list: { id: number; url: string; type: MediaFormType }[] = [];

      const mediaCalls: Promise<{
        url: string;
        type: MediaFormType;
        id: number;
      }>[] = [];

      for (const deltaOperation of deltaOperations) {
        const id = deltaOperation.attributes?.[MEDIA_ID_ATTRIBUTE];
        const type = deltaOperation.attributes?.[MEDIA_TYPE_ATTRIBUTE];

        mediaCalls.push(
          new Promise((resolve) => {
            axios.get(`${API_URL}/media/${id}`, getAuth()).then((response) => {
              resolve({
                id: Number(id),
                url: response.data.file,
                type: type as MediaFormType,
              });
            });
          })
        );
      }

      list = await Promise.all(mediaCalls);

      const images = list.filter((current) => current.type === "image");
      const videos = list.filter((current) => current.type === "video");

      setSelectedImages(images);

      setSelectedVideos(videos);
    }
  };

  const handleRemoveGallery = (id: number) => {
    if (quillRef.current && onChange && gallery_id) {
      const contents = quillRef.current.getEditor().getContents();
      contents.ops = contents.ops?.filter((d: any) => {
        return !(
          typeof d.insert === "string" &&
          d.insert.includes("[gallery id=" + id + "]")
        );
      });
      setSelectedGalleries((galleries) => {
        const galls = galleries.filter((gallery) => gallery.id !== id);
        if (galls) {
          onChange(
            gallery_id,
            galls.map((gallery) => {
              return { id: gallery.id };
            })
          );
          return galls;
        }
        return [];
      });
      quillRef.current?.getEditor().setContents(contents);
    }
  };

  const handleAddMedia = async (type: MediaFormType, id?: number) => {
    if (quillRef.current) {
      if (typeof lastPosition !== "undefined" && id) {
        const data = await axios.get(`${API_URL}/media/${id}`, getAuth());

        if (type === "image") {
          setSelectedImages((prev) => {
            return [...prev, { id: id, url: data.data.file, type }];
          });
        } else {
          setSelectedVideos((prev) => {
            return [...prev, { id: id, url: data.data.file, type }];
          });
        }

        if (type === "image") {
          const delta = new Delta().retain(lastPosition).insert(
            {
              image: {
                src: `${API_URL}/${data.data.file}`,
                alt: data.data.alternative_text ?? data.data.title,
                label: data.data.label,
              },
            },
            { [MEDIA_ID_ATTRIBUTE]: id, [MEDIA_TYPE_ATTRIBUTE]: type }
          );

          quillRef.current?.getEditor().updateContents(delta as any);
        } else if (type === "video") {
          // old behavior for old videos to be backward compatible
          if (data.data.file.startsWith("uploads")) {
            const delta = new Delta()
              .retain(lastPosition)
              .insert(
                { video: `${API_URL}/${data.data.file}` },
                { [MEDIA_ID_ATTRIBUTE]: id, [MEDIA_TYPE_ATTRIBUTE]: type }
              );

            quillRef.current?.getEditor().updateContents(delta as any);
          } else {
            console.log(data.data);
            quillRef.current
              ?.getEditor()
              .insertText(
                lastPosition,
                `\n[video title="${data.data.title}" id="${data.data.id}" playback-id="${data.data.file}"]\n`,
                {
                  color: "#f00",
                }
              );
          }
        }
      }
    }
  };

  function mapNewMedia(id: number) {
    return (prev: SelectedMedia[]) => {
      const media = prev.filter((currentMedia) => currentMedia.id !== id);
      if (media) {
        return media;
      }
      return [];
    };
  }

  const handleRemoveMedia = (id: number, type: MediaFormType) => {
    if (quillRef.current) {
      const contents = quillRef.current.getEditor().getContents();
      contents.ops = contents.ops?.filter((d) => {
        if (typeof d.insert === "string" && d.insert.startsWith("[video")) {
          const videoId = d.insert.match(/id="(?<id>[^"]+)"/)?.groups?.id;

          console.log(d, id, type, videoId);

          if (id === Number(videoId)) {
            return false;
          }
        } else {
          const mediaId: string = d.attributes?.[MEDIA_ID_ATTRIBUTE];

          if (id === Number(mediaId)) {
            return false;
          }
        }

        return true;
      });

      if (type === "image") {
        setSelectedImages(mapNewMedia(id));
      } else {
        setSelectedVideos(mapNewMedia(id));
      }

      quillRef.current?.getEditor().setContents(contents);
    }
  };

  const handleOpenGalleries = () => {
    setAllGalleries([]);
    setPage(0);
    getGalleries();
    setOpenGalleries(true);
    setLastPosition(quillRef.current?.getEditor().getSelection()?.index);
  };

  const handleAddCode = () => {
    if (quillRef.current) {
      if (typeof lastPosition !== "undefined") {
        quillRef.current
          ?.getEditor()
          .insertText(
            lastPosition,
            `[code language=${language}]\n ${code}\n[code end]\n`,
            {
              "code-block": true,
            }
          );
      }
    }
    setOpenCode(false);
  };

  const handleAddIframe = () => {
    if (quillRef.current) {
      if (typeof lastPosition !== "undefined") {
        quillRef.current
          ?.getEditor()
          .insertText(
            lastPosition,
            `[iframe ${iframeData.replace(/<iframe|><\/iframe>/gi, "")}]\n`,
            {
              "code-block": true,
            }
          );
      }
    }
    setOpenIframe(false);
    setIframeData("");
  };

  function setLastPositionOnFooterBtnClick() {
    setLastPosition(quillRef.current?.getEditor().getSelection()?.index);
  }

  return (
    <div>
      <div className={classes.header}>
        <Typography variant={"h6"} className={classes.heading}>
          {label}
        </Typography>
      </div>
      <ReactQuill
        ref={(value) => (quillRef.current = value)}
        className={`${error ? classes.quillError : classes.quill}`}
        modules={modules}
        defaultValue={value}
        onChangeSelection={handleSelection}
        onChange={(value) => onChange(id, value)}
      />
      <div className={classes.footer}>
        {canAddImage && <Button
          variant={"contained"}
          onClick={() => {
            setOpenImages(true);
            setLastPositionOnFooterBtnClick();
          }}
          disabled={!isSelected}
        >
          ADD IMAGE
        </Button>}
        {canAddGallery && gallery_id ? (
          <Button
            variant={"contained"}
            onClick={handleOpenGalleries}
            disabled={!isSelected}
          >
            ADD GALLERY
          </Button>
        ) : null}

        {canAddVideo && (
          <Button
            variant={"contained"}
            onClick={() => {
              setOpenVideo(true);
              setLastPositionOnFooterBtnClick();
            }}
            disabled={!isSelected}
          >
            ADD VIDEO
          </Button>
        )}

        {canAddCode && (
          <Button
            variant={"contained"}
            onClick={() => {
              setOpenCode(true);
              setLastPositionOnFooterBtnClick();
            }}
            disabled={!isSelected}
          >
            ADD CODE
          </Button>
        )}
        {canAddIframe && (
          <Button
            variant={"contained"}
            onClick={() => {
              setOpenIframe(true);
              setLastPositionOnFooterBtnClick();
            }}
            disabled={!isSelected}
          >
            ADD IFRAME
          </Button>
        )}
      </div>

      <FormModal
        actionButtonText="INSERT GALLERY"
        handleSubmit={() => {
          handleAddGallery(selectedGallery?.id);
        }}
        title="Galleries"
        open={openGalleries}
        onClose={() => setOpenGalleries(false)}
        extraButtons={[
          {
            text: "ADD NEW GALLERY",
            onClick() {
              navigate("/galleries/new");
            },
          },
        ]}
        overrideActionButtonProps={{
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false),
        }}
      >
        <div
          ref={galleryModalRef}
          style={{ display: "flex", flexWrap: "wrap", gap: 20 }}
        >
          {allGalleries.map((gallery) => {
            return (
              <div
                key={gallery.id}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setSelectedGallery(gallery);
                }}
                className={classes.galleryItem}
                style={{
                  border:
                    selectedGallery?.id === gallery.id
                      ? "2px solid blue"
                      : "2px solid white",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  Title: {gallery.title}
                </Typography>
                <div style={{ gap: 10, display: "flex", flexGrow: 100 }}>
                  {gallery.images.map((image, index) => {
                    return index < 5 ? (
                      <div
                        className={commonClasses.mediaSelect}
                        key={`gallery-image-${image.id}`}
                      >
                        <img
                          key={image.id}
                          src={
                            image.file
                              ? API_URL + "/" + image.file
                              : placeholder
                          }
                          width={150}
                          height={150}
                          alt={"media"}
                        />
                      </div>
                    ) : null;
                  })}
                </div>
                <div>
                  <Button
                    variant={"contained"}
                    onClick={() => navigate("/galleries/" + gallery.id)}
                    style={{ marginTop: 10, marginRight: 10 }}
                  >
                    EDIT GALLERY
                  </Button>
                  {isInGalleries(gallery.id) && (
                    <Button
                      variant={"contained"}
                      onClick={() => handleRemoveGallery(gallery.id)}
                      style={{ marginTop: 10 }}
                    >
                      REMOVE GALLERY
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </FormModal>
      <FormModal
        open={openVideo}
        handleSubmit={() => {
          if (selectedVideo) handleAddMedia("video", selectedVideo.id);
          setOpenVideo(false);
          setSelectedVideo(undefined);
        }}
        title="ADD VIDEO"
        onClose={() => setOpenVideo(false)}
        actionButtonText="INSERT VIDEO"
      >
        <MediaForm
          handleRemoveMedia={(mediaId) => handleRemoveMedia(mediaId, "video")}
          setSelectedMedia={setSelectedVideo}
          selectedMediaElements={selectedVideos}
          mediaType="video"
        />
      </FormModal>

      <FormModal
        actionButtonText="INSERT IMAGE"
        open={openImages}
        onClose={() => setOpenImages(false)}
        title="Selected images"
        handleSubmit={() => {
          if (selectedImage) handleAddMedia("image", selectedImage.id);
          setOpenImages(false);
          setSelectedImage(undefined);
        }}
        extraButtons={[
          {
            text: "ADD NEW MEDIA",
            onClick: () => navigate("/media-library/new"),
          },
        ]}
        closeButtonText="CLOSE"
      >
        <MediaForm
          handleRemoveMedia={(mediaId) => handleRemoveMedia(mediaId, "image")}
          setSelectedMedia={setSelectedImage}
          selectedMediaElements={selectedImages}
          mediaType="image"
        />
      </FormModal>

      <FormProvider>
        <FormModal
          title="Code Block"
          actionButtonText="ADD CODE"
          open={openCode}
          onClose={() => setOpenCode(false)}
          handleSubmit={handleAddCode}
          shouldValidateForm
        >
          <TextInput
            value={language}
            onChange={(id, value) => setLanguage(value)}
            id={"language"}
            label={"Language"}
            validators={[{ required: true }]}
          />
          <TextInput
            multiline={true}
            value={code}
            onChange={(id, value) => setCode(value)}
            id={"code"}
            label={"Code"}
            validators={[{ required: true }]}
          />
        </FormModal>
      </FormProvider>
      <FormProvider>
        <FormModal
          open={openIframe}
          onClose={() => setOpenIframe(false)}
          handleSubmit={handleAddIframe}
          title="Iframe"
          shouldValidateForm
          actionButtonText="ADD IFRAME"
        >
          <TextInput
            value={iframeData}
            onChange={(id, value) => setIframeData(value)}
            id={"irame-src"}
            label={"Content"}
            validators={[{ required: true }]}
          />
        </FormModal>
      </FormProvider>
    </div>
  );
};

export default QuillEditor;
