import { LAYOUT_ID } from "../layouts/Default";
import { useEffect } from "react";

export type STATUS = 'hidden' | 'visible';

/**
 * Triggers a callback when a desired element hide/show itself while scrolling inside entity layout.
 */
function useLayoutElementScrollTrigger(ref: React.RefObject<HTMLDivElement>, options: {
    threshold: number;
    rootMargin: string;
}, cb: (status: STATUS) => any) {


    useEffect(() => {
        let observer: IntersectionObserver;

        if (ref.current) {
            observer = new IntersectionObserver((entries, observer) => {
                const [entry] = entries;

                if (entry.isIntersecting) {
                    cb('visible')
                } else {
                    cb('hidden');
                }

            }, {
                root: document.querySelector(`#${LAYOUT_ID}`),
                rootMargin: options.rootMargin,
                threshold: options.threshold
            })

            observer.observe(ref.current);
        }

        // Cleanup function
        return () => {
            if (observer) {
                observer.disconnect()
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export default useLayoutElementScrollTrigger;