import { makeStyles } from "@mui/styles";

export const useCommonClasses = makeStyles({
    
    modalWrapper: {
      width: "80%",
      maxWidth: "80%",
      height: "80%",
      maxHeight: "80%",
      padding: 30,
      backgroundColor: "#DDD",
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
    },
    modalContent: {
      display: "flex",
      overflowY: "scroll",
      paddingRight: 10,
      flexGrow: 100,
      flexDirection: 'column'
    },
    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    buttons: {
      display: "flex",
      justifyContent: "end",
      gap: 20,
      marginTop: 10,
    },
    modalContentImage: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 100,
      paddingRight: 10,
    },
    imagesContent: {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
      marginBottom: 10,
    },
    mediaContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: 100,
    },
    mediaSelect: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      maxWidth: 300,
      alignItems: "center",
      borderRadius: 4,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      padding: 10,
    },
});
