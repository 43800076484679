export interface CategoryItem {
	id: number,
	name: string,
	slug: string,
	description: string,
}

export const defaultData: CategoryItem = {
	id: -1,
	name: '',
	slug: '',
	description: ''
}