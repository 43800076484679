import React, {useState} from 'react';
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import {useParams} from "react-router-dom";
import EntityDetailLayout from "../layouts/EntityDetail";
import {defaultData} from '../types/CategoryItem';
import TextInput from '../components/TextInput';
import {defaultPartnerItem, PartnerItem} from '../types/PartnerItem';
import MediaPicker from '../components/MediaPicker';
import {getImageForm} from "../utils/methods";

const tableHeader = [
	{name: "ID", attr: "id"},
	{name: "Title", attr: "title"},
	{name: "Link", attr: "link"},
	{name: "Featured Image", attr: "featured_image", type: "image"}
]

export default function Partners() {

	const {entityId} = useParams()
	const [detailData, setDetailData] = useState<PartnerItem>(defaultPartnerItem)

	const getFormData = () => {
		// const formData = new FormData()
		// formData.append("title", detailData.title)
		// if(detailData.link) formData.append("link", detailData.title)
		// formData.append("featured_image", Number(detailData.featured_image).toString())

		return {
			title: detailData.title,
			link: detailData.link,
			featured_image: getImageForm(detailData.featured_image)
		}
	}

	const changeData = (attr: string, value: any) => {
		setDetailData(prev => {
			return {...prev, [attr]: value}
		})
	}

	const isEditing = () => entityId?.toLowerCase() !== "new";

	if(entityId) {
		return (
			<DefaultLayout>
				<EntityDetailLayout id={detailData.id} title={"Partners"} apiUrl={"/partner"} defaultValue={defaultData} setData={setDetailData} getFormData={getFormData} navigateBack={isEditing() ? false : true}>
					<TextInput value={detailData.title} onChange={changeData} id={"title"} label={"Title"} validators={[{required: true}, {maxLimit: 255}]}/>
					{/* eslint-disable-next-line no-useless-escape */}
					<TextInput value={detailData.link} onChange={changeData} id={"link"} label={"Link"} validators={[{required: true}, {regex: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, error: "Value should be an URL address"}, {maxLimit: 255}]}/>
					<MediaPicker value={detailData.featured_image} onChange={changeData} id={"featured_image"} label={"Featured Image"} validators={[{required: true}]} />
				</EntityDetailLayout>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<DataTable title={"Partners"} apiUrl={"/partner"} url={"/partners"} tableHeader={tableHeader} />
		</DefaultLayout>
	)
}
