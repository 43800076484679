export interface MediaItem {
	id: number,
	file: string,
	file_name: string,
	file_ext: string,
	file_size: number,
	mimetype: string,
	title: string,
	label: string,
	alternative_text: string,
	created_at: string,
	updated_at: string,
	uploaded_by_id: number,
	dimensions: string,
	original_name: string,

	file_data: File | undefined,
	is_selected: boolean,
	muxAssetId?: string
}

export const defaultMediaItem: MediaItem = {
	dimensions: "",
	file_size: 0,
	label: "",
	original_name: "",
	alternative_text: '',
	created_at: '',
	file: '',
	file_ext: '',
	file_name: '',
	id: 0,
	mimetype: '',
	title: '',
	updated_at: '',
	uploaded_by_id: 0,

	is_selected: false,
	file_data: undefined
}
