export interface TagItem {
	id: number,
	title: string,
	slug: string,
}

export const defaultTagItem : TagItem = {
	id: -1,
	title: '',
	slug: ''
}
