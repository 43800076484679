export interface AboutUsItem {
    id: number,
    language: string,
    featured_image: {id: number}[],
    description: string,
    moto: string,
    who_are_we: string,
    birthday_video: {id: number}[],
    birthday_video_cover: {id: number}[],
    moto2: string,
    how_do_we_succeed: string,
    why_to_choose_us: string,
    recommendation_video: {id: number}[],
    recommendation_video_cover: {id: number}[],
};

export const defaultAboutUsItem: AboutUsItem = {
    id: 0,
    language: "en",
    featured_image: [],
    description: "",
    moto: "",
    who_are_we: "",
    birthday_video: [],
    birthday_video_cover: [],
    moto2: "",
    how_do_we_succeed: "",
    why_to_choose_us: "",
    recommendation_video: [],
    recommendation_video_cover: [],
};