import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../utils/constants";
import { getAuth } from "../utils/Auth";
import { useFormContext } from "../store/FormProvider";
import { ValidatorTypes } from "../types/Validation";
import { handleResponseError } from "../utils/methods";

const useStyles = makeStyles({
  mediaPicker: {
    margin: "5px 0",
  },
});

export interface IProps {
  value: any;
  id: string;
  label: string;
  validators: ValidatorTypes[];
  onChange: (id: string, value: any) => void;
  options?: string[];
  formatOptions?: (value: any[]) => string[];
  getDisplayValue?: (option: string, value: any) => React.ReactNode;
  multiple?: boolean;
  renderValue?: SelectProps["renderValue"];
}

const OptionPicker: FC<IProps> = (props) => {
  const classes = useStyles();
  const {
    id,
    label,
    value,
    formatOptions,
    validators,
    onChange,
    getDisplayValue,
    multiple,
    renderValue,    
  } = props;
  
  const [selected, setSelected] = useState<any>(undefined);
  const [options, setOptions] = useState<string[]>(props.options ?? []);

  const { errors, addValue, addValidationType } = useFormContext();
  const [, setError] = useState<string>("");

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
      if (value) {
        setSelected(props.options.find((option) => option === value));
      }
    } else {
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, value]);

  useEffect(() => {
    if (value) {
      addValue(id, value);
    }
    if (value && value.email) {
      setSelected(options.find((option) => option === value.email));
    }
  }, [value, options, addValue, setSelected, id]);

  useEffect(() => {
    addValidationType(id, validators);
  }, [addValidationType, id, validators]);

  useEffect(() => {
    if (errors.has(id)) {
      setError(errors.get(id)!);
    } else {
      setError("");
    }
  }, [errors, setError, id]);

  const getData = () => {
    if (formatOptions) {
      axios
        .get(API_URL + "/user", getAuth())
        .then((res) => {
          setOptions(formatOptions(res.data.data));
          if (value && value.email) {
            setSelected(
              formatOptions(res.data.data).find(
                (option) => option === value.email
              )
            );
          }
        })
        .catch((err) => {
          console.error(err);
          handleResponseError(err.response, "while loading user");
        });
    }
  };

 

  useEffect(() => {
    if (selected !== undefined) {
      onChange(id, selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  const finalValue = value ? value : multiple ? [] : "";

  return (
    <div className={classes.mediaPicker}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          value={finalValue}
          label={label}
          onChange={(event) => setSelected(event.target.value)}
          multiple={multiple}
          renderValue={renderValue}
        >
          {options ? (
            options.map((option, index) => {
              return (
                <MenuItem value={option} key={index}>
                  {getDisplayValue
                    ? getDisplayValue(option, finalValue)
                    : option}
                </MenuItem>
              );
            })
          ) : (
            <div>No data</div>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default OptionPicker;
