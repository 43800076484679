import { useCallback, useRef } from "react";
import { Paper, SeoAssessor, helpers, languageProcessing } from "yoastseo";
import { BlogItem } from "../../types/BlogItem";

export const useSeoAnalysis = () => {
  const paperRef = useRef<any>(null);
  const seoAssessorRef = useRef<any>(null);

  const getPaper = (detailData: BlogItem) =>
    new Paper(
      // TODO gather images alt for the analysis
      detailData.content.replace(/\[image id=[0-9]+\]/, '<img src="" alt="" />'),
      {
        keyword: detailData.focus_keyphrase,
        description: detailData.meta_description,
        title: detailData.title,
        titleWidth: helpers.measureTextWidth(detailData.title),
        slug: detailData.slug,
        locale: "en_US",
      }
    );

  const initSeoAnalysis = useCallback((detailData: BlogItem) => {
    const newPaper = getPaper(detailData);

    if (!paperRef.current) {
      const researcher = new languageProcessing.EnResearcher(newPaper);
      seoAssessorRef.current = new SeoAssessor(researcher);
    }
  }, []);

  const analyzeSeo = useCallback((detailData: BlogItem) => {
    paperRef.current = getPaper(detailData);
    seoAssessorRef.current?.assess(paperRef.current);    
  }, []);

  // teardown is necessary to prevent analyser running in the background while we navigate among blogs
  const endSeoAnalysis = useCallback(() => {
    seoAssessorRef.current = null;
    paperRef.current = null;
  }, []);

  return [seoAssessorRef.current, initSeoAnalysis, analyzeSeo, endSeoAnalysis] as const;
};
