import { FC } from "react";
import { Button, Grid, Paper, Theme, Typography } from "@mui/material";
import MuxPlayer from "@mux/mux-player-react";
import { makeStyles } from "@mui/styles";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { MediaItem } from "../types/MediaItem";
import { getFileType, isImage, isVideo } from "../utils/methods";
import { API_URL } from "../utils/constants";

type StyleProps = {};

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  content: {
    marginTop: 20,
  },
  item: {
    cursor: "pointer",
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    padding: 20,
    justifyContent: "center",
  },
  mediaSelect: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    padding: 10,
    paddingBottom: 5,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  itemContent: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    flexGrow: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface IProps {
  itemSize?: "default" | "big";
  data?: MediaItem[];
  url?: string;
  onClick?: (item: MediaItem) => void;
  loadMore?: () => void;
}

const GridTable: FC<IProps> = ({
  data,
  url,
  itemSize = "default",
  onClick,
  loadMore,
}: IProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const itemSizes = {
    xs: 12,
    md: itemSize === "big" ? 12 : 4,
    lg: itemSize === "big" ? 6 : 3,
    xl: itemSize === "big" ? 4 : 2,
  };

  return (
    <div>
      <Grid container spacing={2}>
        {data?.map((item) => {
          return (
            <Grid item {...itemSizes} key={item.id}>
              <Paper
                className={classes.item}
                style={{
                  border: `${
                    item.is_selected ? "2px solid blue" : "2px solid white"
                  }`,
                }}
                sx={{
                  height: itemSize === "big" ? 300 : 200,
                }}
                onClick={() => {
                  if (url) navigate(url + "/" + item.id);
                  if (onClick) onClick(item);
                }}
              >
                <div key={item.id} className={classes.itemContent}>
                  {isImage(item.file_ext) && (
                    <img
                      src={
                        API_URL +
                        "/" +
                        item.file.slice(
                          0,
                          item.file.length - item.file_ext.length - 1
                        ) +
                        "-thumbnail." +
                        item.file_ext
                      }
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        backgroundColor: "#e6e6e6",
                      }}
                      alt={item.title}
                    />
                  )}
                  {isVideo(item.file_ext) &&
                    (item.file.startsWith("uploads") ? (
                      <video
                        controls
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                        src={API_URL + "/" + item.file}
                      />
                    ) : (
                      <MuxPlayer
                        streamType="on-demand"
                        playbackId={item.file}
                      />
                    ))}

                  {!isImage(item.file_ext) && !isVideo(item.file_ext) ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InsertDriveFileIcon style={{ fontSize: 80 }} />
                      <Typography variant={"h4"}>
                        .{getFileType(item.file)}
                      </Typography>
                    </div>
                  ) : null}
                </div>
                <Typography style={{ marginTop: 5 }}>
                  {item.id} -{" "}
                  {item.title.length > 17
                    ? item.title.slice(0, 17) + "..."
                    : item.title}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      {loadMore ? (
        <div className={classes.buttonContainer}>
          <Button variant={"contained"} onClick={loadMore}>
            LOAD MORE
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default GridTable;
