import React, { useState, useEffect } from "react";
import axios from "axios";
import DefaultLayout from "../../layouts/Default";
import EntityDetailLayout from "../../layouts/EntityDetail";
import TextInput from "../../components/TextInput";
import {
  defaultOurWorkSliderItem,
  OurWorkSliderItem,
} from "../../types/OurWorkSliderItem";
import MediaPicker from "../../components/MediaPicker";
import { getImageForm, handleResponseError } from "../../utils/methods";
import { getAuth } from "../../utils/Auth";
import { API_URL } from "../../utils/constants";
import { OurWorkItem } from "../../types/OurWorkItem";
import AutocompleteField from "../../components/AutocompleteField";
import OptionPicker from "../../components/OptionPicker";
import { Status } from "../../types/global";

export default function OurWorkSliderDetail() {
  const [ourWorkList, setOurWorkList] = useState<OurWorkItem[]>([]);
  const [detailData, setDetailData] = useState<OurWorkSliderItem>(
    defaultOurWorkSliderItem
  );

  function getOurWorkItems() {
    axios
      .get(`${API_URL}/our-work?limit=0`, getAuth())
      .then((res) => {
        setOurWorkList(res.data.data);
      })
      .catch((err) => {
        handleResponseError(err.response, "while loading our work records.");
      });
  }

  useEffect(() => {
    getOurWorkItems();
  }, []);

  const getFormData = () => {
    return {
      title: detailData.title,
      sub_title: detailData.sub_title ?? "",
      content: detailData.content,
      slide_image: getImageForm(detailData.slide_image),
      our_work: detailData.our_work,
      status: detailData.status,
    };
  };

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  function onChangeAutocompleteField(event: any, newValue: string | null) {
    changeData(
      "our_work",
      ourWorkList.filter((ourWork) => ourWork.slug === newValue)[0]
    );
  }

  return (
    <DefaultLayout>
      <EntityDetailLayout
        id={detailData.id}
        title={"Our Work Slider"}
        apiUrl={"/our-work-slider"}
        defaultValue={defaultOurWorkSliderItem}
        setData={setDetailData}
        getFormData={getFormData}
        showUsedInForEntity="ourWorkSlider"
      >
        <TextInput
          value={detailData.title}
          onChange={changeData}
          id={"title"}
          label={"Title"}
          validators={[{ required: true }, { maxLimit: 255 }]}
        />
        <TextInput
          value={detailData.sub_title ?? ""}
          onChange={changeData}
          id={"sub_title"}
          label={"Sub Title"}
          validators={[{ maxLimit: 255 }]}
        />
        <TextInput
          value={detailData.content}
          onChange={changeData}
          id={"content"}
          label={"Content"}
          validators={[{ required: true }]}
        />
        <AutocompleteField
          id="our_work"
          label="Our work slug"
          options={ourWorkList.map((ourWork) => ourWork.slug)}
          onChange={onChangeAutocompleteField}
          validators={[{ required: true }]}
          value={detailData.our_work?.slug ?? ""}
        />
        <OptionPicker
          value={detailData.status}
          id={"status"}
          label={"Status"}
          validators={[{ required: true }]}
          onChange={changeData}
          options={Object.values(Status)}
        />
        <MediaPicker
          value={detailData.slide_image}
          id={"slide_image"}
          label={"Slide Image"}
          onChange={changeData}
          validators={[{ required: true }]}
        />
      </EntityDetailLayout>
    </DefaultLayout>
  );
}
