import React, { createContext, FC, ReactElement, useContext, useEffect, useState } from 'react';
import { defaultUserItem, UserItem } from '../types/UserItem';
import { getUser } from '../utils/Auth';

interface IUserContext extends UserItem {
	setUser: (value: (user: UserItem) => UserItem) => void
}

export const UserContext = createContext<IUserContext>(null!)

export const useUserContext = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext must be used within a UserProvider');
	}
	return context;
}

interface IProps {
	children: ReactElement
}

const UserProvider: FC<IProps> = ({children}: IProps) => {

	const [user, setUser] = useState<UserItem>(defaultUserItem)

	useEffect(() => {
		getUser().then(user => {
			if(user) setUser(user)
		})
	}, [])

	return (
		<UserContext.Provider
			value={{
				...user,
				setUser
			}}
		>
			{children}
		</UserContext.Provider>
	)
}

export default UserProvider
