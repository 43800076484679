import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import MediaPicker from '../components/MediaPicker';
import OptionPicker from '../components/OptionPicker';
import TextInput from '../components/TextInput';
import DataTable, {
	IProps as DatatableProps,
} from "../components/dataTable/DataTable";
import DefaultLayout from "../layouts/Default";
import EntityDetailLayout from "../layouts/EntityDetail";
import { BranchManagerItem, defaultBranchManagerItem } from '../types/BranchMangerItem';
import { defaultData } from '../types/CategoryItem';
import { LanguageItem } from '../types/LanguageItem';
import { getAuth } from '../utils/Auth';
import { API_URL } from '../utils/constants';
import { getImageForm } from "../utils/methods";


const tableHeader = [
	{ name: "ID", attr: "id" },
	{ name: "Language", attr: "language" },
	{ name: "Name", attr: "name" },
	{ name: "Function", attr: "function" },
	{ name: "Description", attr: "description" },
	{ name: "Featured Image", attr: "featured_image", type: "image" }
]

const main_title = "Branch Managers"
const urls = "/branch-manager"

export default function BranchManagers() {

	const { entityId } = useParams()
	const [detailData, setDetailData] = useState<BranchManagerItem>(defaultBranchManagerItem)
	const [languages, setLanguages] = useState<LanguageItem[]>([]);
	const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);
	const [showDnDSorting, setShowDnDSorting] = useState(false);

	useEffect(() => {
		fetchLanguages();
	}, [])

	const fetchLanguages = async () => {
		setIsLoadingLanguages(true);
		const { data } = await axios.get(`${API_URL}/language`, getAuth());
		setLanguages(data.data)
		setIsLoadingLanguages(false);
	}

	const languageOptions = useMemo(() => languages.map(l => l.isoCode), [languages])

	const getFormData = () => {
		return {
			name: detailData.name,
			function: detailData.function,
			description: detailData.description,
			language: detailData.language,
			featured_image: getImageForm(detailData.featured_image)
		}
	}

	const changeData = (attr: string, value: any) => {
		setDetailData(prev => {
			return { ...prev, [attr]: value }
		})
	}

	const isEditing = () => entityId?.toLowerCase() !== "new";

	const datatableFilters: DatatableProps["filters"] = useMemo(() => ([
		{
			type: "select",
			props: {
				options: ["All", ...languageOptions],
				value: "All",
				label: "Language",
				id: "language",
				renderValue(value) {
					if (value === "") {
						return "All";
					}
					return value as string;
				},
				getDisplayValue(option: string) {
					if (option === "") {
						return "All";
					}
					return option;
				},
			},
			apiValueTransformer(value: string) {
				setShowDnDSorting(value === "All" || value === "")
				if (value === "All") {
					return "";
				}
				return value;
			},
		},
	]), [languageOptions]);

	if (isLoadingLanguages) {
		return null;
	}

	if (entityId) {
		return (
			<DefaultLayout>
				<EntityDetailLayout id={detailData.id} title={main_title} apiUrl={urls} defaultValue={defaultData} setData={setDetailData} getFormData={getFormData} navigateBack={isEditing() ? false : true} refreshUsedInOnSave>
					<TextInput value={detailData.name} onChange={changeData} id={"name"} label={"Name"} validators={[{ required: true }, { maxLimit: 255 }]} />
					<TextInput value={detailData.function} onChange={changeData} id={"function"} label={"Function"} validators={[{ required: true }, { maxLimit: 255 }]} />
					<TextInput value={detailData.description} onChange={changeData} id={"description"} label={"Description"} validators={[{ maxLimit: 150 }]} />
					<OptionPicker
						value={detailData.language}
						id={"language"}
						label={"Language"}
						validators={[{ required: true }]}
						onChange={changeData}
						options={languageOptions}
					/>
					<MediaPicker value={detailData.featured_image} onChange={changeData} id={"featured_image"} label={"Featured Image"} validators={[{ required: true }]} />
				</EntityDetailLayout>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<DataTable
				title={main_title}
				apiUrl={urls}
				url={"/branch-manager"}
				tableHeader={tableHeader}
				filters={datatableFilters}
				allowDnDSorting={showDnDSorting}
			/>
		</DefaultLayout>
	)
}
