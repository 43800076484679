import { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from "quill-blot-formatter";

class CustomImageResize extends ImageResize {
    constructor(...params: any[]) {
        super(...params);

        this.observer = new MutationObserver((mutations) => {
            this.repositionElements();
        });

        this.quill.root.addEventListener('click', this.handleClick);
    }

    handleClick = (evt: any) => {

        if(this.img) {
            this.observer.observe(this.img, { attributes: true, attributeFilter: ['style']})
        } else {
            this.observer.disconnect();
        }

    };
}


Quill.register('modules/imageResize', CustomImageResize);

Quill.register('modules/blotFormatter', BlotFormatter);
