import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import DefaultLayout from "../../layouts/Default";
import EntityDetailLayout from "../../layouts/EntityDetail";
import { makeStyles } from "@mui/styles";

import placeholder from "../../assets/placeholder-image.png";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { defaultMediaItem, MediaItem } from "../../types/MediaItem";
import { getFileType, isImage, isVideo } from "../../utils/methods";
import { API_URL } from "../../utils/constants";
import { useUserContext } from "../../store/UserProvider";
import TextInput from "../../components/TextInput";
import MuxPlayer from "@mux/mux-player-react";


const useStyles = makeStyles({
  contentBox: {
    display: "flex",
    width: "100%",
    gap: 20,
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    maxWidth: "50%",
    "& img": {
      width: "100%",
    },
  },
  rightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  resourceLink: {
    marginTop: "32px",
  },
});

type MediaFile = { type: string; source: string; path: string };

export default function MediaLibraryDetail() {
  const classes = useStyles();

  const { entityId } = useParams();
  const fileRef = useRef<HTMLInputElement>(null);
  const userContext = useUserContext();

  const [detailData, setDetailData] = useState<MediaItem>(defaultMediaItem);
  const [file, setFile] = useState<MediaFile>({
    type: "image",
    source: placeholder,
    path: "",
  });

  const getFormData = () => {
    const formData = new FormData();
    formData.append("title", detailData.title);
    formData.append("alternative_text", detailData.alternative_text ?? "");
    formData.append("label", detailData.label ?? "");

    if (detailData.file_data) {
      const type = getFileType(detailData.file_data);
      type && formData.append("file_ext", type);

      formData.append("file", detailData.file_data);
      formData.append("file_name", detailData.file_data.name);
      formData.append("original_name", detailData.file_data.name);
      formData.append("file_size", detailData.file_data.size.toString());
      formData.append("dimensions", detailData.dimensions);
      formData.append("mimetype", detailData.file_data.type);
    }
    formData.append("uploaded_by_id", userContext.id.toString());
    return formData;
  };

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  useEffect(() => {
    let data: MediaFile = { type: "", source: "", path: "" };

    if (detailData.file) {
      data.path = detailData.file;

      if (!detailData.file_data && detailData.file.startsWith("uploads")) {
        data.source = `${API_URL}/${detailData.file}`;
      } else {
        data.source = detailData.file;
      }
      data.type = "other";
      if (isImage(detailData.file_ext)) data.type = "image";

      if (isVideo(detailData.file_ext)) data.type = "video";
    } else {
      data = { type: "image", source: placeholder, path: "" };
    }
    setFile(data);
  }, [detailData]);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File | null | undefined = e.target.files?.[0];

    if (file) {
      setDetailData((prev) => {
        const ext = file.name.split(".").pop();
        const filename = ext
          ? file.name.slice(0, file.name.length - ext.length - 1)
          : "";
        if (ext && isImage(ext)) {
          const img = new Image();
          img.onload = function () {
           
            setDetailData((prev) => {
              return {
                ...prev,
                dimensions: img.width + "x" + img.height,
              };
            });
          };
          img.src = URL.createObjectURL(file);
        }
        return {
          ...prev,
          file: URL.createObjectURL(file),
          file_data: file,
          file_ext: ext ? ext : "",
          file_size: file.size,
          original_name: filename,
          dimensions: "",
          updated_at: new Date().toISOString(),
        };
      });
    }
  };

  const isMuxVideo = !!detailData.muxAssetId;

  return (
    <DefaultLayout>
      <EntityDetailLayout
        id={detailData.id}
        title={"Media Library"}
        apiUrl={"/media"}
        defaultValue={defaultMediaItem}
        setData={setDetailData}
        getFormData={getFormData}
        showUsedInForEntity="media"
      >
        <div className={classes.contentBox}>
          <div className={classes.leftSide}>
            <div
              style={{
                flexGrow: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {file.type === "image" || !file.type ? (
                <img
                  src={file.source}
                  style={{
                    borderRadius: 5,
                    background: "#e6e6e6",
                    boxShadow: "0 0 2px #ccc",
                    maxWidth: "fit-content",
                  }}
                  alt={"media"}
                />
              ) : null}
              {file.type === "video" ? (
                !isMuxVideo || file.path.startsWith("blob") ? (
                  <video controls style={{ borderRadius: 5, width: "100%" }}>
                    <source src={file.source} />
                  </video>
                ) : (
                  <MuxPlayer streamType="on-demand" playbackId={file.source} />
                )
              ) : null}
              {file.type === "other" ? (
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <InsertDriveFileIcon style={{ fontSize: 120 }} />
                  <Typography variant={"h4"}>
                    .
                    {getFileType(
                      detailData.file_data
                        ? detailData.file_data
                        : detailData.file
                    )}
                  </Typography>
                </div>
              ) : null}
            </div>
            <div>
              <input
                id={"file"}
                placeholder={"File"}
                type={"file"}
                name={"file"}
                ref={fileRef}
                style={{ display: "none" }}
                onChange={onFileChange}
              />
              {!detailData.file_data && detailData.file && !isMuxVideo ? (
                <a
                  href={`${API_URL}/${detailData.file}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  download
                  rel="noreferrer"
                >
                  <Button style={{ marginTop: 20 }} variant={"contained"}>
                    DOWNLOAD FILE
                  </Button>
                </a>
              ) : null}
              {detailData.file_data || !detailData.file || !isMuxVideo ? (
                <Button
                  style={{ marginTop: 20, marginLeft: 20 }}
                  variant={"contained"}
                  onClick={() => fileRef.current?.click()}
                >
                  {entityId === "new" ? "SELECT FILE" : "EDIT FILE"}
                </Button>
              ) : null}
            </div>
          </div>
          <div className={classes.rightSide}>
            <TextInput
              value={detailData.title ?? ""}
              id={"title"}
              label={"Title"}
              onChange={changeData}
              validators={[{ required: true }]}
            />
            <TextInput
              value={detailData.alternative_text ?? ""}
              id={"alternative_text"}
              label={"Alternative Text"}
              onChange={changeData}
            />
            <TextInput
              value={detailData.label ?? ""}
              id={"label"}
              label={"Label"}
              onChange={changeData}
            />
            <TextInput
              value={detailData.original_name ?? ""}
              id={"original_name"}
              label={"Original Name"}
              onChange={changeData}
            />

            <Typography variant={"h6"} style={{ marginTop: 20 }}>
              <b>Metadata:</b>
            </Typography>
            <Typography>
              <b>File size:</b> {detailData.file_size} B
            </Typography>
            <Typography>
              <b>Dimensions:</b> {detailData.dimensions}
            </Typography>
            <Typography>
              <b>Updated at:</b>{" "}
              {new Date(detailData.updated_at).toDateString()}
            </Typography>
            <Typography>
              <b>Created at:</b>{" "}
              {new Date(detailData.created_at).toDateString()}
            </Typography>
          </div>
        </div>
        {detailData.file && !detailData.file_data && (
          <p className={classes.resourceLink}>
            <CopyToClipboard text={`${API_URL}/${detailData.file}`}>
              <IconButton
                aria-label="copy to clipboard"
                size="small"
                color="primary"
              >
                <ContentCopy fontSize="inherit" />
              </IconButton>
            </CopyToClipboard>
            Link: {isMuxVideo ? `https://stream.mux.com/${detailData.file}/high.mp4` : file.source}
          </p>
        )}
      </EntityDetailLayout>
    </DefaultLayout>
  );
}
