import React, {useState} from 'react';
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import {useParams} from "react-router-dom";
import EntityDetailLayout from "../layouts/EntityDetail";
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import {defaultUserItem, UserItem} from '../types/UserItem';
import OptionPicker from '../components/OptionPicker';
import TextInput from '../components/TextInput';
import ProfilePicker from '../components/ProfilePicker';

const tableHeader = [
	{name: "ID", attr: "id"},
	{name: "Avatar", attr: "profile_picture", defaultValue: 'avatar', type: "image"},
	{name: "Name", attr: "first_name"},
	{name: "Surname", attr: "last_name"},
	{name: "Email", attr: "email"},
	{name: "Role", attr: "role"},
	{name: "Active", attr: "is_active", type: "boolean"},
]

const Users = () => {

	const {entityId} = useParams()
	const [detailData, setDetailData] = useState<UserItem>(defaultUserItem)

	const getFormData = () => {
		return {
			email: detailData.email,
			first_name: detailData.first_name,
			last_name: detailData.last_name,
			is_active: !!detailData.is_active,
			role: detailData.role,
			biographical_info: detailData.biographical_info,
			password: detailData.password,
			profile_picture: detailData.profile_picture
		}
	}

	const changeData = (attr: string, value: any) => {
		setDetailData(prev => {
			return {...prev, [attr]: value}
		})
	}

	const setData = (data: UserItem) => {
		setDetailData(data)
	}

	const roles = ["ADMIN", "EDITOR", "CONTRIBUTOR"]

	if(entityId) {
		return (
			<DefaultLayout>
				<EntityDetailLayout id={detailData.id} title={"Users"} apiUrl={"/user"} defaultValue={defaultUserItem} setData={setData} getFormData={getFormData}>
					<FormGroup>
						<>
							<div style={{display: "flex", width: "100%"}}>
							<ProfilePicker photo={detailData.profile_picture?.file} avatar={detailData.avatar} onChange={changeData} />
								<div style={{flexGrow: 100}}>
									<TextInput id={"first_name"} label={"First Name"} value={detailData.first_name} onChange={changeData} validators={[{required: true}]} />
									<TextInput id={"last_name"} label={"Last Name"} value={detailData.last_name} onChange={changeData} validators={[{required: true}]} />
									<TextInput id={"email"} label={"Email"} value={detailData.email} onChange={changeData} validators={[{required: true}, {regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/}]} />
									<FormControlLabel control={<Checkbox checked={Boolean(detailData.is_active)} onChange={(e) => changeData("is_active", e.target.checked)} />} label="Active" />
								</div>
							</div>
							<TextInput id={"biographical_info"} label={"Biographical Info"} value={detailData.biographical_info} onChange={changeData} />
							{entityId === "new" && <TextInput id={"password"} label={"Password"} value={detailData.password} onChange={changeData} validators={[{required: true}, {minLimit: 8, error: "Password should be at least 8 characters long"}]}/>}
							<OptionPicker value={detailData.role} id={"role"} label={"Role"} validators={[{required: true}]} onChange={changeData} options={roles} />
						</>
					</FormGroup>
				</EntityDetailLayout>
			</DefaultLayout>
		)
	}

	return (
		<DefaultLayout>
			<DataTable title={"Users"} apiUrl={"/user"} url={"/users"} tableHeader={tableHeader} />
		</DefaultLayout>
	)
}

export default Users
