import { FC, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { MediaItem } from "../types/MediaItem";
import { Button, Modal, TextField, Typography } from "@mui/material";
import GridTable from "./GridTable";
import { Close } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { API_URL } from "../utils/constants";
import { getAuth } from "../utils/Auth";
import { handleResponseError, isImage, isVideo } from "../utils/methods";
import { useFormContext } from "../store/FormProvider";
import { ValidatorTypes } from "../types/Validation";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MuxPlayer from "@mux/mux-player-react";

const useStyles = makeStyles({
  mediaPicker: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 22px)",
    gap: 5,
    border: "1px solid rgba(0, 0, 0, 0.27)",
    height: "calc(100% - 32px)",
    borderRadius: 4,
    marginTop: 5,
    marginBottom: 5,
    padding: 10,
  },
  images: {
    flexGrow: 100,
  },
  modalWrapper: {
    width: "80%",
    maxWidth: "80%",
    height: "80%",
    maxHeight: "80%",
    padding: 30,
    backgroundColor: "#DDD",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
  },
  modalContent: {
    // height: "85%",
    // maxHeight: "85%",
    overflowY: "scroll",
    paddingRight: 10,
    flexGrow: 100,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
    gap: 10,
    paddingTop: 20,
  },
  mediaSelect: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    padding: 10,
    paddingBottom: 5,
  },
  search: {
    backgroundColor: "white",
    margin: "0 !important",
  },
  image: {
    maxHeight: 300,
    maxWidth: 300,
    objectFit: "contain",
    background: "#e6e6e6",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  deleteButton: {
    background: "transparent",
    cursor: "pointer",
    border: "none",
  },
});

interface IProps {
  id: string;
  label: string;
  multiple?: boolean;
  value:
    | { id: number; file?: string }[]
    | { id: number; file?: string }
    | undefined;
  validators?: ValidatorTypes[];
  onChange: (
    id: string,
    item: { id: number }[],
    selectedMedia: MediaItem[]
  ) => void;
  mediaType?: string;
  showPreview?: boolean;
  open?: boolean;
  onClose?: () => void;
}

export function getMediaFileUrl(path: string) {
  return API_URL + "/" + path;
}

const MediaPicker: FC<IProps> = ({
  id,
  label,
  mediaType,
  value,
  validators,
  onChange,
  multiple,
  showPreview = true,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState<MediaItem[]>([]);
  const [clickedMedia, setClickedMedia] = useState<MediaItem[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<MediaItem[]>([]);

  const { errors, addValue, addValidationType } = useFormContext();
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    addValidationType(id, validators);
  }, [addValidationType, id, validators]);

  useEffect(() => {
    if (open !== undefined) {
      setOpenModal(open);
    }
  }, [open]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (selectedMedia) {
      addValue(id, selectedMedia);
      // onChange(id, selectedMedia)
    }
  }, [selectedMedia, addValue, id]);

  useEffect(() => {
    if (errors.has(id)) setError(errors.get(id)!);
    else setError("");
  }, [errors, setError, id]);

  const getData = async () => {
    const addItem = async (id: number, items: MediaItem[]) => {
      const data = await axios.get(API_URL + "/media/" + id, getAuth());
      if (data.status !== 200 && data.status !== 201)
        handleResponseError(data, "while loading media item");
      const media: MediaItem = { ...data.data, is_selected: true };
      items.push(media);
    };

    const items: MediaItem[] = [];
    if (value) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          await addItem(value[i].id, items);
        }
      } else {
        const data = await axios.get(API_URL + "/media/" + value.id, getAuth());
        if (data.status !== 200 && data.status !== 201)
          handleResponseError(data, "while loading media item");
        const media: MediaItem = { ...data.data, is_selected: true };
        items.push(media);
      }
      setSelectedMedia(items);
      setClickedMedia(items);
    }

    axios
      .get(
        API_URL +
          `/media?${mediaType ? "file=" + mediaType : ""}&${
            search ? "search=" + search : ""
          }`,
        getAuth()
      )
      .then(async (res) => {
        const data = [
          ...items,
          ...res.data.data.filter(
            (data: any) => !Boolean(items.find((item) => item.id === data.id))
          ),
        ];
        setData(data);
        setPage(res.data.meta.currentPage);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while loading media");
      });
  };

  const loadMore = () => {
    axios
      .get(
        `${API_URL}/media?${mediaType ? "file=" + mediaType : ""}&${
          !page && page !== 0 ? "" : "page=" + (page + 1)
        }&${search ? "search=" + search : ""}`,
        getAuth()
      )
      .then((res) => {
        setData((prev) => {
          const data = [
            ...prev,
            ...res.data.data.filter(
              (data: any) =>
                !Boolean(selectedMedia.find((item) => item.id === data.id))
            ),
          ];
          return data.filter((item) => {
            if (mediaType === "video") return isVideo(item.file_ext);
            if (mediaType === "image") return isImage(item.file_ext);
            if (!mediaType) return true;
            return true;
          });
        });
        setPage(res.data.meta.currentPage);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while loading user");
      });
  };

  const handleSelect = (item: MediaItem) => {
    if (!multiple) {
      setClickedMedia((prev) => {
        data.forEach((item) => (item.is_selected = false));
        item.is_selected = true;
        return [item];
      });
      return;
    }
    if (item.is_selected) {
      setClickedMedia((prev) => {
        item.is_selected = false;
        return prev.filter((selected) => selected.id !== item.id);
      });
    } else {
      setClickedMedia((prev) => {
        item.is_selected = true;
        return [...prev, item];
      });
    }
  };

  function execOnClose() {
    if (typeof onClose === "function") {
      onClose();
    }
  }

  function onEmpty() {
    setSelectedMedia([]);
    onChange(id, [], []);
  }

  const isRequired = !!validators?.find((type) => type.required);

  return (
    <>
      {showPreview && (
        <div className={classes.mediaPicker}>
          <>
            <div className={classes.titleContainer}>
              <Typography variant={"h6"}>{label}</Typography>
              {!isRequired && selectedMedia && selectedMedia.length !== 0 && (
                <button className={classes.deleteButton} onClick={onEmpty}>
                  <Tooltip title="Delete media">
                    <Close color="error" />
                  </Tooltip>
                </button>
              )}
            </div>
            <div className={classes.images}>
              {selectedMedia && selectedMedia.length !== 0 ? (
                <div style={{ height: "90%" }}>
                  <Typography>Selected files:</Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      gap: 10,
                      flexWrap: "wrap",
                      margin: "10px 0",
                    }}
                  >
                    {selectedMedia.map((media) => {
                      return (
                        <div key={media.id} className={classes.mediaSelect}>
                          {isImage(media.file_ext) && (
                            <img
                              src={getMediaFileUrl(media.file)}
                              className={classes.image}
                              alt={media.title}
                            />
                          )}
                          {isVideo(media.file_ext) &&
                            (media.file.startsWith("uploads") ? (
                              <video
                                style={{
                                  maxHeight: 300,
                                  maxWidth: 300,
                                  objectFit: "contain",
                                }}
                              >
                                <source src={getMediaFileUrl(media.file)} />
                              </video>
                            ) : (
                              <MuxPlayer
                                streamType="on-demand"
                                playbackId={media.file}
                              />
                            ))}
                          <Typography style={{ marginTop: 5 }}>
                            Name:{" "}
                            {media.title.length > 28
                              ? media.title.slice(0, 28) + "..."
                              : media.title}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 50,
                  }}
                >
                  <Typography>File not selected</Typography>
                </div>
              )}
            </div>
            <Button
              variant={"contained"}
              color={error ? "error" : "primary"}
              style={{ marginTop: 10 }}
              onClick={() => setOpenModal(true)}
            >
              SELECT FILE
            </Button>
            {error && (
              <Typography variant={"caption"} color={error ? "red" : "black"}>
                File is required
              </Typography>
            )}
          </>
        </div>
      )}
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          execOnClose();
        }}
        className={classes.modal}
      >
        <div className={classes.modalWrapper}>
          <Typography variant={"h4"} style={{ marginBottom: 20 }}>
            Media Library
          </Typography>
          <div className={classes.modalContent}>
            <GridTable
              data={data}
              itemSize={mediaType === "video" ? "big" : "default"}
              onClick={handleSelect}
            />
          </div>
          <div className={classes.buttons}>
            <TextField
              placeholder={"Search..."}
              className={classes.search + " search-box"}
              onChange={(event) => setSearch(event.target.value)}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />

            <Button
              variant={"contained"}
              onClick={() => navigate("/media-library/new")}
            >
              ADD NEW MEDIA
            </Button>
            <Button variant={"contained"} onClick={loadMore}>
              LOAD MORE
            </Button>
            <Button variant={"contained"} onClick={() => setOpenModal(false)}>
              DISCARD
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                onChange(
                  id,
                  clickedMedia.map((item) => {
                    return { id: item.id };
                  }),
                  clickedMedia
                );
                setSelectedMedia(clickedMedia);
                execOnClose();
                setOpenModal(false);
              }}
            >
              SELECT MEDIA
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MediaPicker;
