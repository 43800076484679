export interface JobContactItem {
  id: number,
  location: string,
  name: string,
  phone: string,
  email: string,
}

export const defaultJobContactItem : JobContactItem = {
  id: -1,
  location: '',
  name: '',
  phone: '',
  email: '',
}