import React, { useState } from 'react';
import DefaultLayout from '../../layouts/Default';
import DataTable from '../../components/dataTable/DataTable';
import { useParams } from 'react-router-dom';
import EntityDetailLayout from '../../layouts/EntityDetail';
import TextInput from '../../components/TextInput';
import { defaultPositionItem, PositionItem } from '../../types/PositionItem';
import { Checkbox, FormControlLabel } from '@mui/material';
import OptionPicker from '../../components/OptionPicker';
import QuillEditor from '../../components/QuillEditor/QuillEditor';
import MediaPicker from '../../components/MediaPicker';
import { getImageForm } from '../../utils/methods';
import { usePreview } from '../../hooks/usePreview';
import { Preview } from '@mui/icons-material';
import { EMAILS } from '../../types/global';

const locations: Record<any, string> = {
  '': 'All',
  slovakia: 'Slovakia (HQ)',
  serbia: 'Serbia',
  argentina: 'Argentina',
  tunisia: 'Tunisia',
  uk: 'United Kingdom',
};

const tableHeader = [
  { name: 'ID', attr: 'id' },
  { name: 'Title', attr: 'title' },
  { name: 'Location', attr: 'location' },
  { name: 'Slug', attr: 'slug' },
  { name: 'Is Open', attr: 'is_open', type: 'boolean' },
  { name: 'Status', attr: 'status' },
  { name: 'Position image', attr: 'position_image', type: 'image' },
];

export default function Positions() {
  const { entityId } = useParams();
  const [detailData, setDetailData] =
    useState<PositionItem>(defaultPositionItem);

  const getFormData = () => {
    return {
      title: detailData.title,
      target_email: detailData.target_email ?? EMAILS.JOB,
      sub_title: detailData.sub_title,
      slug: detailData.slug,
      description: detailData.description,
      is_open: detailData.is_open,
      location: detailData.location || null,
      status: detailData.status,
      meta_title: detailData.meta_title,
      meta_description: detailData.meta_description, 
      focus_keyphrase: detailData.focus_keyphrase,
      position_image: getImageForm(detailData.position_image),
      social_image: getImageForm(detailData.social_image),

    };
  };

  const changeData = (attr: string, value: any) => {
    if (attr === 'location' && value instanceof Array) {
      if (value.includes('') && detailData.location) {
        // set only to All if All is selected
        value = '';
      } else {
        value = value.filter((location) => location !== '').join(',');
      }
    }

    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  const { openPreview } = usePreview('positions', getFormData, detailData);

  const isEditing = () => entityId?.toLowerCase() !== 'new';

  if (entityId) {
    return (
      <DefaultLayout>
        <EntityDetailLayout
          id={detailData.id}
          title={'Position'}
          apiUrl={'/position'}
          defaultValue={defaultPositionItem}
          navigateBack={isEditing() ? false : true}
          setData={setDetailData}
          getFormData={getFormData}
          customButtons={[
            {
              label: 'WEB PREVIEW',
              onClick: openPreview,
              floatButtonIcon: <Preview />,
            },
          ]}
        >
          <TextInput
            value={detailData.title}
            onChange={changeData}
            id={'title'}
            label={'Title'}
            validators={[{ required: true }, { maxLimit: 255 }]}
          />
          <TextInput
            value={detailData.sub_title}
            onChange={changeData}
            id={'sub_title'}
            label={'Sub Title'}
            validators={[{ required: true }]}
          />
          <OptionPicker
            value={detailData.location?.split(',') || ['']}
            id={'location'}
            label={'Location'}
            validators={[]}
            onChange={changeData}
            options={['', 'slovakia', 'serbia', 'argentina', 'tunisia', 'uk']}
            renderValue={(selected) =>
              (selected as any[]).map((value) => locations[value]).join(', ')
            }
            getDisplayValue={(option, value) => {
              return (
                <>
                  <Checkbox checked={value.includes(option)} />
                  {locations[option || '']}
                </>
              );
            }}
            multiple={true}
          />
          <TextInput
            value={detailData.slug}
            onChange={changeData}
            id={'slug'}
            label={'Slug'}
            validators={[{ regex: /^[-a-zA-Z0-9_]+$/ }, { required: true }]}
          />
          <TextInput
            value={detailData.target_email}
            onChange={changeData}
            id={'target_email'}
            label={'Target Email'}
            validators={[{ regex: /^(.+)@(.+){2,}\.(.+){2,}$/ }]}
          />
          <TextInput
            value={detailData.meta_title}
            onChange={changeData}
            id={'meta_title'}
            label={'Meta Title'}
            validators={[{ required: true }, { maxLimit: 255 }]}
          />
          <TextInput
            value={detailData.meta_description}
            onChange={changeData}
            id={'meta_description'}
            label={'Meta Description'}
            validators={[{ required: true }]}
          />
          <TextInput
            value={detailData.focus_keyphrase}
            onChange={changeData}
            id={'focus_keyphrase'}
            label={'Focus Keyphrase'}
            validators={[{ required: true }, { maxLimit: 75 }]}
          />
          <OptionPicker
            value={detailData.status}
            id={'status'}
            label={'Status'}
            validators={[{ required: true }]}
            onChange={changeData}
            options={['DRAFT', 'PUBLISHED']}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(detailData.is_open)}
                onChange={(e) => changeData('is_open', e.target.checked)}
              />
            }
            label="Open"
          />
          <MediaPicker
            value={detailData.position_image}
            id={'position_image'}
            label={'Position Image'}
            validators={[{ required: true }]}
            onChange={changeData}
          />
          <MediaPicker
            value={detailData.social_image}
            id={'social_image'}
            label={'Social Media Image'}
            validators={[{ required: false }]}
            onChange={changeData}
          />
          <QuillEditor
            value={detailData.description}
            onChange={changeData}
            id={'description'}
            label={'Description'}
            validators={[{ required: true }]}
            canAddVideo={false}
            canAddCode={false}
            canAddIframe={false}
          />
        </EntityDetailLayout>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <DataTable
        title={'Positions'}
        apiUrl={'/position'}
        url={'/positions'}
        tableHeader={tableHeader}
        valueFormatter={(attr: string, value: any) => {
          if (attr === 'location') {
            return (value || '')
              .split(',')
              .map((location: string) => locations[location])
              .join(', ');
          } else {
            return value;
          }
        }}
      />
    </DefaultLayout>
  );
}
