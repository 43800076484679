import React, { useState } from "react";
import DefaultLayout from "../../layouts/Default";
import EntityDetailLayout from "../../layouts/EntityDetail";
import { defaultData } from "../../types/CategoryItem";
import { ContactItem, defaultContactItem } from "../../types/ContactItem";
import TextInput from "../../components/TextInput";
import MediaPicker from "../../components/MediaPicker";
import { getImageForm } from "../../utils/methods";

export default function ContactDetail() {
  const [detailData, setDetailData] = useState<ContactItem>(defaultContactItem);

  const getFormData = () => {
    return {
      title: detailData.title,
      email: detailData.email,
      address: detailData.address,
      zip_code: detailData.zip_code,
      city: detailData.city,
      state: detailData.state,
      phone_1: detailData.phone_1,
      phone_2: detailData.phone_2,
      contact_image: getImageForm(detailData.contact_image),
    };
  };

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

    return (
      <DefaultLayout>
        <EntityDetailLayout
          id={detailData.id}
          title={"Contacts"}
          apiUrl={"/contact"}
          defaultValue={defaultData}
          setData={setDetailData}
          getFormData={getFormData}	  
        >
          <TextInput
            value={detailData.title}
            onChange={changeData}
            id={"title"}
            label={"Title"}
            validators={[{ required: true }, { maxLimit: 100 }]}
          />
          <TextInput
            value={detailData.email}
            onChange={changeData}
            id={"email"}
            label={"Email"}
            validators={[
              /* eslint-disable-next-line no-useless-escape */
              { regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ },
              { maxLimit: 254 },
            ]}
          />
          <TextInput
            value={detailData.address}
            onChange={changeData}
            id={"address"}
            label={"Address"}
            validators={[{ maxLimit: 255 }]}
          />
          <TextInput
            value={detailData.zip_code}
            onChange={changeData}
            id={"zip_code"}
            label={"Zip Code"}
            validators={[]}
          />
          <TextInput
            value={detailData.city}
            onChange={changeData}
            id={"city"}
            label={"City"}
            validators={[{ maxLimit: 75 }]}
          />
          <TextInput
            value={detailData.state}
            onChange={changeData}
            id={"state"}
            label={"State"}
            validators={[{ maxLimit: 75 }]}
          />
          <TextInput
            value={detailData.phone_1}
            onChange={changeData}
            id={"phone_1"}
            label={"Phone 1"}
            validators={[
            /* eslint-disable-next-line no-useless-escape */
              { regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ },
              { maxLimit: 25 },
            ]}
          />
          <TextInput
            value={detailData.phone_2}
            onChange={changeData}
            id={"phone_2"}
            label={"Phone 2"}
            validators={[
                /* eslint-disable-next-line no-useless-escape */
              { regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ },
              { maxLimit: 25 },
            ]}
          />
          <MediaPicker
            mediaType="image"
            value={detailData.contact_image}
            onChange={changeData}
            id={"contact_image"}
            label={"Contact Image"}
          />
        </EntityDetailLayout>
      </DefaultLayout>
    );
  }
