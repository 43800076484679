/*
    File used to extend different Quill fetures.
*/

import { Quill } from "react-quill";


const BlockEmbed = Quill.import('blots/block/embed');
const Parchment = Quill.import('parchment');

export const MEDIA_ID_ATTRIBUTE = 'data-media-id';
export const MEDIA_TYPE_ATTRIBUTE = 'data-media-type';

/**
 * Example taken from https://quilljs.com/guides/cloning-medium-with-parchment/
 */
class VideoBlot extends BlockEmbed {

  static allowedAttributes = [MEDIA_ID_ATTRIBUTE, MEDIA_TYPE_ATTRIBUTE]

  static create(url: string) {

    let node = super.create();
    node.setAttribute('src', url);
    // Set non-format related attributes with static values
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);

    return node;
  }

  static formats(node: any) {

    // We still need to report unregistered embed formats
    let format = {} as any;

    for (const allowedAttribute of this.allowedAttributes) {
      if (node.hasAttribute(allowedAttribute)) {
        format[allowedAttribute] = node.getAttribute(allowedAttribute);
      }
    }

    return format;
  }

  static value(node: any) {
    return node.getAttribute('src');
  }

  format(name: string, value: any) {

    for (const allowedAttribute of (this.constructor as typeof VideoBlot).allowedAttributes) {
      if (name === allowedAttribute) {
        return this.domNode.setAttribute(name, value);
      }
    }

    // Default behavior.
    super.format(name, value);

  }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';




class ImageBlot extends BlockEmbed {

  static allowedAttributes = [MEDIA_ID_ATTRIBUTE, MEDIA_TYPE_ATTRIBUTE, 'loading', 'width', 'class', 'style'];


  static syncFigure(domNode: any, imgSrc: any) {

    const figcaption = domNode.querySelector('figcaption');


    if (domNode.style.float !== imgSrc.style.float) {
      domNode.style.float = imgSrc.style.float;
    }

    if (imgSrc.style.float === 'right' || imgSrc.style.float === 'left') {
      domNode.style.margin = imgSrc.style.margin;
      domNode.style.display = undefined;
    } else if (domNode.style.margin !== imgSrc.style.margin) {
      domNode.style.margin = imgSrc.style.margin;
    }

    if (imgSrc.style.display === 'block') {
      domNode.style.display = 'flex';
      domNode.style.justifyContent = 'center';
      domNode.style.float = null;

      if (figcaption) {
        figcaption.style.marginTop = '1em';
      }

    } else if (figcaption) {
      figcaption.style.marginTop = null;
    }

    // Restore default value.
    if (imgSrc.getAttribute('style') === null) {
      domNode.setAttribute('style', '');
      if (figcaption) {
        figcaption.style.marginTop = '1em';
      }
    }
  }

  static create(value: any) {

    if (value.targetTag === 'IMG') {

      const imgNode = window.document.createElement('img');
      const figureNode = window.document.createElement('figure');

      figureNode.setAttribute('data-custom-figure', 'true');

      imgNode.setAttribute('alt', value.alt);
      imgNode.setAttribute('src', value.src);

      figureNode.appendChild(imgNode)
      figureNode.style.display = 'inline-block';

      return figureNode;
    }


    const node = super.create();
    const img = window.document.createElement('img');
    const div = window.document.createElement('div');
    img.setAttribute('alt', value.alt);
    img.setAttribute('src', value.src);
    node.setAttribute('data-custom-figure', true);
    node.style.display = 'inline-block';
    div.appendChild(img);

    if (value.label) {
      const figcaption = window.document.createElement('figcaption');
      figcaption.innerHTML = value.label;
      div.appendChild(figcaption)
    }

    node.appendChild(div);


    if (value.imgNode) {
      this.syncFigure(node, value.imgNode)
    }

    return node;
  }

  static value(node: any) {
    if (node.tagName === 'IMG') {

      return {
        alt: node.getAttribute('alt'),
        src: node.getAttribute('src'),
        targetTag: 'IMG'
      };
    }
    
    if(node.classList.contains('wp-block-embed')) { 
      return null;
    }

    const imgNode = node.querySelector('img');
    const figcaption = node.querySelector('figcaption');

    return {
      alt: imgNode.getAttribute('alt'),
      src: imgNode.getAttribute('src'),
      label: figcaption?.innerHTML,
      imgNode
    }

  }

  static formats(node: any) {

    const imgNode = node.tagName === 'IMG' ? node : node.querySelector('img');

    // We still need to report unregistered embed formats
    let format = {} as any;

    for (const allowedAttribute of this.allowedAttributes) {
      if (imgNode.hasAttribute(allowedAttribute)) {
        format[allowedAttribute] = imgNode.getAttribute(allowedAttribute);
      }
    }

    return format;
  }

  format(name: string, value: any) {
    for (const allowedAttribute of (this.constructor as typeof ImageBlot).allowedAttributes) {
      if (name === allowedAttribute) {

        const imgNode = this.domNode.tagName === 'IMG' ? this.domNode : this.domNode.querySelector('img');

        return imgNode.setAttribute(name, value);
      }
    }

    // Default behavior.
    super.format(name, value);
  }

  update(mutationRecords: any) {

    for (const mutationRecord of mutationRecords) {
      if (mutationRecord.attributeName === 'style' && mutationRecord.target.tagName === 'IMG') {

        (this.constructor as unknown as ImageBlot).syncFigure(this.domNode, mutationRecord.target);

      }
    }

  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = ['figure', 'img'];

class ShiftEnterBlot extends Parchment.Embed { }
ShiftEnterBlot.blotName = 'ShiftEnter';
ShiftEnterBlot.tagName = 'br';

Quill.register(ShiftEnterBlot);
Quill.register(VideoBlot);
Quill.register(ImageBlot);

