import { EMAILS } from "./global";

export interface PositionItem {
	id: number,
	title: string,
	sub_title: string,
	target_email: string,
	slug: string,
	description: string,
	is_open: boolean,
	status: string,
	meta_title: string,
	meta_description: string,
	focus_keyphrase: string,
	created: string,
	modified: string,
	location: string,
	position_image: { id: number }[],
	social_image: { id: number }[]
}

export const defaultPositionItem: PositionItem = {
	id: -1,
	created: '',
	description: '',
	focus_keyphrase: '',
	is_open: false,
	meta_description: '',
	meta_title: '',
	modified: '',
	slug: '',
	status: 'DRAFT',
	sub_title: '',
	title: '',
	location: '',
	position_image: [],
	social_image: [],
	target_email: EMAILS.JOB
}
