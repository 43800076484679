import { MediaItem } from "./MediaItem"

import { GRAVATAR } from "../utils/constants"

export interface UserItem {
	id: number,
	email: string,
	username: string,
	first_name: string,
	last_name: string,
	biographical_info: string,
	refresh_token: string,
	role: string,
	avatar: string,
	profile_picture_id: number,
	created_at: string,
	update_at: string,
	is_active:	boolean,
	password: string,
	profile_picture: MediaItem | null,
}
export const defaultUserItem: UserItem = {
	biographical_info: '',
	created_at: new Date().toISOString(),
	email: '',
	first_name: '',
	id: 0,
	is_active: true,
	last_name: '',
	profile_picture_id: 0,
	refresh_token: '',
	role: 'ADMIN',
	avatar: `${GRAVATAR}foo?s=500`, // fake photo from gravatar as initial avatar.
	update_at: new Date().toISOString(),
	username: '',
	password: '',
	profile_picture: null
}
