import DefaultLayout from "../../layouts/Default";
import DataTable from "../../components/dataTable/DataTable";
import { clearStorage } from "../../utils/persistance";
import { ABOUT_US_LOCAL_STORAGE_KEY } from "./AboutUsConst";

const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Title", attr: "title", defaultValue: "About Us" },
  { name: "Language", attr: "language"},
];

export default function AboutUs() { 

  return (
    <DefaultLayout>
      <DataTable
        title="About Us"
        apiUrl="/about-us"
        url="/about-us"
        tableHeader={tableHeader}
        onAddNew={() => clearStorage(ABOUT_US_LOCAL_STORAGE_KEY)}
      />
    </DefaultLayout>
  );
}
