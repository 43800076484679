/**
 * Returns a list of en irregulars.
 *
 * @type {string[]} English irregulars.
 */
export default [
	"arisen",
	"awoken",
	"reawoken",
	"babysat",
	"backslid",
	"backslidden",
	"beat",
	"beaten",
	"become",
	"begun",
	"bent",
	"unbent",
	"bet",
	"bid",
	"outbid",
	"rebid",
	"underbid",
	"overbid",
	"bidden",
	"bitten",
	"blown",
	"bought",
	"overbought",
	"bound",
	"unbound",
	"rebound",
	"broadcast",
	"rebroadcast",
	"broken",
	"brought",
	"browbeat",
	"browbeaten",
	"built",
	"prebuilt",
	"rebuilt",
	"overbuilt",
	"burnt",
	"burst",
	"bust",
	"cast",
	"miscast",
	"recast",
	"caught",
	"chosen",
	"clung",
	"come",
	"overcome",
	"cost",
	"crept",
	"cut",
	"undercut",
	"recut",
	"daydreamt",
	"dealt",
	"misdealt",
	"redealt",
	"disproven",
	"done",
	"predone",
	"outdone",
	"misdone",
	"redone",
	"overdone",
	"undone",
	"drawn",
	"outdrawn",
	"redrawn",
	"overdrawn",
	"dreamt",
	"driven",
	"outdriven",
	"drunk",
	"outdrunk",
	"overdrunk",
	"dug",
	"dwelt",
	"eaten",
	"overeaten",
	"fallen",
	"felt",
	"fit",
	"refit",
	"retrofit",
	"flown",
	"outflown",
	"flung",
	"forbidden",
	"forecast",
	"foregone",
	"foreseen",
	"foretold",
	"forgiven",
	"forgotten",
	"forsaken",
	"fought",
	"outfought",
	"found",
	"frostbitten",
	"frozen",
	"unfrozen",
	"given",
	"gone",
	"undergone",
	//	Is also auxiliary: "got",
	"gotten",
	"ground",
	"reground",
	"grown",
	"outgrown",
	"regrown",
	"had",
	"handwritten",
	"heard",
	"reheard",
	"misheard",
	"overheard",
	"held",
	"hewn",
	"hidden",
	"unhidden",
	"hit",
	"hung",
	"rehung",
	"overhung",
	"unhung",
	"hurt",
	"inlaid",
	"input",
	"interwound",
	"interwoven",
	"jerry-built",
	"kept",
	"knelt",
	"knit",
	"reknit",
	"unknit",
	"known",
	"laid",
	"mislaid",
	"relaid",
	"overlaid",
	"lain",
	"underlain",
	"leant",
	"leapt",
	"outleapt",
	"learnt",
	"unlearnt",
	"relearnt",
	"mislearnt",
	"left",
	"lent",
	"let",
	"lip-read",
	"lit",
	"relit",
	"lost",
	"made",
	"premade",
	"remade",
	"meant",
	"met",
	"mown",
	"offset",
	"paid",
	"prepaid",
	"repaid",
	"overpaid",
	"partaken",
	"proofread",
	"proven",
	"put",
	"quick-frozen",
	"quit",
	"read",
	"misread",
	"reread",
	"retread",
	"rewaken",
	"rid",
	"ridden",
	"outridden",
	"overridden",
	"risen",
	"roughcast",
	"run",
	"outrun",
	"rerun",
	"overrun",
	"rung",
	"said",
	"sand-cast",
	"sat",
	"outsat",
	"sawn",
	"seen",
	"overseen",
	"sent",
	"resent",
	"set",
	"preset",
	"reset",
	"misset",
	"sewn",
	"resewn",
	"oversewn",
	"unsewn",
	"shaken",
	"shat",
	"shaven",
	"shit",
	"shone",
	"outshone",
	"shorn",
	"shot",
	"outshot",
	"overshot",
	"shown",
	"shrunk",
	"preshrunk",
	"shut",
	"sight-read",
	"slain",
	"slept",
	"outslept",
	"overslept",
	"slid",
	"slit",
	"slung",
	"unslung",
	"slunk",
	"smelt",
	"outsmelt",
	"snuck",
	"sold",
	"undersold",
	"presold",
	"outsold",
	"resold",
	"oversold",
	"sought",
	"sown",
	"spat",
	"spelt",
	"misspelt",
	"spent",
	"underspent",
	"outspent",
	"misspent",
	"overspent",
	"spilt",
	"overspilt",
	"spit",
	"split",
	"spoilt",
	"spoken",
	"outspoken",
	"misspoken",
	"overspoken",
	"spread",
	"sprung",
	"spun",
	"unspun",
	"stolen",
	"stood",
	"understood",
	"misunderstood",
	"strewn",
	"stricken",
	"stridden",
	"striven",
	"struck",
	"strung",
	"unstrung",
	"stuck",
	"unstuck",
	"stung",
	"stunk",
	"sublet",
	"sunburnt",
	"sung",
	"outsung",
	"sunk",
	"sweat",
	"swept",
	"swollen",
	"sworn",
	"outsworn",
	"swum",
	"outswum",
	"swung",
	"taken",
	"undertaken",
	"mistaken",
	"retaken",
	"overtaken",
	"taught",
	"mistaught",
	"retaught",
	"telecast",
	"test-driven",
	"test-flown",
	"thought",
	"outthought",
	"rethought",
	"overthought",
	"thrown",
	"outthrown",
	"overthrown",
	"thrust",
	"told",
	"retold",
	"torn",
	"retorn",
	"trod",
	"trodden",
	"typecast",
	"typeset",
	"upheld",
	"upset",
	"waylaid",
	"wept",
	"wet",
	"rewet",
	"withdrawn",
	"withheld",
	"withstood",
	"woken",
	"won",
	"rewon",
	"worn",
	"reworn",
	"wound",
	"rewound",
	"overwound",
	"unwound",
	"woven",
	"rewoven",
	"unwoven",
	"written",
	"typewritten",
	"underwritten",
	"outwritten",
	"miswritten",
	"rewritten",
	"overwritten",
	"wrung",
];
