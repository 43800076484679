import DataTable from "../components/dataTable/DataTable";
import DefaultLayout from "../layouts/Default";
import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utils/constants";
import { getAuth } from "../utils/Auth";
import { toast } from "react-toastify";
import { handleResponseError } from "../utils/methods";

const tableHeader = [
  {name: "ID", attr: "id"},
  {name: "Section", attr: "stringKey.section"},
  {name: "Key", attr: "stringKey.key"},
  {name: "Description", attr: "stringKey.description"},
  {name: "Default value", attr: "stringKey.default"},
  {name: "Translation", attr: "value", type: "edit"}
]

const TranslationsDetail = () => {
  const {languageId} = useParams();

  const saveTranslation = (id: number, value: string) => {
    const request = {
      id: id,
      value: value
    }
    axios
      .put(
        `${API_URL}/translation/${languageId}${id ? "/" + id : ""}`,
        request,
        getAuth()
      )
      .then(() => {
        toast.success("Record edited successfully!");
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while editing record");
      });
  }

  return (
    <DefaultLayout>
      <DataTable title={"Translations"} apiUrl={`/translation/${languageId}`}
                 onSaveInput={saveTranslation}
                 tableHeader={tableHeader}
                 hasAddButton={false} hasBackButton={true}/>
    </DefaultLayout>
  );
}

export default TranslationsDetail;