import axios from 'axios';
import { toast } from 'react-toastify';
import { UserItem } from '../types/UserItem';
import { API_URL } from './constants';
import {handleResponseError} from "./methods";

export const getUser = async (): Promise<UserItem | undefined> => {
	const value = localStorage.getItem("tokens")
	const email = localStorage.getItem("email")
	if(!value || !email) return undefined
	const tokens = JSON.parse(value)
	let data = null
	try {
		data = await axios.get(API_URL + "/user/profile", {headers: {Authorization: `Bearer ${tokens.access_token}`}})
		if(data?.status !== 200 && data?.status !== 201) {
			handleResponseError(data, "while loading current user")
			window.location.replace("/login")
		}
	} catch (err) {
		toast.error("Network error !")
	}
	return data?.data
}

export const isLogged = () => {
	return !!localStorage.getItem("tokens");
}

export const getAuth = () => {
	const value = localStorage.getItem("tokens")
	if(!value) return undefined
	const tokens = JSON.parse(value)
	return {headers: {Authorization: `Bearer ${tokens.access_token}`}}
}

export const login = (email: String, password: String) => {
	axios.post(API_URL + "/auth/login", {email, password})
		.then((res) => {
			localStorage.setItem("tokens", JSON.stringify(res.data))
			localStorage.setItem("email", JSON.stringify(email))
			axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
			window.location.replace("/")
		})
		.catch(err => {
			console.error(err)
			handleResponseError(err.response, "while trying to login")
		})
}

export const logout = () => {
	localStorage.removeItem("tokens")
	window.location.replace("/login")
}
