export const getDraftDataFromStorage = (slug: string | undefined, key: string) => {
    if (!slug || slug !== "new") {
      return {};
    }
    return JSON.parse(localStorage.getItem(key) || "{}");
  };

  export const persistOnChange = (slug: string | undefined, key: string, detailData: any) => {
    if (slug === "new") {
        localStorage.setItem(key, JSON.stringify(detailData));
      }
  }
  
  export const clearStorage = (key: string) => {
    localStorage.removeItem(key);
  };
