export interface ContactItem {
	id: number,
	title: string,
	email: string,
	address: string,
	zip_code: string,
	city: string,
	state: string,
	phone_1: string,
	phone_2: string,
	contact_image: {id: number}[] | undefined,
}

export const defaultContactItem : ContactItem = {
	id: -1,
	address: '',
	city: '',
	contact_image: undefined,
	email: '',
	phone_1: '',
	phone_2: '',
	state: "DRAFT",
	title: '',
	zip_code: ''
}