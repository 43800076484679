import React from 'react';
import DefaultLayout from "./Default";
import { isLogged } from '../utils/Auth';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	content: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%"
	},
	contentNotLogged: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100vh",
		width: "100vw"
	},
});

export default function Error() {

	const classes = useStyles();

	if(isLogged()) return (
			<DefaultLayout>
				<div className={classes.content}>404</div>
			</DefaultLayout>
		)

	return <div  className={classes.contentNotLogged}>
		404
	</div>
}
