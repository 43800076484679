import React, { FC, useState } from "react";

export interface IProps {
  id: number,
  value: string,
  onSaveInput: (id: number, value: string) => void
}

export const DataTableInputCell: FC<IProps> = ({id, value, onSaveInput}: {
  id: number,
  value: string,
  onSaveInput: (id: number, value: string) => void
}) => {
  const [input, setInput] = useState<string>(value)

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => onSaveInput(id, e.target.value)

  return (
    <textarea
      onBlur={handleBlur}
      style={{width: '100%', height: '80px'}}
      value={input}
      onChange={event => setInput(event.target.value)}
    />
  )
}