import { IProps as DataTableProps } from '../../components/dataTable/DataTable'
export const getMediaCfg = function getMediaCfg(id: string): DataTableProps[]  {

    return [
        {
            title: 'Blogs',
            apiUrl: `/blog/media/${id}`,
            url: '/blogs',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Created', attr: 'created_at'},
            ]
        },
        {
            title: 'Our Work Slider',
            apiUrl: `/our-work-slider/media/${id}`,
            url: '/our-work-slider',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Link', attr: 'link'},
            ]
        },
        {
            title: 'Galleries',
            apiUrl: `/gallery/media/${id}`,
            url: '/galleries',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
            ]
        },
        {
            title: 'Partners',
            apiUrl: `/partner/media/${id}`,
            url: '/partners',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Link', attr: 'link'}
            ]
        },
        {
            title: 'Positions',
            apiUrl: `/position/media/${id}`,
            url: '/positions',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Slug', attr: 'slug'}
            ]
        },
        {
            title: 'Testimonials',
            apiUrl: `/testimonial/media/${id}`,
            url: '/testimonials',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
            ]
        },
        {
            title: 'Contacts',
            apiUrl: `/contact/media/${id}`,
            url: '/contacts',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Email', attr: 'email'}
            ]
        },
        {
            title: 'Users',
            apiUrl: `/user/media/${id}`,
            url: '/users',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Email', attr: 'email'},
                {name: 'Username', attr: 'username'}
            ]
        },
        {
            title: 'Our Work',
            apiUrl: `/our-work/media/${id}`,
            url: '/our-work',
            tableHeader: [
                {name: 'Id', attr: 'id'},
                {name: 'Title', attr: 'title'},
                {name: 'Slug', attr: 'slug'}
            ]
        },
        {
            title: 'Settings',
            apiUrl: `/setting/media/${id}`,
            url: '/settings',
            tableHeader: [
                {name: 'Number', attr: 'number'},
                {name: 'Text', attr: 'text'}
            ],
            showControls: false,
            fixedUrl: true
        }
    ]
} 