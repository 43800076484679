import React, { FC, useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useFormContext } from '../store/FormProvider';
import { ValidatorTypes } from '../types/Validation';

interface IProps {
    value: string | undefined,
    id: string,
    label: string,
    options: string[],
    onChange: (event: any, newValue: string | null) => void,
    onInputChange?: (event: any, newInputValue: string) => void,
    validators?: ValidatorTypes[],
}

const AutocompleteField: FC<IProps> = ({ id, value, label, options, onChange, onInputChange, validators }: IProps) => {

    const {errors, addValue, addValidationType} = useFormContext()
	const [error, setError] = useState<string>("")

	useEffect(() => {
		addValidationType(id, validators)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		addValue(id, value)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	useEffect(() => {
			if(errors.has(id)) setError(errors.get(id)!)
			else setError("")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors])

    return (
        <Autocomplete
            disablePortal
            id={id}
            value={value}
            options={options}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label={label} error={Boolean(error)} helperText={error} />}
            onInputChange={onInputChange}
        />
    )
}

export default AutocompleteField
