
import DefaultLayout from "../../layouts/Default";

import DataTable, {
  IProps as DatatableProps,
} from "../../components/dataTable/DataTable";


const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Preview", attr: "file", type: "image" },
  { name: "Title", attr: "title" },
  { name: "Type", attr: "file_ext" },
  { name: "Name", attr: "file_name" },
  { name: "Label", attr: "label" },
];

const datatableFilters: DatatableProps["filters"] = [
  {
    type: "select",
    props: {
      options: ["All", "image", "video", "application"],
      value: "All",
      label: "Media type",
      id: "file",
      renderValue(value: any) {
        if (value === "") {
          return "All";
        }
        return value as any;
      },
      getDisplayValue(option: string) {
        if (option === "") {
          return "All";
        }
        return option;
      },
    },
    apiValueTransformer(value: string) {
      if (value === "All") {
        return "";
      }
      return value;
    },
  },
];


export default function MediaLibrary() {
 



  return (
    <DefaultLayout>
      <DataTable
        title={"Media Library"}
        apiUrl={"/media"}
        url={"/media-library"}
        tableHeader={tableHeader}
        filters={datatableFilters}
      />
    </DefaultLayout>
  );
}
