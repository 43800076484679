import {MediaItem} from "./MediaItem";

export interface GalleryItem {
    images: MediaItem[],
    id: number,
    key: string,
    title: string,
}

export const defaultGalleryItem : GalleryItem = {
    images: [],
    id: 0,
    key: '',
    title: ''
}
