import {
  Checkbox,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import lodash from "lodash";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/constants";
import { isImage, isVideo } from "../../utils/methods";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DragIndicator from "@mui/icons-material/DragIndicator";
import { useDrag, useDrop } from "react-dnd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { DataTableInputCell } from "./DataTableInputCell";

type StyleProps = {};

const useStyles = makeStyles<Theme, StyleProps>({
  image: {
    width: 60,
    background: "#e6e6e6",
  },
});

export interface IProps {
  index: number;
  title: string;
  url?: string;
  tableHeader: {
    name: string;
    attr: string;
    type?: string;
    defaultValue?: string;
  }[];
  valueFormatter?: (attr: string, value: any) => any;
  fixedUrl?: boolean;
  row: any;
  allowDnDSorting?: boolean;
  onMove?: (row: any, index: number) => void;
  onMoveEnd?: (id: number) => void;
  onMoveTo?: (id: number, position: "start" | "end") => void;
  onSaveInput?: (id: number, value: string) => void;
}

type DraggedItem = {
  id: number;
  index: number;
};

const DataTableRow: FC<IProps> = ({
  index,
  title,
  url,
  fixedUrl = false,
  tableHeader,
  valueFormatter,
  row,
  allowDnDSorting = false,
  onMove,
  onMoveEnd,
  onMoveTo,
  onSaveInput,
}: IProps) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const ref = useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop(
    {
      accept: "row",
      hover(draggedItem: DraggedItem) {
        if (draggedItem.id !== row.id) {
          onMove?.(draggedItem.id, index);
        }
      },
      drop: (item: DraggedItem) => {
        onMoveEnd?.(row.id);
        return item;
      },
    },
    [row.id, index]
  );

  const [{ isDragging }, drag] = useDrag(
    {
      type: "row",
      item: { id: row.id, index: index },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [index, row.id]
  );

  const handleRedirect = (row: any) => {
    if (!url) {
      return;
    }
    if (
      title === "Blogs" ||
      title === "Categories" ||
      title === "Our Work" ||
      title === "Positions"
    )
      navigate(url + "/" + row.slug);
    else if (fixedUrl) navigate(url);
    else navigate(url + "/" + row.id);
  };

  const getSource = (row: any, item: any) => {
    if (row["file_ext"]) {
      if (isVideo(row["file_ext"])) {
        return <OndemandVideoIcon style={{ fontSize: 50 }} />;
      }
      if (isImage(row["file_ext"])) {
        return (
          <img
            className={classes.image}
            alt={"data"}
            src={`${API_URL}/${row[item.attr]}`}
          />
        );
      }

      return <InsertDriveFileIcon style={{ fontSize: 30 }} />;
    }

    let src = null;

    if (row[item.attr]) {
      src = `${API_URL}/${
        row[item.attr].file
          ? row[item.file_ext]
            ? row[item.attr].file.slice(
                0,
                row[item.attr].file.length - row[item.attr].file_ext.length - 1
              ) +
              "-thumbnail." +
              item.file_ext
            : row[item.attr].file
          : row[item.attr]
      }`;
    } else {
      src = row[item.defaultValue];
    }

    return <img className={classes.image} alt={"data"} src={src} />;
  };

  const openMenu = (event: React.MouseEvent) => {
    setMenuAnchorEl(event.currentTarget as HTMLElement);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const moveToTop = () => {
    onMoveTo?.(row.id, "start");
    closeMenu();
  };

  const moveToEnd = () => {
    onMoveTo?.(row.id, "end");
    closeMenu();
  };

  const menuOpened = !!menuAnchorEl;

  allowDnDSorting && drag(drop(ref));

  return (
    <TableRow
      key={row.id}
      onClick={() => handleRedirect(row)}
      style={{ opacity: isDragging ? 0 : 1 }}
      ref={ref}
    >
      {allowDnDSorting && (
        <TableCell
          sx={{
            padding: "2px 16px 8px",
            cursor: "move",
          }}
          className={classes.tableCell}
          onClick={(e) => e.stopPropagation()}
        >
          <DragIndicator />
        </TableCell>
      )}
      {tableHeader.map((item) => {
        const value = lodash.get(row, item.attr) ?? item.defaultValue;
        return (
          <TableCell
            key={item.attr}
            align={item.attr === "file" ? "center" : "left"}
            component="th"
            scope="row"
            sx={{ padding: "2px 16px 8px" }}
          >
            {item.type === "boolean" ? (
              <Checkbox
                style={{ pointerEvents: "none" }}
                checked={Boolean(value)}
              />
            ) : null}
            {item.type === "image" && (value || row[item.defaultValue || ""])
              ? getSource(row, item)
              : null}
            {item.type === "remote-image" && value ? (
              <img className={classes.image} alt={"data"} src={value} />
            ) : null}
            {item.type === "edit" && onSaveInput ? (
              <DataTableInputCell
                id={row.id}
                value={value}
                onSaveInput={onSaveInput}
              />
            ) : null}
            {!item.type
              ? valueFormatter
                ? valueFormatter(item.attr, value)
                : value
              : null}
          </TableCell>
        );
      })}
      {allowDnDSorting && (
        <TableCell
          onClick={(e) => e.stopPropagation()}
          sx={{ cursor: "pointer", textAlign: "center" }}
        >
          <MoreVertIcon onClick={openMenu} />
          <Menu anchorEl={menuAnchorEl} open={menuOpened} onClose={closeMenu}>
            <MenuItem onClick={moveToTop}>First</MenuItem>
            <MenuItem onClick={moveToEnd}>Last</MenuItem>
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DataTableRow;
