import React, { FC } from 'react';
import { Button, ButtonProps, Modal, Typography } from "@mui/material";
import { useCommonClasses  } from './common-classes';
import { useFormContext } from '../../store/FormProvider';

interface IProps  {
    open: boolean;
    onClose: () => any,
    handleSubmit: () => any,
    children: React.ReactNode,
    actionButtonText?: string;
    title: string;
    shouldValidateForm?: boolean;
    closeButtonText?: string;
    extraButtons?: {
        text: string;
        onClick: () => any
    }[],
    overrideActionButtonProps?: ButtonProps
}

const FormModal: FC<IProps> = ({
    open,
    onClose,
    handleSubmit,
    children,
    actionButtonText = 'ADD',
    title,
    shouldValidateForm = false,
    closeButtonText,
    extraButtons = [],
    overrideActionButtonProps
}) => {
    

    const formContext = useFormContext();

    const classes = useCommonClasses();

    function onSubmit() {

        if(!shouldValidateForm) {
            return handleSubmit();
        }

        if (formContext.validateForm()) {
            handleSubmit();
        }
    }

    return (
        <Modal open={open} onClose={onClose} className={classes.modal}>
        <div className={classes.modalWrapper}>
          <div className={classes.modalContent}>
            <Typography variant={"h4"} style={{ marginBottom: 20 }}>
                {title}
            </Typography>
            {
                children
            }
          </div>
          <div className={classes.buttons}>
            <Button variant={"contained"} onClick={onClose}>
              { closeButtonText || 'DISCARD'}
            </Button>
            {extraButtons.map(buttonDef => (
                <Button variant='contained' onClick={buttonDef.onClick} key={`modal-form-button-${buttonDef.text}`}>
                    {buttonDef.text}
                </Button>
            ))}
            <Button variant={"contained"} onClick={onSubmit} {...overrideActionButtonProps}>
              {actionButtonText}
            </Button>
          </div>
        </div>
      </Modal>
    )
}

export default FormModal;
