import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface IProps {
	open: boolean,
	title: string,
	content?: string,
	yesText?: string,
	noText?: string,
	handleClose: (confirmed: boolean) => void
}

const ConfirmDialog : FC<IProps> = ({open, title, content, handleClose, noText, yesText}) => {

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => handleClose(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{title}
				</DialogTitle>
				{content ? <DialogContent>
					<DialogContentText id="alert-dialog-description">
						{content}
					</DialogContentText>
				</DialogContent> : null }
				<DialogActions>
					<Button onClick={() => handleClose(false)}>
						{yesText ? noText : "Discard"}
					</Button>
					<Button onClick={() => handleClose(true)} autoFocus>
						{noText ? noText : "Confirm"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default ConfirmDialog
