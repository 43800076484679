import { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/Default";
import { useNavigate, useParams } from "react-router-dom";
import EntityDetailLayout from "../../layouts/EntityDetail";
import TextInput from "../../components/TextInput";
import MediaPicker from "../../components/MediaPicker";
import { defaultOurWorkItem, OurWorkItem } from "../../types/OurWorkItem";
import QuillEditor from "../../components/QuillEditor/QuillEditor";
import { getImageForm } from "../../utils/methods";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";
import OptionPicker from "../../components/OptionPicker";
import { usePreview } from "../../hooks/usePreview";
import axios from "axios";
import { getAuth } from "../../utils/Auth";
import { API_URL } from "../../utils/constants";
import { Preview } from "@mui/icons-material";
import { OUR_WORK_LOCAL_STORAGE_KEY } from "./OurWorkConst";
import {
  clearStorage,
  getDraftDataFromStorage,
  persistOnChange,
} from "../../utils/persistance";
import { Status } from "../../types/global";

const MAIN_TITLE = "Our Work";
const URLS = "/our-work";

export enum OurWorkCategory {
  AGILE = "Agile Teams",
  DEV = "Product Development",
  AI = 'AI Integrations'
}

const useStyles = makeStyles({
  gallery: {
    marginTop: "24px",
  },
  relationLink: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  relationTitle: {
    marginBottom: "12px !important",
  },
  relationWrapper: {
    margin: "24px 0px",
  },
  relationLinkText: {
    marginRight: 5,
  },
});

export default function OurWorkDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { entityId } = useParams();

  const defaultData = {
    ...defaultOurWorkItem,
    ...getDraftDataFromStorage(entityId, OUR_WORK_LOCAL_STORAGE_KEY),
  };

  const [detailData, setDetailData] = useState<OurWorkItem>(defaultData);

  const getFormData = () => {
    return {
      title: detailData.title,
      slug: detailData.slug,
      featured_image: getImageForm(detailData.featured_image),
      header_media: getImageForm(detailData.header_media),
      header_media_image: getImageForm(detailData.header_media_image),
      company_logo: getImageForm(detailData.company_logo),
      testimonial: detailData.testimonial_data,
      company_description: detailData.company_description,
      the_need: detailData.the_need,
      our_solution: detailData.our_solution,
      key_achievements: detailData.key_achievements,
      facts: detailData.facts,
      gallery: detailData.gallery,
      short_description: detailData.short_description,
      category: detailData.category,
      social_facebook_image: getImageForm(detailData.social_facebook_image),
      order: parseFloat(detailData.order?.toString() ?? "") || null,
      status: detailData.status,
    };
  };

  useEffect(() => {
    persistOnChange(entityId, OUR_WORK_LOCAL_STORAGE_KEY, detailData);
  }, [detailData, entityId]);

  const createPreviewData = async () => {
    const featured_image = await axios.get(
      `${API_URL}/media/${getImageForm(detailData.featured_image, true)?.id}`,
      getAuth()
    );

    const company_logo = await axios.get(
      `${API_URL}/media/${getImageForm(detailData.company_logo, true)?.id}`,
      getAuth()
    );

    const gallery = await Promise.all(
      detailData.gallery.map((item) =>
        axios
          .get(`${API_URL}/media/${item.id}`, getAuth())
          .then((res) => res.data)
      )
    );

    const data = {
      title: detailData.title,
      slug: detailData.slug,
      featured_image: featured_image.data,
      header_media: getImageForm(detailData.header_media),
      header_media_image: getImageForm(detailData.header_media_image),
      company_logo: company_logo.data,
      testimonial_data: detailData.testimonial_data,
      testimonial_person: detailData.testimonial_data,
      company_description: detailData.company_description,
      the_need: detailData.the_need,
      our_solution: detailData.our_solution,
      key_achievements: detailData.key_achievements,
      facts: detailData.facts,
      gallery: gallery,
      short_description: detailData.short_description,
      social_facebook_image: getImageForm(detailData.social_facebook_image),
      category: detailData.category,
      status: detailData.status,
    };

    return data;
  };

  const { openPreview } = usePreview("our-work", createPreviewData, detailData);

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  function goToTestimonial() {
    navigate(`/testimonials/${detailData.testimonial_data?.id}`);
  }

  function goToOurWorkSlider() {
    navigate(`/our-work-slider/${detailData.our_work_slider_data?.id}`);
  }

  const isEditing = () => entityId?.toLowerCase() !== "new";

  return (
    <DefaultLayout>
      <EntityDetailLayout
        id={detailData.id}
        title={MAIN_TITLE}
        apiUrl={URLS}
        defaultValue={defaultData}
        setData={setDetailData}
        getFormData={getFormData}
        navigateBack={isEditing() ? false : true}
        onSave={() => clearStorage(OUR_WORK_LOCAL_STORAGE_KEY)}
        customButtons={[
          {
            label: "WEB PREVIEW",
            onClick: openPreview,
            floatButtonIcon: <Preview />,
          },
        ]}
      >
        <MediaPicker
          mediaType={"image"}
          value={detailData.featured_image}
          onChange={changeData}
          id={"featured_image"}
          label={"Featured Image"}
          validators={[{ required: true }]}
        />
        <MediaPicker
          value={detailData.header_media}
          mediaType={"video"}
          onChange={changeData}
          id={"header_media"}
          label={"Header Media"}
        />
        <MediaPicker
          mediaType={"image"}
          value={detailData.header_media_image}
          onChange={changeData}
          id={"header_media_image"}
          label={"Header Media Image"}
        />
        <MediaPicker
          mediaType={"image"}
          value={detailData.company_logo}
          onChange={changeData}
          id={"company_logo"}
          label={"Company Logo"}
          validators={[{ required: true }]}
        />
        <TextInput
          value={detailData.title}
          onChange={changeData}
          id={"title"}
          label={"Title"}
          validators={[{ required: true }, { maxLimit: 255 }]}
        />
        <TextInput
          value={detailData.slug}
          onChange={changeData}
          id={"slug"}
          label={"Slug"}
          validators={[{ required: true }, { regex: /^[-a-zA-Z0-9_]+$/ }]}
        />
        <TextInput
          value={detailData.short_description}
          onChange={changeData}
          multiline
          id={"short_description"}
          label={"Short Description"}
          validators={[{ required: true }]}
        />
        <OptionPicker
          value={detailData.category}
          id={"category"}
          label={"Category"}
          validators={[{ required: true }]}
          onChange={changeData}
          options={Object.values(OurWorkCategory)}
        />
        <OptionPicker
          value={detailData.status}
          id={"status"}
          label={"Status"}
          validators={[{ required: true }]}
          onChange={changeData}
          options={Object.values(Status)}
        />
        <MediaPicker
          value={detailData.social_facebook_image}
          id={"social_facebook_image"}
          label={"Social Media Image"}
          mediaType={"image"}
          onChange={changeData}
        />
        {detailData.testimonial_data && (
          <div className={classes.relationWrapper}>
            <Typography variant={"h6"} className={classes.relationTitle}>
              Testimonial
            </Typography>
            <Typography
              className={classes.relationLink}
              color="primary"
              onClick={goToTestimonial}
            >
              <span className={classes.relationLinkText}>
                {detailData.testimonial_data.title}
              </span>
              <OpenInBrowser />
            </Typography>
          </div>
        )}
        {detailData.our_work_slider_data && (
          <div className={classes.relationWrapper}>
            <Typography variant={"h6"} className={classes.relationTitle}>
              Our work slider
            </Typography>
            <Typography
              className={classes.relationLink}
              color="primary"
              onClick={goToOurWorkSlider}
            >
              <span className={classes.relationLinkText}>
                {detailData.our_work_slider_data.title}
              </span>
              <OpenInBrowser />
            </Typography>
          </div>
        )}
        <QuillEditor
          value={detailData.company_description}
          onChange={changeData}
          id={"company_description"}
          label={"Company Description"}
          gallery_id={"gallery"}
          galleries={detailData.gallery}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          validators={[]}
        />
        <QuillEditor
          value={detailData.the_need}
          onChange={changeData}
          id={"the_need"}
          label={"Link"}
          gallery_id={"gallery"}
          galleries={detailData.gallery}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          validators={[]}
        />
        <QuillEditor
          value={detailData.our_solution}
          onChange={changeData}
          id={"our_solution"}
          gallery_id={"gallery"}
          label={"Our Solution"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          validators={[]}
        />
        <QuillEditor
          value={detailData.key_achievements}
          onChange={changeData}
          id={"key_achievements"}
          gallery_id={"gallery"}
          label={"Key Achievements"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          validators={[]}
        />
        <QuillEditor
          value={detailData.facts}
          onChange={changeData}
          id={"facts"}
          label={"Facts"}
          gallery_id={"gallery"}
          canAddVideo={false}
          canAddCode={false}
          canAddIframe={false}
          canAddGallery={false}
          validators={[]}
        />
        <div className={classes.gallery}>
          <MediaPicker
            id="gallery"
            onChange={changeData}
            label="Gallery"
            value={detailData.gallery}
            multiple
          />
        </div>
      </EntityDetailLayout>
    </DefaultLayout>
  );
}
