
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import React, { useState } from "react";
import EntityDetailLayout from "../layouts/EntityDetail";
import TextInput from "../components/TextInput";
import { useParams } from "react-router-dom";
import { defaultData, LanguageItem } from "../types/LanguageItem";
import { Checkbox, FormControlLabel } from "@mui/material";

const tableHeader = [
  {name: "ID", attr: "id"},
  {name: "Name", attr: "name"},
]

export default function Languages() {

  const {entityId} = useParams()
  const [detailData, setDetailData] = useState<LanguageItem>(defaultData)

  const getFormData = () => {
    return {
      id: detailData.id,
      name: detailData.name,
      isoCode: detailData.isoCode,
      isRtl: detailData.isRtl
    }
  }

  const changeData = (attr: string, value: any) => {
    setDetailData(prev => {
      return {...prev, [attr]: value}
    })
  }

  const changeRtl = () => {
    setDetailData(prev => {
      return {...prev, isRtl: !prev.isRtl}
    })
  }

  if (entityId) {
    return (
      <DefaultLayout>
        <EntityDetailLayout id={detailData.id} title={"Languages"} apiUrl={"/language"}
                            defaultValue={defaultData} setData={setDetailData} getFormData={getFormData}>
          <TextInput value={detailData.name} onChange={changeData} id={"name"} label={"Name"}
                     validators={[{required: true}]}/>
          <TextInput value={detailData.isoCode} onChange={changeData} id={"isoCode"}
                     label={"ISO 639-1 alpha-3 code:"}
                     validators={[{required: true}]}/>

          <FormControlLabel
            control={
              <Checkbox
                checked={detailData.isRtl}
                onChange={changeRtl}
              />
            }
            label="RTL (Right To Left)"
          />
        </EntityDetailLayout>
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      <DataTable title={"Languages"} apiUrl={"/language"} url={"/languages"} tableHeader={tableHeader}/>
    </DefaultLayout>
  );
}