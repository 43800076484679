import DataTableRow from "./DataTableRow";
import { FC, useCallback, useState } from "react";
import { TableBody } from "@mui/material";

export interface IProps {
  data: any[];
  title: string;
  url?: string;
  allowDnDSorting: boolean;
  tableHeader: {
    name: string;
    attr: string;
    type?: string;
    defaultValue?: string;
  }[];
  valueFormatter?: (attr: string, value: any) => any;
  fixedUrl?: boolean;
  onMoveEnd?: (id: number, index: number) => void;
  onMoveTo?: (id: number, position: 'start' | 'end') => void;
  onSaveInput?: (id: number, value: string) => void
}

const DataTableContainer: FC<IProps> = ({
  data,
  title,
  url,
  tableHeader,
  allowDnDSorting,
  valueFormatter,
  fixedUrl,
  onMoveEnd,
  onMoveTo,
  onSaveInput
}) => {
  const [sortedData, setSortedData] = useState<any[]>(data);

  const onMove = useCallback(
    (rowId: number, toIndex: number) =>
      // method is called while moving cursor so has to be heavily optimized
      setSortedData((prevData) => {
        const fromIndex = prevData.findIndex((item) => item.id === rowId);

        if (fromIndex === toIndex) {
          return prevData;
        } else {
          const newData = [...prevData];
          const row = newData.splice(fromIndex, 1)[0];
          // hack preventing cycle in jumping position :/
          newData.splice(toIndex > fromIndex ? toIndex : Math.max(toIndex-1, 0), 0, row);
          return newData;
        }
      }),
    []
  );

  // we must call this in the setter as sorted data would be without last update -
  // as method is called outside of React context
  const handleMoveEnd = useCallback((rowId: number) => {
    setSortedData((prevData) => {
      const rowIndex = prevData.findIndex((item) => item.id === rowId);
      onMoveEnd?.(rowId, rowIndex);

      return prevData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRow = (row: any, index: number) => (
    <DataTableRow
      key={row.id}
      index={index}
      row={row}
      title={title}
      url={url}
      tableHeader={tableHeader}
      fixedUrl={fixedUrl}
      allowDnDSorting={allowDnDSorting}
      onMove={onMove}
      onMoveEnd={handleMoveEnd}
      valueFormatter={valueFormatter}
      onMoveTo={onMoveTo}
      onSaveInput={onSaveInput}
    />
  );

  return <TableBody>{sortedData.map(renderRow)}</TableBody>;
};

export default DataTableContainer;
