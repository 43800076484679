import React, { FC, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import qs from "qs";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuth } from "../../utils/Auth";
import { API_URL } from "../../utils/constants";
import { handleResponseError } from "../../utils/methods";
import OptionPicker, { IProps as OptionPickerProps } from "../OptionPicker";
import DatePicker, { IProps as DatePickerProps } from "../DatePicker";
import DataTableContainer from "./DataTableContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export type SelectFilter = {
  type: "select";
  props: Omit<OptionPickerProps, "onChange" | "validators">;
  apiValueTransformer?: (value: any) => any;
};

export type DateFilter = {
  type: "date";
  props: Omit<DatePickerProps, "onChange" | "validators">;
  apiValueTransformer?: (value: any) => any;
};

const SelectFilterComponent: FC<
  SelectFilter["props"] & { onChange: OptionPickerProps["onChange"] }
> = (props) => {
  return <OptionPicker {...props} onChange={props.onChange} validators={[]} />;
};

const DateFilterComponent: FC<
  DateFilter["props"] & { onChange: DatePickerProps["onChange"] }
> = (props) => {
  return <DatePicker {...props} onChange={props.onChange} validators={[]} />;
};

const mapFilters: {
  select: typeof SelectFilterComponent;
  date: typeof DateFilterComponent;
} = {
  select: SelectFilterComponent,
  date: DateFilterComponent,
};

type StyleProps = {};

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  content: {
    marginTop: 20,
  },
  search: {
    backgroundColor: "white",
  },
  center: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
  filterContainer: {
    marginTop: 12,
  },
});

export interface IProps {
  title: string;
  url?: string;
  apiUrl: string;
  tableHeader: {
    name: string;
    attr: string;
    type?: string;
    defaultValue?: string;
  }[];
  dataMapper?: (data: any) => any;
  allowDnDSorting?: boolean;
  valueFormatter?: (attr: string, value: any) => any;
  hasAddButton?: boolean;
  hideWhenNoData?: boolean;
  showControls?: boolean;
  hasBackButton?: boolean;
  fixedUrl?: boolean;
  filters?: (SelectFilter | DateFilter)[]; // Union types, be free of add other filter types and implement them on './Filters.tsx'.
  onAddNew?: () => void;
  onSaveInput?: (id: number, value: string) => void;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DataTable: FC<IProps> = ({
  title,
  url,
  fixedUrl = false,
  apiUrl,
  tableHeader,
  dataMapper = data => data,
  valueFormatter,
  allowDnDSorting = false,
  hasAddButton = true,
  hideWhenNoData = false,
  showControls = true,
  hasBackButton = false,
  onAddNew,
  filters = [],
  onSaveInput,
}: IProps) => {
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filtersData, setFiltersData] = useState<{ [key: string]: any }>(
    filters.reduce((acum, value) => {
      const id = value.props.id;
      return { ...acum, [id]: value.props.value };
    }, {})
  );

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(20);

  const navigate = useNavigate();
  const classes = useStyles();
  let query = useQuery();
  const location = useLocation();

  const getData = (page?: number, limit?: number) => {
    const filtersDataQuery: string = qs.stringify(filtersData, {
      filter(prefix, value) {
        const foundFilterOpt = filters.find(
          (filter) => filter.props.id === prefix
        );

        if (
          foundFilterOpt !== undefined &&
          foundFilterOpt.apiValueTransformer !== undefined
        ) {
          return foundFilterOpt.apiValueTransformer(value);
        }

        return value;
      },
    });

    setLoading(true);
    axios
      .get(
        API_URL +
          apiUrl +
          `?${!page && page !== 0 ? "" : "page=" + page}&${
            limit ? "limit=" + limit : ""
          }&${
            search ? "search=" + encodeURIComponent(search) : ""
          }&${filtersDataQuery}`,
        getAuth()
      )
      .then((res) => {
        setData(dataMapper(res.data.data));
        setPage(res.data.meta.currentPage - 1);
        setTotalItems(res.data.meta.totalItems);
        setRowsPerPage(res.data.meta.itemsPerPage);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while loading data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const page = query.get("page");
    const limit = query.get("limit");

    if (!search) {
      getData(Number(page), Number(limit));
      return;
    }
    getData(0);
    navigate(`${location.pathname}?page=${page}&limit=${rowsPerPage}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, ...Object.values(filtersData)]);

  const handlePageChange = (event: any, newPage: number) => {
    navigate(
      `${location.pathname}${
        newPage ? `?page=${newPage + 1}&limit=${rowsPerPage}` : ""
      }`,
      {
        replace: true,
      }
    );
    getData(newPage + 1, rowsPerPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    getData(1, parseInt(event.target.value));
  };

  const handleRowMoveEnd = (rowId: number, newPositionIndex: number) => {

    axios
      .post(
        `${API_URL}${apiUrl}/${rowId}/order`,
        {
          position: newPositionIndex + page * rowsPerPage,
        },
        getAuth()
      )
      .then(() => {
        toast.success(`Item moved to the position ${newPositionIndex}.`);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while updating position");
      });
  };

  const handleRowMoveTo = (rowId: number, namedPosition: "start" | "end") => {
    axios
      .post(
        `${API_URL}${apiUrl}/${rowId}/order`,
        {
          namedPosition,
        },
        getAuth()
      )
      .then(() => {
        toast.success(
          `Item moved to be ${
            namedPosition === "start" ? "first" : "last"
          } in the list.`
        );
        getData(page, rowsPerPage);
      })
      .catch((err) => {
        console.error(err);
        handleResponseError(err.response, "while updating position");
      });
  };

  if (!data?.length && hideWhenNoData && !search.length) {
    return null;
  }

  const orderingAllowed = allowDnDSorting && !search;

  return (
    <div className={`${classes.root} max-width-container`}>
      <div className={classes.heading}>
        <Typography
          variant={"h4"}
          style={{ fontWeight: 600, marginRight: "20px" }}
        >
          {title}
        </Typography>
        {hasAddButton && (
          <Button
            variant={"contained"}
            onClick={() => {
              onAddNew?.();
              navigate(url + "/new");
            }}
          >
            + ADD
          </Button>
        )}
      </div>
      {hasBackButton && <ArrowBackIcon onClick={() => navigate(-1)} />}
      {showControls && (
        <>
          <TextField
            placeholder={"Search..."}
            className={classes.search}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
          {filters.map((value, index) => {
            const Component = mapFilters[value.type];

            const { id } = value.props;

            const componentValue = filtersData[id];

            return (
              <div
                className={classes.filterContainer}
                key={`datatable-filter-${value.props.id}-${index}`}
              >
                <Component
                  {...value.props}
                  onChange={(id: any, value: any) => {
                    setFiltersData((prev) => ({
                      ...prev,
                      [id]: value,
                    }));
                  }}
                  value={componentValue}
                />
              </div>
            );
          })}
        </>
      )}
      <div className={classes.content}>
        {loading ? (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 700,
                  borderSpacing: "0 14px",
                  borderCollapse: "separate",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    {orderingAllowed && <TableCell></TableCell>}
                    {tableHeader.map((item) => {
                      const width =
                        item.name.toLowerCase() === "id"
                          ? "40px"
                          : item.type === "edit"
                          ? "400px"
                          : undefined;
                      return (
                        <TableCell key={item.name} style={{ width }}>
                          {item.name ?? item.defaultValue}
                        </TableCell>
                      );
                    })}
                    {orderingAllowed && <TableCell>Order</TableCell>}
                  </TableRow>
                </TableHead>

                {data ? (
                  <DndProvider backend={HTML5Backend}>
                    <DataTableContainer
                      data={data}
                      allowDnDSorting={orderingAllowed}
                      valueFormatter={valueFormatter}
                      title={title}
                      url={url}
                      tableHeader={tableHeader}
                      fixedUrl={fixedUrl}
                      onMoveEnd={handleRowMoveEnd}
                      onMoveTo={handleRowMoveTo}
                      onSaveInput={onSaveInput}
                    />
                  </DndProvider>
                ) : (
                  <TableBody>
                    <TableRow>
                      <div>No data yet</div>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {showControls && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DataTable;
