export interface StringKeyItem {
  id: number,
  section: string,
  key: string,
  description: string,
  default: string
}

export const defaultData: StringKeyItem = {
  id: -1,
  section: '',
  key: '',
  description: '',
  default: ''
}