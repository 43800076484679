import { useRef, useEffect } from 'react';
import { IFormContext } from '../store/FormProvider';
import { WEBSITE_URL } from '../utils/constants'

const commonData = {
  source: 'cms_preview'
}


export function usePreview(entity: string, createPreviewData: () => any, data: any) {
    const previewWindowRef = useRef<Window | null>(null);


    useEffect(() => {
        const updatePreview = async () => {
        const previewWindow = previewWindowRef.current;
        if (previewWindow) {
            previewWindow.postMessage({...(await createPreviewData()), ...commonData}, WEBSITE_URL as string);
        }
    };

    const timeoutID = setTimeout(updatePreview, 500);
    return () => {
      clearTimeout(timeoutID);
    };
    // eslint-disable-next-line
  }, [data]);


  async function openPreview(event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, formContext: IFormContext){

    if(!formContext.validateForm()) {
      return;
    }

    let previewWindow = previewWindowRef.current;
    if (previewWindow) {
      previewWindow.close();
    }

    const data = {...(await createPreviewData()), ...commonData};

    const previewUrl = `${WEBSITE_URL}/${entity}/preview`;

    const processMessage = (event: any) => {
        if (event.data === 'get-preview-data') {
            previewWindow?.postMessage(data, WEBSITE_URL as string);
        }
    }

    window.addEventListener('message', processMessage);
    previewWindow = window.open(previewUrl, '_blank')!;
    previewWindowRef.current = previewWindow;

    const timer = setInterval(function() {
      if (!previewWindow || previewWindow.closed) {
        clearInterval(timer);
        previewWindowRef.current = null;
        window.removeEventListener('message', processMessage);
      }
    }, 1000)
  }


  return {
    openPreview
  }
}