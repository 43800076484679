import { scoreToRating } from "yoastseo";
import Presenter from "./Presenter";

interface AnalysisResultsProps {
  seoAssessor: any;
}


const AnalysisResults = ({ seoAssessor }: AnalysisResultsProps) => {

    const summaryScore = () => {
      const scores = seoAssessor.results.map((r: any) => r.score);
      const sum: number = scores.reduce((a: number, b: number) => a + b, 0) / scores.length;
      return sum;
    };

    const getIndicatorColor = () => {
      return scoreToRating(summaryScore());
    };

      return (
        <div className={`seo-results seo-results--${getIndicatorColor()}`}>
          <h3 className="seo-results__heading">
            SEO - {getIndicatorColor().toUpperCase()} (
            {Math.max(0, summaryScore()).toPrecision(2)}/10)
          </h3>
          <Presenter assessor={seoAssessor} />
        </div>
      );
};

export default AnalysisResults;
